export function getAsset(path) {
  return new URL(`./${path}`, import.meta.url).href;
}

export function getImageSource(path) {
  return getAsset(`img/${path}`);
}

export const IMG = {
  googleAnalytics: getImageSource('googleAnalytics.svg'),
  google: getImageSource('logos/google.svg'),
  connectionRevokedAvatar: getImageSource('connection-revoked-avatar@2x.png'),
  igMessageEnableAccess: getImageSource('ig-message-enable-access.png'),
  twitterMessageEnableAccess: getImageSource('twitter-message-enable-access.png'),
  dashboardsPreviewCompetitiveReport: getImageSource('dashboards/preview-competitive-report.png'),
  dashboardsPreviewContentReport: getImageSource('dashboards/preview-contentreport.png'),
  dashboardsCompetitorGraphPreview: getImageSource('dashboards/competitor-graph-preview.png'),
  dashboardsGraphSingleLine: getImageSource('dashboards/graph_single-line.png'),
  dashboardsSingleBarGraphPreview: getImageSource('dashboards/single-bar-graph-preview.png'),
  dashboardsStackedBarGraphPreview: getImageSource('dashboards/stacked-bar-graph-preview.png'),
  dashboardsPreviewGraph: getImageSource('dashboards/preview-graph.png'),
  dashboardsMultipleBrandGraph: getImageSource('dashboards/multi-graph-preview.png'),
  emptyThumbup: getImageSource('empty-thumbup.png'),
  atSymbol: getImageSource('at-symbol.svg'),
  friends: getImageSource('friends.png'),
  checkMarkBlue: getImageSource('checkmark-blue.svg'),
  logosTiktokTrendingSounds: getImageSource('logos/tiktok-trending-sounds.svg'),
  soundsList: getImageSource('sounds-list.svg'),
  emptySeashore: getImageSource('empty-seashore.png'),
  chromeExtension: getImageSource('chrome-extension.png'),
  engageVisual: getImageSource('engage-visual.png'),
  importing: getImageSource('importing.png'),
  arrowLeft: getImageSource('arrow-left.png'),
  arrowRight: getImageSource('arrow-right.png'),
  iconsTwitterWhite: getAsset('icons/twitter.svg'),
  iconsFacebookWhite: getAsset('icons/facebookWhite.svg'),
  iconsTwitterDarkGrey: getAsset('icons/twitterDarkGrey.svg'),
  emptyKanpai: getImageSource('empty-kanpai.png'),
  emptyWave: getImageSource('empty-wave@2x.png'),
  emptyCattub: getImageSource('empty-cattub.png'),
  emptyBeach: getImageSource('empty-beach.png'),
  emptyField: getImageSource('empty-field@2x.png'),
  radioButtonOn: getImageSource('radioButtonOn.svg'),
  radioButtonOff: getImageSource('radioButtonOff.svg'),
  beisLogo: getImageSource('inAppTrials/beis-logo.png'),
  toryBurchLogo: getImageSource('inAppTrials/tory-burch-logo.png'),
  coterieLogo: getImageSource('inAppTrials/coterie-logo.png'),
  stellMcCartneyLogo: getImageSource('inAppTrials/stellmccartney-logo.png'),
  summerFridaysLogo: getImageSource('inAppTrials/summerfridays-logo.png'),
  tiktokCreatorOverviewBanner: getImageSource('tiktok-creator-overview-banner.png'),
  instagramCreatorOverviewBanner: getImageSource('instagram-creator-overview-banner.png'),
  emailCreatorOverviewBanner: getImageSource('email-creator-overview-banner.png'),
  socialCommerceBanner: getImageSource('social-commerce-no-connection.png'),
  firstTimeUserBanner: getImageSource('first-time-user-banner.png'),
};

export const inAppTrials = {
  engage: [
    getImageSource('inAppTrials/engage/3x.png'),
    getImageSource('inAppTrials/engage/2x.png'),
    getImageSource('inAppTrials/engage/1x.png'),
  ],
  advance: [
    getImageSource('inAppTrials/advance/3x.png'),
    getImageSource('inAppTrials/advance/2x.png'),
    getImageSource('inAppTrials/advance/1x.png'),
  ],
  premium: [
    getImageSource('inAppTrials/premium/3x.png'),
    getImageSource('inAppTrials/premium/2x.png'),
    getImageSource('inAppTrials/premium/1x.png'),
  ],
  socialListening: [
    getImageSource('inAppTrials/socialListening/3x.png'),
    getImageSource('inAppTrials/socialListening/2x.png'),
    getImageSource('inAppTrials/socialListening/1x.png'),
  ],
  creators: [
    getImageSource('inAppTrials/creators/3x.png'),
    getImageSource('inAppTrials/creators/2x.png'),
    getImageSource('inAppTrials/creators/1x.png'),
  ],
};
