export const tooltips = {
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noUserPermission: "Looks like you don't have permission to use this feature!",
  connectCallToAction:
    "Dash Social brings you all of the insights you love, including X! Ask your Dash Social administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
};

export const defaultGraphMetric = 'TOTAL_ENGAGEMENTS';

export const timeScaleTypes = {
  DAILY: {
    value: 'DAILY',
    label: 'By Day',
  },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'By Month',
  },
};

export const yourTweetsMediaTypes = ['TWITTER_OWNED', 'TWITTER_OWNED_LINK', 'TWITTER_OWNED_TEXT'];

export const twitterMediaCardDefaults = {
  labels: {
    engagements: 'Total Engagements',
    urlClicks: 'Link Clicks',
    follows: 'User Follows',
    userProfileClicks: 'Profile Clicks',
  },
  metrics: ['engagementRate', 'engagements', 'impressions', 'likes'],
};

export const filterTypes = {
  GRAPH: 'Graph',
  DATE: 'Date',
  SCALE: 'Scale',
};

export const yourTweetsSortOptions = [
  {
    value: 'DATE',
    label: 'Recently Published',
  },
  {
    value: 'TWITTER_REPLIES',
    label: 'Replies',
    field: 'replies',
  },
  {
    value: 'TWITTER_ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    field: 'engagementRate',
  },
  {
    value: 'TWITTER_RETWEETS',
    label: 'Reposts',
    field: 'retweets',
  },
  {
    value: 'TWITTER_IMPRESSIONS',
    label: 'Impressions',
    field: 'impressions',
  },
  {
    value: 'TWITTER_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
    field: 'engagements',
  },
  {
    value: 'TWITTER_LIKES',
    label: 'Likes',
    field: 'likes',
  },
  {
    value: 'TWITTER_TOTAL_RETWEETS',
    label: 'Total Reposts',
    field: 'totalRetweets',
  },
  {
    value: 'TWITTER_URL_CLICKS',
    label: 'Link Clicks',
    field: 'urlClicks',
  },
  {
    value: 'TWITTER_FOLLOWS',
    label: 'User Follows',
    field: 'follows',
  },
  {
    value: 'TWITTER_USER_PROFILE_CLICKS',
    label: 'Profile Clicks',
    field: 'userProfileClicks',
  },
  {
    value: 'TWITTER_VIDEO_VIEWS',
    label: 'Video Views',
    field: 'videoViews',
  },
  {
    value: 'TWITTER_QUOTE_TWEETS',
    label: 'Quote Posts',
    field: 'quoteTweets',
  },
];

export const yourTweetsDefaultSort = 'DATE';

export const yourTweetsFilterOptions = [
  { label: 'Media Type', value: 'media_types' },
  { label: 'Post', value: 'tweet_keywords' },
];

export const yourTweetsWidgets = {
  media_types: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Media Type',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Photo', value: 'PHOTO' },
        { label: 'Video', value: 'VIDEO' },
        { label: 'Carousel', value: 'CAROUSEL' },
        { label: 'Text', value: 'TEXT' },
        { label: 'Link', value: 'LINK' },
      ],
    },
  },
  tweet_keywords: {
    name: 'MediaFilterSearchWidget',
    label: 'Post',
    options: {
      placeholder: 'Enter keywords, #hashtags or @mentions',
    },
  },
};

export const galleriesSortOptions = [
  {
    value: 'CREATED',
    label: 'Recently Added',
  },
  {
    value: 'TWITTER_AVG_ENGAGEMENT_RATE',
    label: 'Avg. Engagement Rate',
  },
  {
    value: 'TWITTER_IMPRESSIONS',
    label: 'Impressions',
  },
  {
    value: 'TWITTER_LIKES',
    label: 'Likes',
  },
  {
    value: 'TWITTER_RETWEETS',
    label: 'Reposts',
  },
  {
    value: 'TWITTER_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
  },
];

export const twitterGalleriesDetailSortOptions = [
  {
    label: 'Recently Published',
    value: '-DATE',
  },
  {
    value: '-TWITTER_REPLIES',
    label: 'Replies',
    field: 'replies',
  },
  {
    value: '-TWITTER_ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    field: 'engagementRate',
  },
  {
    value: '-TWITTER_RETWEETS',
    label: 'Reposts',
    field: 'retweets',
  },
  {
    value: '-TWITTER_IMPRESSIONS',
    label: 'Impressions',
    field: 'impressions',
  },
  {
    value: '-TWITTER_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
    field: 'engagements',
  },
  {
    value: '-TWITTER_URL_CLICKS',
    label: 'Link Clicks',
    field: 'urlClicks',
  },
  {
    value: '-TWITTER_TOTAL_RETWEETS',
    label: 'Total Reposts',
    field: 'totalRetweets',
  },
  {
    value: '-TWITTER_USER_PROFILE_CLICKS',
    label: 'Profile Clicks',
    field: 'userProfileClicks',
  },
  {
    value: '-TWITTER_FOLLOWS',
    label: 'User Follows',
    field: 'follows',
  },
  {
    value: '-TWITTER_QUOTE_TWEETS',
    label: 'Quote Posts',
    field: 'quoteTweets',
  },
  {
    value: '-TWITTER_VIDEO_VIEWS',
    label: 'Video Views',
    field: 'videoViews',
  },
];

export const twitterGalleryCardDefaults = {
  metrics: [
    'twitterTotalEngagements',
    'twitterAvgEngagementRate',
    'twitterRetweets',
    'twitterLikes',
    'twitterImpressions',
  ],
  labels: {
    twitterTotalEngagements: 'Total Engagements',
    twitterAvgEngagementRate: 'Avg. Engagement Rate',
    twitterRetweets: 'Reposts',
    twitterLikes: 'Likes',
    twitterImpressions: 'Impressions',
  },
};

export const twitterMetricFormats = {
  twitterAvgEngagementRate: 'percent',
};

export const galleriesFilterWidgetsConfig = {
  tags: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
    },
  },
};

export const galleriesFilterOptions = [
  {
    label: 'Tags',
    value: 'tags',
  },
];

export const routeNames = {
  YOUR_TWEETS: 'twitter.yourTweets',
};

export const twitterConnectionMessages = {
  message: `To see account insights you are required to connect your X account.`,
};

export const twitterTopLineStatsMetrics = [
  {
    value: 'NEW_FOLLOWERS',
    label: 'Net New Followers',
  },
  {
    value: 'ENGAGEMENTS_TOTAL',
    label: 'Total Engagements',
    extendedMetrics: [
      {
        value: 'ENGAGEMENTS_ORGANIC',
        label: 'Organic',
      },
      {
        value: 'ENGAGEMENTS_PROMOTED',
        label: 'Promoted',
      },
    ],
  },
  {
    value: 'AVG_ENGAGEMENT_RATE_TOTAL',
    label: 'Avg. Engagement Rate',
    percent: true,
    extendedMetrics: [
      {
        value: 'AVG_ENGAGEMENT_RATE_ORGANIC',
        label: 'Organic',
        percent: true,
      },
      {
        value: 'AVG_ENGAGEMENT_RATE_PROMOTED',
        label: 'Promoted',
        percent: true,
      },
    ],
  },
  {
    value: 'LIKES_TOTAL',
    label: 'Likes',
    extendedMetrics: [
      {
        value: 'LIKES_ORGANIC',
        label: 'Organic',
      },
      {
        value: 'LIKES_PROMOTED',
        label: 'Promoted',
      },
    ],
  },
  {
    value: 'IMPRESSIONS_TOTAL',
    label: 'Impressions',
    extendedMetrics: [
      {
        value: 'IMPRESSIONS_ORGANIC',
        label: 'Organic',
      },
      {
        value: 'IMPRESSIONS_PROMOTED',
        label: 'Promoted',
      },
    ],
  },
  {
    value: 'URL_CLICKS_TOTAL',
    label: 'Link Clicks',
    extendedMetrics: [
      {
        value: 'URL_CLICKS_ORGANIC',
        label: 'Organic',
      },
      {
        value: 'URL_CLICKS_PROMOTED',
        label: 'Promoted',
      },
    ],
  },
  {
    value: 'VIDEO_VIEWS',
    label: 'Video Views',
    extendedMetrics: [
      {
        value: 'VIDEO_VIEWS_ORGANIC',
        label: 'Organic',
      },
      {
        value: 'VIDEO_VIEWS_PROMOTED',
        label: 'Promoted',
      },
    ],
  },
];

export const SORT_DETAILS = Object.freeze({
  X_BOARDS: {
    options: [
      { label: 'Recently Added', value: 'CREATED' },
      { label: 'Avg. Engagement Rate', value: 'TWITTER_AVG_ENGAGEMENT_RATE' },
      { label: 'Impressions', value: 'TWITTER_IMPRESSIONS' },
      { label: 'Likes', value: 'TWITTER_LIKES' },
      { label: 'Reposts', value: 'TWITTER_RETWEETS' },
      { label: 'Total Engagements', value: 'TWITTER_TOTAL_ENGAGEMENTS' },
    ],
    default: {
      sortOrder: 'DESC',
      selected: { label: 'Recently Added', value: 'CREATED' },
    },
  },
});

export default {
  twitterGalleryCardDefaults,
  twitterGalleriesDetailSortOptions,
  galleriesSortOptions,
};
