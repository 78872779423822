<template>
  <img
    :alt="alt"
    :src="imageUrl"
    :srcset="srcset"
    :style="imgStyle"
    :width="width"
    :height="height"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { imageFitValidator, ImageAPI, IMAGE_API_DOMAINS, SOURCE_DOMAINS } from '@/utils/imageApi';

/**
 * A component intended to wrap all images within Dash Social. Handles three
 * types of scenarios:
 *
 * 1. If the URL is from our S3, CDN, or CloudFront domains, convert it to an
 *    images.dashhudson.com URL. If width or height are provided as props, double them to provide
 *    an alternative for high-DPI displays.
 * 2. If the URL is from images.dashhudson.com, overwrite the width, height, and
 *    fit with any provided parameters. If width or height are provided as props or in the URL,
 *    double them to provide an alternative for high-DPI displays.
 * 3. If the URL does not fit 1 or 2 (i.e. an external URL), pass it through
 *    unmodified
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  props: {
    /**
     * Specifies an alternate text for the image, if the image cannot be displayed.
     */
    alt: { type: String, default: null },
    /**
     * The source url of the image to be displayed.
     */
    url: {
      type: String,
      required: true,
    },
    /**
     * How the image should be resized to fit the provided dimensions.
     */
    fit: {
      type: String,
      default: null,
      validator: imageFitValidator,
    },
    /**
     * The CSS styles which are to be bound to the img tag.
     */
    imgStyle: { type: Object, default: () => {} },
    /**
     * The number of pixels wide the resultant image should be.
     */
    width: {
      type: Number,
      default: null,
    },
    /**
     * The number of pixels high the resultant image should be.
     */
    height: {
      type: Number,
      default: null,
    },
  },
  computed: {
    imageUrl() {
      if (this.url?.length > 0) {
        const originalUrl = new URL(this.url);
        const domain = originalUrl.hostname;

        if (SOURCE_DOMAINS.includes(domain)) {
          return ImageAPI.url(this.url, { w: this.width, h: this.height }, this.fit);
        }
        if (IMAGE_API_DOMAINS.includes(domain)) {
          const originalParams = new URLSearchParams(originalUrl.search);
          const newUrl = new URL(`${originalUrl.origin}${originalUrl.pathname}`);
          const newWidth = this.width || originalParams.get('w');
          const newHeight = this.height || originalParams.get('h');
          const newFit = this.fit || originalParams.get('fit');

          if (newWidth) {
            newUrl.searchParams.append('w', newWidth);
          }
          if (newHeight) {
            newUrl.searchParams.append('h', newHeight);
          }
          if (newFit) {
            newUrl.searchParams.append('fit', newFit);
          }
          return newUrl.toString();
        }
      }
      return this.url;
    },
    srcset() {
      if (this.imageUrl) {
        const urlObj = new URL(this.imageUrl);
        const width = urlObj.searchParams.get('w');
        const height = urlObj.searchParams.get('h');

        if (width) {
          urlObj.searchParams.set('w', Number(width) * 2);
        }
        if (height) {
          urlObj.searchParams.set('h', Number(height) * 2);
        }

        if (width || height) {
          return `${urlObj.toString()} 2x`;
        }
      }
      return null;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
img {
  width: 100%;
  display: block;
  height: auto;
}
</style>
