function getAsset(path) {
  return new URL(`./assets/${path}`, import.meta.url).href;
}

export const brandingDashSocial = {
  appName: 'Dash Social',
  abbreviated: 'Dash',

  logoPrimary: getAsset('dashsocial/dash-social-logo-primary-black.svg'),
  iconPrimary: getAsset('dashsocial/dash-social-icon-primary.svg'),
  iconPlaceholder: getAsset('dashsocial/dash-social-icon-primary.svg'),

  // These are alternate variants of the branding that are currently unused
  logoPrimaryWhite: getAsset('dashsocial/dash-social-logo-primary-white.svg'),
  logoBlack: getAsset('dashsocial/dash-social-logo-black.svg'),
  logoWhite: getAsset('dashsocial/dash-social-logo-white.svg'),

  iconBlack: getAsset('dashsocial/dash-social-icon-black.svg'),
  iconWhite: getAsset('dashsocial/dash-social-icon-white.svg'),
};
