<script setup>
// Vue
import { computed, ref } from 'vue';
// Stores
import { useAuthStore } from '@/stores/auth';
import { useFlagStore } from '@/stores/flag';
import { useTrackingStore } from '@/stores/tracking';
// Components
import BrandAvatar from '@/components/core/BrandAvatar.vue';
import ChannelIcon from '@/components/core/ChannelIcon.vue';
import ConnectButton from '@/components/ConnectButton.vue';
import ContentBlock from '@/components/common/ContentBlock.vue';
import DHTerms from '@/components/DHTerms.vue';
import Icon from '@/components/foundation/Icon.vue';
import Popup from '@/components/Popup.vue';
import Avatar from '@/components/foundation/Avatar.vue';
// Composables
import { useOverflowScroll } from '@/composables/useOverflowScroll';
// Utils
import { formatPlatformLabel, formatPlatformConnectionLabel } from '@/utils/formatters';
// Constants
import { educationalModalCopy } from '@/app/auth/constants';
import { PLATFORM_CONNECTION_NAMES } from '@/models/platform/platform-connection.enum';
// Assets
import { branding } from '@/config';

const authStore = useAuthStore();
const flagStore = useFlagStore();
const trackingStore = useTrackingStore();

const props = defineProps({
  popupType: { type: String, default: 'small' },
  platform: { type: String, default: null },
  onCancel: { type: Function, required: true },
  showTerms: { type: Boolean, default: false },
  brand: { type: Object, default: null },
  bypassConnectionCheck: { type: Boolean, default: false },
});

const parentRef = ref(null);
const childRef = ref(null);
const { isOverflowing, bottomArrived } = useOverflowScroll(parentRef, childRef);

const title = 'Prepare to Connect';

const inlineStyles = {
  padding: 0,
};

const hasAdAccountConnectionFlag = computed(
  () => flagStore.ready && flagStore.flags.adAccountConnection,
);

const channelType = computed(() => {
  // TODO sc-106472: Remove this if statement
  if (props.platform === 'facebook_ads' && hasAdAccountConnectionFlag.value) {
    return 'facebook_ads_new';
  }
  switch (props.platform) {
    case PLATFORM_CONNECTION_NAMES.FACEBOOK:
      return 'facebook';
    case PLATFORM_CONNECTION_NAMES.INSTAGRAM:
      return 'instagram';
    case 'facebook_sandbox':
      return 'instagram';
    case 'pinterest_v5':
      return 'pinterest';
    default:
      return props.platform;
  }
});

const channelAvatar = computed(() => {
  // TODO sc-106472: Remove this if statement and alter the original ternary operator to return `meta`
  if (channelType.value === 'facebook_ads_new' && hasAdAccountConnectionFlag.value) {
    return 'meta';
  }
  return props.platform === 'facebook_ads' ? 'facebook' : channelType.value;
});

const brandToConnect = computed(() => (props.brand ? props.brand : authStore.currentBrand));

const hasAvatar = computed(() => {
  return (
    brandToConnect.value?.avatarUrl ||
    brandToConnect.value?.avatar_url ||
    brandToConnect.value?.avatar
  );
});

const buttonText = computed(() => {
  return `Continue with ${formatPlatformLabel(props.platform)}`;
});

const content = computed(() => educationalModalCopy?.[channelType.value]?.info);

function mixpanelTrack(params) {
  const payload = {
    platformType: props.platform,
    ...params,
  };
  trackingStore.track('Platform Connection Prepare To Connect Interaction', payload);
}

function handleFooterMixpanel(data) {
  const params = {
    buttonClicked: data.text,
    linkUrl: data.url,
  };
  mixpanelTrack(params);
}

defineExpose({
  channelType,
  channelAvatar,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <Popup
    :close="onCancel"
    responsive
    :type="popupType"
    class="educational-popup-container"
    :inline-styles="inlineStyles"
    show-when-drawer-open
  >
    <div
      class="header-row flex flex-col items-center p-10"
      data-cy="educational-connection-modal-title-box"
    >
      <div class="mx-auto flex items-center justify-between">
        <BrandAvatar v-if="hasAvatar" :brand="brandToConnect" large class="force-max-dimensions" />
        <Avatar v-else :img-src="branding.iconPrimary" tooltip="" large data-cy="DHAvatar" />
        <Icon class="mx-2" name="retweet" />
        <ChannelIcon :value="channelAvatar" xxlarge />
      </div>
      <p class="mb-2 mt-6 text-xl capitalize text-[--text-primary]">
        <!-- TODO sc-106472: Remove this check and just use platform-->
        {{
          formatPlatformConnectionLabel(
            platform === 'facebook_ads' && hasAdAccountConnectionFlag
              ? 'facebook_ads_new'
              : platform,
          )
        }}
      </p>
      <p class="text-base text-[--text-primary]" data-cy="educational-connection-modal-title">
        {{ title }}
      </p>
    </div>
    <div ref="parentRef" class="wrapper-container relative mx-10">
      <div ref="childRef" class="wrapper">
        <div class="info-box d-grid">
          <ContentBlock
            v-for="(block, index) in content"
            :key="index"
            :appearance="block.style"
            :content="block.content"
            :icon="block.icon"
            :title="block.title"
          >
            <template #action>
              <p v-if="block.linkUrl && block.linkText" class="text-small">
                <a
                  class="block-link"
                  :href="block.linkUrl"
                  target="_blank"
                  rel="noopener"
                  @click="
                    mixpanelTrack({
                      areaHeading: block.title,
                      buttonClicked: block.linkText,
                      linkUrl: block.linkUrl,
                    })
                  "
                >
                  {{ block.linkText }}
                </a>
              </p>
            </template>
          </ContentBlock>
        </div>
      </div>
    </div>
    <div class="cta-box relative p-10">
      <Transition name="fade"
        ><div
          v-if="!bottomArrived && isOverflowing"
          class="absolute left-0 top-0 block h-12 w-full translate-y-[-100%] bg-gradient-to-b from-transparent to-[#00000014]"
        ></div
      ></Transition>
      <div class="cta-button">
        <ConnectButton
          :platform="platform"
          :brand="brand"
          :open-popup="false"
          :custom-text="buttonText"
          :bypass-connection-check="bypassConnectionCheck"
        />
      </div>
      <DHTerms v-if="showTerms" @link-clicked="handleFooterMixpanel" />
    </div>
  </Popup>
</template>

<style lang="postcss" scoped>
.educational-popup-container {
  .wrapper-container {
    overflow-y: auto;
  }

  .wrapper {
    min-height: 100%;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .info-box {
    display: grid;
    grid-gap: var(--space-12);
    height: 100%;
    overflow: auto;
  }

  .cta-box {
    background-color: var(--background-0);
    border-radius: 0 0 var(--round-corner) var(--round-corner);

    .cta-button {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }
}
</style>
