import { CHANNELS } from '@/models/dashboards/channels.enum';

import { colours } from '@/ux/colours';

const CUSTOM_METRIC_REGEX = {
  FORMULA_METRIC_PATTERN: /['[A-Z_]+:[A-Z_]+'\]/g,
  PERMITTED_FORMULA_CHARACTERS_PATTERN: /[+\-*/x().]|\b\d+(\.\d+)?\b|\['[A-Z_]+:[A-Z_]+'\]/g,
  PERMITTED_TEXT_NODE_CHARACTERS_PATTERN: /^[\d()+\-*/x.A-Za-z#\s]+$/,
  FORMULA_NUMBERS_PATTERN: /-?\b\d+(\.\d+)?\b/,
  SANITIZED_FORMULA_PATTERN: /^[\d()+\-*/x.]+$/,
  IMPLICIT_LEFT_MULTIPLICATION_SUBSTITUTION: /(\d|\))\(/g,
  IMPLICIT_RIGHT_MULTIPLICATION_SUBSTITUTION: /\)(\d)/g,
  DISALLOW_NUMBER_METRIC_DIRECT_PATTERN: /(\d|\))(?=\[)|\](?=\d)/g,
};

const CUSTOM_METRIC_FORMULA_ERRORS = {
  UNSUPPORTED_CHARACTERS:
    'Unsupported characters in formula. Valid characters include numbers and () + * / - .',
  EVALUATION_ERROR:
    "There's an issue with this calculation. Please review and make corrections to proceed.",
};

const CREATE_CUSTOM_METRIC_BUTTON = {
  text: 'Create Custom Metric',
  disabledTooltip:
    'Your user is not authorized to Manage Custom metrics. Please contact your system admin.',
};

const GET_CUSTOM_METRIC_DETAILS = {
  limit: 30,
};

const EMPTY_LIST_PROPS = {
  title: `You don't have any custom metrics yet`,
  image: 'empty-field2.png',
  buttonText: CREATE_CUSTOM_METRIC_BUTTON.text,
  disabledTooltip: CREATE_CUSTOM_METRIC_BUTTON.disabledTooltip,
};

const CREATE_CUSTOM_METRIC_BUILDER_HEADER_TEXT = 'Create a Custom Metric';
const EDIT_CUSTOM_METRIC_BUILDER_HEADER_TEXT = 'Edit Custom Metric';
const DUPLICATE_CUSTOM_METRIC_BUILDER_HEADER_TEXT = 'Duplicate Custom Metric';
const CUSTOM_METRIC_BACK_BUTTON_TEXT = 'Back';
const CUSTOM_METRIC_NEXT_BUTTON_TEXT = 'Next';
const CUSTOM_METRIC_SAVE_BUTTON_TEXT = 'Save';
const METRIC_DETAILS_TEXT_THRESHOLD = 0.7;

const CUSTOM_METRIC_MEDIA_POPUP_HEADER_TEXT = 'Custom Metrics';

const INSERT_METRIC_BUTTON_SELECT = '[data-cy="insert-metric-button"]';

const CUSTOM_METRICS_USAGE_WARNING_PERCENTAGE = 70;

const CUSTOM_METRIC_STEP = {
  BRAND_STEP: 1,
  FORMULA_STEP: 2,
  METRIC_DETAILS_STEP: 3,
};

const CUSTOM_METRIC_STEPS_LABEL = [
  {
    stepNumber: CUSTOM_METRIC_STEP.BRAND_STEP,
    stepLabel: 'Select Brands',
    panelName: 'brand',
  },
  {
    stepNumber: CUSTOM_METRIC_STEP.FORMULA_STEP,
    stepLabel: 'Build Formula',
    panelName: 'formula',
  },
  {
    stepNumber: CUSTOM_METRIC_STEP.METRIC_DETAILS_STEP,
    stepLabel: 'Metric Details',
    panelName: 'metric-details',
  },
];

const CUSTOM_METRIC_BUILDER_HEADER_DESC_BY_STEP = {
  [CUSTOM_METRIC_STEP.BRAND_STEP]: {
    description: 'Select which brands this metric will be available for',
  },
  [CUSTOM_METRIC_STEP.FORMULA_STEP]: {
    description: 'Build your formula',
  },
  [CUSTOM_METRIC_STEP.METRIC_DETAILS_STEP]: {
    description: 'Additional Options about your formula',
  },
};

const CUSTOM_METRICS_NEXT_STEP_BUTTON_OPTIONS = {
  BRAND_SELECTION_PANEL_SELECT_ONE_BRAND:
    'You must select at least one valid brand before proceeding',
  FORMULA_BUILDER_PANEL_FORMULA_ERROR: 'Resolve formula errors before proceeding',
  METRIC_DETAILS_PANEL_CHANNEL_ERROR:
    'Please remove any disconnected channels from your formula before saving',
  METRIC_DETAILS_PANEL_NO_CHANGES: "You haven't made any changes yet",
};

const TOOLTIPS = {
  METRIC_TYPE_PERCENTAGE: 'Choose this if the metric is supposed to calculate a percentage.',
  METRIC_TYPE_INFO:
    'Determines if your metric will receive a % at the end and if the formula will be altered to make it a percentage',
  AGGREGATION_INFO:
    'When your custom metric is used to calculate a total, there are different methods to reach the total sum. You can choose below how to reach that total sum. Learn more about each option.',
  CHANNEL_DISABLED: 'This account has been disconnected',
};

const METRIC_TYPE = {
  FLOAT: {
    value: 'FLOAT',
    label: 'Numeric (123)',
  },
  PERCENTAGE: {
    value: 'PERCENTAGE',
    label: 'Percentage (%)',
  },
};

const TREND_INTERPRETATION = {
  GROWTH: {
    value: 'INCLINE',
    label: 'Growth',
  },
  DECLINE: {
    value: 'DECLINE',
    label: 'Decline',
  },
};

const FORMULA_BUILDER = {
  METRIC_TYPE: 'Metric Type',
  AGGREGATION: 'Aggregation',
  METRIC_FORMULA: 'Metric Calculation',
  HELP_TEXT:
    'Need help? Explore examples and step-by-step instructions for creating custom metric calculations in our',
  RESOURCE_CENTRE_LINK: 'Resource Centre Article.',
  DESCRIPTION: 'Start typing a metric name or use # to open the metric dropdown',
  METRIC_TYPE_OPTIONS: [
    { value: METRIC_TYPE.FLOAT.value, label: METRIC_TYPE.FLOAT.label },
    {
      value: METRIC_TYPE.PERCENTAGE.value,
      label: METRIC_TYPE.PERCENTAGE.label,
      tooltip: TOOLTIPS.METRIC_TYPE_PERCENTAGE,
    },
  ],
};

const TOOLTIP_DELAY = {
  show: 800,
  hide: 100,
};

const METRIC_DETAILS = {
  METRIC_NAME: {
    value: 'METRIC_NAME',
    label: 'Metric Name',
    placeholder: 'Enter a name for this metric',
    maxNumCharacters: 100,
    duplicateErrorMessage: 'A metric with this name already exists',
  },
  METRIC_DESCRIPTION: {
    value: 'METRIC_DESCRIPTION',
    label: 'Description ',
    placeholder: 'Enter a short description of the metric',
    maxNumCharacters: 500,
    info: "Adding a description provides context for your team. The metric's hover text will automatically display this description and the calculation.",
    duplicateErrorMessage: 'A metric with this description already exists',
  },
  DESIRED_OUTCOME: {
    value: 'DESIRED_OUTCOME',
    label: 'Desired Outcome',
    options: [
      {
        label: TREND_INTERPRETATION.GROWTH.label,
        value: TREND_INTERPRETATION.GROWTH.value,
        fontClass: 'text-[12px]',
      },
      {
        label: TREND_INTERPRETATION.DECLINE.label,
        value: TREND_INTERPRETATION.DECLINE.value,
        fontClass: 'text-[12px]',
      },
    ],
    tooltip: {
      content: 'Select whether you want to view growth or decline for your desired outcome.',
      theme: 'dh-tooltip-small',
    },
  },
};

export const AVAILABLE_CUSTOM_METRIC_CHANNELS = [
  CHANNELS.FACEBOOK.value,
  CHANNELS.INSTAGRAM.value,
  CHANNELS.TWITTER.value,
  CHANNELS.LINKEDIN.value,
  CHANNELS.PINTEREST.value,
  CHANNELS.TIKTOK.value,
  CHANNELS.INSTAGRAM_STORIES.value,
];

const CUSTOM_METRICS_RADIO_GROUP_OPTIONS = Object.freeze({
  BRANDS: { label: 'Brands', value: 'Brands' },
  BRAND_TAGS: { label: 'Tags', value: 'Tags' },
});

const CUSTOM_METRICS_USAGE_THRESHOLD_COLORS = Object.freeze({
  OK: colours.SUCCESS.SUCCESS_500,
  WARNING: colours.ALERT.ALERT_500,
  LIMIT_REACHED: colours.ERROR.ERROR_500,
});

const AGGREGATION_TYPE_INFO =
  'Note: Aggregations can be selected when creating reports with this metric. There’s no need to include them in your formula.';

const AGGREGATION_TYPE_INFO_LINK_INFO = 'Learn more about aggregation in our Resource Center';
const CUSTOM_METRICS_RESOURCE_CENTRE_LINK =
  'https://help.dashsocial.com/hc/en-us/articles/32624748311693';

const NOTIFICATION_MESSAGES = Object.freeze({
  createCustomMetricSuccess:
    'Your custom metric has been created. It may take 24 hours for your custom metric to show.',
  updateCustomMetricSuccess:
    'Your custom metric has been updated. It may take 24 hours for your custom metric to show.',
  duplicateCustomMetricSuccess: 'Your custom metric has been duplicated.',
  createCustomMetricError: 'An error has occurred. Please try again.',
  customMetricNameExists: 'A metric with this name already exists. Please choose a different name.',
  updateCustomMetricError:
    'An error has occurred while updating the custom metric. Please try again.',
  duplicateCustomMetricError:
    'An error has occurred while duplicating the custom metric. Please try again.',
});

const CUSTOM_METRIC_BUILDER_INITIAL_VALUE = Object.freeze({
  aggregation: 'SUM', // TODO: remove when SC-135035 is completed
  brandIds: [],
  formula: '["INSTAGRAM:ORGANIC_SHARES"]+["INSTAGRAM:ORGANIC_SHARES"]', // TODO: remove when SC-135035 is completed
  metricFormat: 'FLOAT', // TODO: remove when SC-135035 is completed
  name: '',
  description: '',
  step: 0,
  trendInterpretation: METRIC_DETAILS.DESIRED_OUTCOME.options[0].value,
});

const MATH_OPERATORS = {
  DIVIDE: '/',
  MULTIPLY_ASTERISK: '*',
  ADD: '+',
  SUBTRACT: '-',
  OPEN_PARENTHESIS: '(',
  CLOSE_PARENTHESIS: ')',
  MULTIPLY_X: 'x',
};

const LAUNCH_METRIC_POPUP_SYMBOL = '#';

const CUSTOM_METRIC_SAVE_MODES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
};

const NO_METRICS_FOUND = 'No metrics found. Try adjusting your search.';

const CUSTOM_METRIC_DELAY_MESSAGES = {
  BANNER:
    'One or more custom metrics have been created or updated. It may take up to 24 hours for them to be fully visible across the platform.',
  METRIC:
    'Recently updated/created. It may take up to 24 hours for your custom metric to appear throughout the platform.',
};

const CUSTOM_METRIC_ERROR_MESSAGES = {
  BANNER:
    'One or more of your custom metrics below has an error. Edit or delete it to resolve the issue.',
  METRIC:
    'One or more errors has been found with this custom metric. Edit the calculation to continue using it.',
  CHIP: 'This account has been disconnected',
  ERROR_NOT_RETURNED: 'Error not returned',
  CONTAINS_ERROR: 'Yes',
  CUSTOM_METRICS_ERROR_MEDIA_POPUP:
    'Custom metrics failed to load for this media item. Please try again later or contact support if the issue persists.',
};

const FORMATTED_TYPE_DISPLAY = {
  PERCENTAGE: 'Percentage (%)',
  FLOAT: 'Numeric (123)',
};

const FORMATTED_TREND_INTERPRETATION = {
  INCLINE: 'Growth is desired',
  DECLINE: 'Decline is desired',
};

const CHANNEL_TO_CONNECTION_FIELD_MAP = {
  facebook: 'facebook',
  instagram: 'instagram_page',
  instagramStories: 'instagram_page',
  instagram_stories: 'instagram_page',
  linkedin: 'linkedin',
  pinterest: 'pinterest_v5',
  tiktok: 'tiktok',
  twitter: 'twitter',
};

const CHANNEL_FROM_FORMULA_TO_MEDIA_ITEM_MAP = {
  facebook: 'FACEBOOK',
  instagram: 'INSTAGRAM',
  instagramStories: 'INSTAGRAM_STORY',
  linkedin: 'LINKEDIN',
  pinterest: 'PINTEREST',
  tiktok: 'TIKTOK',
  twitter: 'TWITTER',
};

const PRE_DEFINED_CHANNELS = [
  'facebook',
  'instagram',
  'instagramStories',
  'linkedin',
  'pinterest',
  'tiktok',
  'twitter',
];

const ACTION_MAP = {
  [CUSTOM_METRIC_SAVE_MODES.CREATE]: 'Created',
  [CUSTOM_METRIC_SAVE_MODES.UPDATE]: 'Updated',
  [CUSTOM_METRIC_SAVE_MODES.DUPLICATE]: 'Duplicated',
  [CUSTOM_METRIC_SAVE_MODES.DELETE]: 'Deleted',
};

const CUSTOM_METRIC_MIXPANEL_EVENTS = {
  SAVED: 'Custom Metric Saved',
  CREATE: 'Custom Metric Create Metric',
  STEPS: 'Custom Metric Creation - Steps',
  UPDATE: 'Custom Metric Update',
  ERROR: 'Custom Metric Error Message',
};

const WIZARD_PANEL_DIRECTION = {
  FORWARD: 'slide-right',
  BACKWARD: 'slide-left',
};

const CUSTOM_METRICS_REPORT_DISABLED_TOOLTIP =
  'None of the brands within your organization have access to custom metrics';

const CUSTOM_METRIC_LANDING_PAGE = {
  TEXT: 'Create and modify existing metrics to report on your performance exactly the way you need.',
  LINK_TEXT: 'Learn more about custom metrics.',
};

export default {
  CUSTOM_METRIC_REGEX,
  CREATE_CUSTOM_METRIC_BUTTON,
  EMPTY_LIST_PROPS,
  GET_CUSTOM_METRIC_DETAILS,
  CREATE_CUSTOM_METRIC_BUILDER_HEADER_TEXT,
  EDIT_CUSTOM_METRIC_BUILDER_HEADER_TEXT,
  DUPLICATE_CUSTOM_METRIC_BUILDER_HEADER_TEXT,
  CUSTOM_METRIC_BUILDER_HEADER_DESC_BY_STEP,
  CUSTOM_METRIC_BACK_BUTTON_TEXT,
  CUSTOM_METRIC_NEXT_BUTTON_TEXT,
  CUSTOM_METRIC_SAVE_BUTTON_TEXT,
  CUSTOM_METRIC_MEDIA_POPUP_HEADER_TEXT,
  CUSTOM_METRICS_USAGE_WARNING_PERCENTAGE,
  INSERT_METRIC_BUTTON_SELECT,
  TOOLTIPS,
  FORMULA_BUILDER,
  TOOLTIP_DELAY,
  AVAILABLE_CUSTOM_METRIC_CHANNELS,
  METRIC_DETAILS,
  METRIC_DETAILS_TEXT_THRESHOLD,
  CUSTOM_METRICS_RADIO_GROUP_OPTIONS,
  CUSTOM_METRICS_USAGE_THRESHOLD_COLORS,
  AGGREGATION_TYPE_INFO,
  AGGREGATION_TYPE_INFO_LINK_INFO,
  CUSTOM_METRICS_RESOURCE_CENTRE_LINK,
  NOTIFICATION_MESSAGES,
  CUSTOM_METRIC_BUILDER_INITIAL_VALUE,
  METRIC_TYPE,
  TREND_INTERPRETATION,
  MATH_OPERATORS,
  LAUNCH_METRIC_POPUP_SYMBOL,
  CUSTOM_METRIC_SAVE_MODES,
  NO_METRICS_FOUND,
  CUSTOM_METRIC_DELAY_MESSAGES,
  CUSTOM_METRIC_ERROR_MESSAGES,
  FORMATTED_TYPE_DISPLAY,
  FORMATTED_TREND_INTERPRETATION,
  CHANNEL_TO_CONNECTION_FIELD_MAP,
  PRE_DEFINED_CHANNELS,
  ACTION_MAP,
  CUSTOM_METRIC_MIXPANEL_EVENTS,
  CUSTOM_METRIC_STEPS_LABEL,
  WIZARD_PANEL_DIRECTION,
  CUSTOM_METRICS_REPORT_DISABLED_TOOLTIP,
  CHANNEL_FROM_FORMULA_TO_MEDIA_ITEM_MAP,
  CUSTOM_METRIC_FORMULA_ERRORS,
  CUSTOM_METRICS_NEXT_STEP_BUTTON_OPTIONS,
  CUSTOM_METRIC_LANDING_PAGE,
};
