import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useSocialListeningStore } from '@/stores/social-listening';
import {
  CHANNEL_FILTER_OPTIONS,
  TRENDS_DEFAULT_FILTERS,
} from '@/app/socialListening/constants/listeningFilters';
import {
  getKeywordORString,
  getKeywordTypePayload,
  getStartAndEndDate,
} from '@/app/socialListening/utils/api-request-utils';
import cloneDeep from 'lodash/cloneDeep';
import {
  getFormattedKeywordPostVolumeTimeSeriesChartData,
  getFormattedSentimentChartData,
} from '@/app/socialListening/utils/formatters';
import { useListeningPermissions } from '@/app/socialListening/composables/useListeningPermissions';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { useSocialListeningGDI } from '@/app/socialListening/composables/useSocialListeningGDI';
import {
  POST_SORT_OPTIONS,
  POST_VOLUME_GRAPH_OPTIONS,
  TOP_POSTS_INITIAL_MAX_POSTS,
} from '@/app/socialListening/constants';
import { refreshCancelToken } from '@/apis/axios.utils';
import { groupByTotalPostsPerPeriodWithPrimaryBreakdown } from '@/app/socialListening/utils/graph-utils';
import { useSocketStore } from '@/stores/socket';
import { v4 as uuidv4 } from 'uuid';
import { convertToSearchBodyFilters } from '@/app/socialListening/utils/filter-utils';

export const useSocialListeningTrendsStore = defineStore('socialListeningTrends', () => {
  const socialListeningStore = useSocialListeningStore();
  const socketStore = useSocketStore();
  const { canAccessYoutubeCompliance } = useListeningPermissions();
  const socialListeningGDI = useSocialListeningGDI();

  // Refs
  const filters = ref({ ...cloneDeep(TRENDS_DEFAULT_FILTERS) });
  const temporaryFilters = ref({});

  const sentimentTrendTimeSeries = ref(null);

  const pending = ref({
    sentimentTrendTimeSeries: false,
    keywordPostVolumeTimeSeries: false,
  });

  const queryKeyword = ref('');
  const keywordPostVolumeTimeSeries = ref({ meters: null, tooltipData: null });
  const requestContext = reactive({});

  // Computed
  const searchFilters = computed(() => {
    return { ...(filters.value || {}), ...(temporaryFilters.value || {}) };
  });

  const sourceCreated = computed(() => {
    const { startDate, endDate } = getStartAndEndDate(searchFilters.value.dateRange);
    return { onOrAfter: startDate, onOrBefore: endDate };
  });

  function getChannels(channels = []) {
    if (canAccessYoutubeCompliance.value) {
      return channels;
    }
    return channels.filter((c) => c !== CHANNEL_FILTER_OPTIONS.YOUTUBE.value);
  }

  const selectedChannels = computed(() => {
    const channels = searchFilters.value.channels?.length
      ? searchFilters.value.channels
      : TRENDS_DEFAULT_FILTERS.channels;
    return getChannels(channels);
  });

  const selectedMediaTypes = computed(() => {
    return searchFilters.value.mediaTypes?.length
      ? searchFilters.value.mediaTypes
      : TRENDS_DEFAULT_FILTERS.mediaTypes;
  });

  const formattedFilters = computed(() => {
    const converted = convertToSearchBodyFilters({
      sources: selectedChannels.value,
      sentiment: searchFilters.value.sentiments,
      mediaTypes: selectedMediaTypes.value,
    });
    return {
      sourceCreated: sourceCreated.value,
      keywordsAndHashtags: getKeywordORString(
        searchFilters.value,
        socialListeningStore.topKeywords,
      ),
      ...omitBy(converted, isEmpty),
    };
  });

  const drawerSerializedFilters = computed(() => {
    const drawerFilters = {
      ...omitBy(searchFilters.value, isEmpty),
      keywordsAndHashtags: getKeywordORString(filters.value, socialListeningStore.topKeywords),
    };
    return mapValues(drawerFilters, (v, k) => {
      if (k === 'channels') {
        return selectedChannels.value?.join(',');
      }
      return Array.isArray(v) ? v?.join(',') : v;
    });
  });

  const scale = computed(() => {
    return socialListeningStore.calculateScale(formattedFilters.value);
  });

  const postVolumePayload = computed(() => {
    return {
      scale: scale.value,
      searchFilters: formattedFilters.value,
    };
  });

  // Functions
  function setFilters({
    channels,
    sentiments,
    mediaTypes,
    dateRange,
    keyword,
    keywordTypes,
    customDateRange,
  }) {
    filters.value = {
      channels: channels ? getChannels(channels) : channels,
      sentiments,
      mediaTypes,
      dateRange,
      keyword,
      keywordTypes,
      customDateRange,
    };
  }

  function resetFilters() {
    filters.value = { ...cloneDeep(TRENDS_DEFAULT_FILTERS) };
    queryKeyword.value = '';
  }

  function setKeywordFilter(keyword) {
    filters.value = {
      ...filters.value,
      keyword,
    };
  }

  function removeKeywordFilter() {
    filters.value = {
      ...filters.value,
      keyword: '',
    };
    socialListeningStore.enableTopLevelFilters();
  }

  function clearTempFilters() {
    temporaryFilters.value = {};
  }

  function setTempFilters(filtersObj) {
    temporaryFilters.value = { ...filtersObj };
  }

  async function getSentimentTrend() {
    try {
      sentimentTrendTimeSeries.value = null;
      pending.value.sentimentTrendTimeSeries = true;
      const result = await socialListeningStore.getSentimentTrendTimeSeries({
        searchFilters: formattedFilters.value,
        scale: scale.value,
      });
      sentimentTrendTimeSeries.value = getFormattedSentimentChartData(result, filters.value);
    } finally {
      pending.value.sentimentTrendTimeSeries = false;
    }
  }

  async function getTimeSeriesPostVolumeKeyword() {
    try {
      const graphOptions = POST_VOLUME_GRAPH_OPTIONS.TOTAL_POSTS;
      keywordPostVolumeTimeSeries.value = null;
      pending.value.keywordPostVolumeTimeSeries = true;
      const payload = {
        searchFilters: formattedFilters.value,
        scale: scale.value,
        breakdownBy: graphOptions.primaryBreakdown,
      };
      const cancelToken = refreshCancelToken(
        requestContext,
        'keywordPostVolumeTimeSeriesCancelToken',
      );
      const result = await socialListeningGDI.getTimeSeriesPostVolume(payload, cancelToken);
      const breakdownGroups = groupByTotalPostsPerPeriodWithPrimaryBreakdown(
        result,
        [],
        graphOptions.value,
        [],
        scale.value,
      );
      keywordPostVolumeTimeSeries.value = getFormattedKeywordPostVolumeTimeSeriesChartData(
        breakdownGroups,
        graphOptions.chartConfig,
        canAccessYoutubeCompliance,
      );
    } finally {
      pending.value.keywordPostVolumeTimeSeries = false;
    }
  }

  async function getTopPostsPreview(offsetValue = 0) {
    const topPostsFilters = { ...formattedFilters.value };
    if (temporaryFilters.value.keyword) {
      topPostsFilters.keywordsAndHashtags = temporaryFilters.value.keyword;
    }
    await socialListeningStore.getTopPostsPreview({
      filters: topPostsFilters,
      sorts: [`-${POST_SORT_OPTIONS.engagements.value}`, `-${POST_SORT_OPTIONS.date.value}`],
      paging: {
        limit: TOP_POSTS_INITIAL_MAX_POSTS,
        offset: offsetValue,
      },
    });
  }

  async function getMentionsBreakdown(graphOption = POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_CHANNEL) {
    const payload = {
      ...postVolumePayload.value,
      breakdownBy: graphOption.primaryBreakdown,
    };
    await socialListeningStore.getTimeSeriesPostVolume(payload, graphOption.value);
  }

  async function fetchPageData(skipTopKeywords = false) {
    if (!skipTopKeywords) {
      const { keywordsAndHashtags, ...filterData } = formattedFilters.value;
      const payload = {
        searchFilters: { ...filterData },
        ...getKeywordTypePayload(searchFilters.value.keywordTypes),
      };
      await socialListeningStore.getTopKeywords(payload);
    }

    if (socialListeningStore.topKeywords.length !== 0) {
      const promises = [];
      promises.push(getTopPostsPreview());
      if (socketStore?.id) {
        const socketId = socketStore.id;
        const requestId = uuidv4();
        promises.push(
          socialListeningStore.getVisualTrends({
            requestId,
            socketId,
            searchBody: {
              filters: { ...formattedFilters.value },
            },
          }),
        );
      }
      await Promise.allSettled(promises);
    } else {
      socialListeningStore.clearOverviewData(true);
    }
  }

  return {
    filters,
    temporaryFilters,
    searchFilters,
    drawerSerializedFilters,
    queryKeyword,
    removeKeywordFilter,
    setFilters,
    resetFilters,
    setKeywordFilter,
    clearTempFilters,
    setTempFilters,
    pending,
    getSentimentTrend,
    sentimentTrendTimeSeries,
    sourceCreated,
    getChannels,
    selectedChannels,
    formattedFilters,
    scale,
    keywordPostVolumeTimeSeries,
    getTimeSeriesPostVolumeKeyword,
    fetchPageData,
    getTopPostsPreview,
    getMentionsBreakdown,
  };
});
