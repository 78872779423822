import merge from 'lodash/merge';
import { createAxiosInstance, refreshCancelToken } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.dashboardApiUrl);
const cancelTokens = {};

function getAndSetCancelToken({
  brandIds,
  competitorSourceAccountIds,
  channels,
  metrics,
  reportType,
  aggregateBy,
  sourceAdAccountIds,
  sourceCampaignIds,
}) {
  const cancelData = {
    brandIds,
    competitorSourceAccountIds,
    channels,
    metrics,
    reportType,
    aggregateBy,
    sourceAdAccountIds,
    sourceCampaignIds,
  };
  return refreshCancelToken(cancelTokens, JSON.stringify(cancelData));
}

const api = {
  // Dashboards
  getDashboards(params) {
    return axios.get('/dashboards', {
      params,
    });
  },
  getDashboard({ dashboardId }) {
    return axios.get(`/dashboards/${dashboardId}`);
  },
  createDashboard({ data, params }) {
    return axios.post(
      '/dashboards',
      {
        ...data,
      },
      {
        params,
      },
    );
  },
  updateDashboard({ dashboardId, data }) {
    return axios.patch(`/dashboards/${dashboardId}`, {
      ...data,
    });
  },
  deleteDashboard({ dashboardId }) {
    return axios.delete(`/dashboards/${dashboardId}`);
  },
  // Reports
  getMetricTypes() {
    return axios.get('/metric_types');
  },
  getMetricDetails(params) {
    return axios.get('/metric_details', { params: { ...params } });
  },
  getComparableMetrics() {
    return axios.get(`/comparable_metrics`);
  },
  getReport({ dashboardId, reportId, cancelToken, params }) {
    return axios.get(`/dashboards/${dashboardId}/reports/${reportId}`, { params, cancelToken });
  },
  getGenericReportData(
    {
      brandIds,
      competitorSourceAccountIds,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      channels,
      metrics,
      reportType,
      timeScale,
      sortOrder,
      limit,
      aggregateBy,
      sourceAdAccountIds,
      sourceCampaignIds,
    },
    axiosConfig = {},
  ) {
    const cancelToken = getAndSetCancelToken({
      brandIds,
      competitorSourceAccountIds,
      channels,
      metrics,
      reportType,
      aggregateBy,
      sourceAdAccountIds,
      sourceCampaignIds,
    });

    return axios.put(
      '/reports/data',
      {
        competitor_source_accountIds: competitorSourceAccountIds,
        source_ad_account_ids: sourceAdAccountIds,
        source_campaign_ids: sourceCampaignIds,
      },
      merge(
        {
          params: {
            brandIds: Array.isArray(brandIds) ? brandIds.join(',') : brandIds,
            startDate,
            endDate,
            contextStartDate,
            contextEndDate,
            channels: Array.isArray(channels) ? channels.join(',') : channels,
            metrics: Array.isArray(metrics) ? metrics.join(',') : metrics,
            reportType,
            timeScale,
            sortOrder,
            limit,
            aggregateBy,
          },
          cancelToken,
        },
        axiosConfig,
      ),
    );
  },
  createReport({ dashboardId, data }) {
    return axios.post(`/dashboards/${dashboardId}/reports`, {
      ...data,
    });
  },
  updateReport({ dashboardId, reportId, data }) {
    return axios.patch(`/dashboards/${dashboardId}/reports/${reportId}`, {
      ...data,
    });
  },
  deleteReport({ dashboardId, reportId }) {
    return axios.delete(`/dashboards/${dashboardId}/reports/${reportId}`);
  },
  getTopLineStatsForChannel({
    brandIds,
    startDate,
    endDate,
    contextStartDate,
    contextEndDate,
    channels,
    metrics,
    requirePosts,
  } = {}) {
    const cancelToken = getAndSetCancelToken({
      brandIds,
      channels,
      metrics,
    });

    const params = {
      brandIds,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      requirePosts,
      metrics,
      channels,
    };
    return axios.get(`/reports/data`, { params, cancelToken });
  },
  getGraphStats({ brandIds, startDate, endDate, metrics, channels, timeScale } = {}) {
    const reportType = 'GRAPH';
    const cancelToken = getAndSetCancelToken({
      brandIds,
      channels,
      metrics,
      reportType,
    });

    const params = {
      brandIds,
      startDate,
      endDate,
      metrics,
      channels,
      timeScale,
      reportType,
    };
    return axios.get(`/reports/data`, { params, cancelToken });
  },
  // Exports
  getDashboardAutomatedExports({ dashboardId }) {
    return axios.get(`/dashboards/${dashboardId}/automated_exports`);
  },
  getAllDashboardAutomatedExports() {
    return axios.get(`/dashboards/automated_exports`);
  },
  createDashboardAutomatedExports({ dashboardId, autoExport }) {
    return axios.post(`/dashboards/${dashboardId}/automated_exports`, autoExport);
  },
  updateDashboardAutomatedExports({ dashboardId, autoExportId, autoExport }) {
    return axios.patch(`/dashboards/${dashboardId}/automated_exports/${autoExportId}`, autoExport);
  },
  deleteAutomatedExport({ dashboardId, autoExportId }) {
    return axios.delete(`/dashboards/${dashboardId}/automated_exports/${autoExportId}`);
  },
  deleteDashboardAutomatedExportUser({ dashboardId, autoExportId, userId }) {
    return axios.delete(
      `/dashboards/${dashboardId}/automated_exports/${autoExportId}/recipients/${userId}`,
    );
  },
  async getReportCsv({
    dashboardId,
    reportId,
    startDate,
    endDate,
    contextStartDate,
    contextEndDate,
    timeScale,
  }) {
    const params = {
      start_date: startDate,
      end_date: endDate,
      context_start_date: contextStartDate,
      context_end_date: contextEndDate,
      time_scale: timeScale,
      format: 'csv',
    };
    return axios.get(`/dashboards/${dashboardId}/reports/${reportId}`, {
      params,
      responseType: 'blob',
    });
  },
  getReportDataCsv({
    brandIds,
    startDate,
    endDate,
    contextStartDate,
    contextEndDate,
    channels,
    metrics,
    reportType,
    sortOrder,
    limit,
    timeScale,
    filenamePrefix,
    csvLayout,
  }) {
    return axios.get(
      `/reports/data`,
      merge(
        {
          params: {
            brandIds,
            startDate,
            endDate,
            contextStartDate,
            contextEndDate,
            channels,
            metrics,
            reportType,
            sortOrder,
            limit,
            timeScale,
            filenamePrefix,
            csvLayout,
          },
        },
        {
          params: { format: 'csv' },
          responseType: 'blob',
        },
      ),
    );
  },
  getDashboardSpreadsheet({
    dashboardId,
    startDate,
    endDate,
    contextStartDate,
    contextEndDate,
    format,
    socketId,
    timeScale,
  }) {
    const params = {
      start_date: startDate,
      end_date: endDate,
      context_start_date: contextStartDate,
      context_end_date: contextEndDate,
      time_scale: timeScale,
      socket_id: socketId,
      format,
    };
    return axios.get(`/dashboards/${dashboardId}`, { params });
  },
  // Sharing
  getDashboardUsers({ dashboardId }) {
    return axios.get(`/dashboards/${dashboardId}/users`);
  },
  shareDashboard({ dashboardId, userId, userRole }) {
    return axios.put(`/dashboards/${dashboardId}/users/${userId}`, {
      user_role: userRole,
    });
  },
  updateDashboardOwner({ dashboardId, userId }) {
    return axios.put(`/dashboards/${dashboardId}/owner`, {
      user_id: userId,
    });
  },
  deleteDashboardUser({ dashboardId, userId }) {
    return axios.delete(`/dashboards/${dashboardId}/users/${userId}`);
  },
  // Industries
  getIndustries(includeFollowerBuckets = true) {
    return axios.get(`/industries?include_follower_buckets=${includeFollowerBuckets}`);
  },
  async getIndustriesBySourceAccount(sourceAccountId) {
    return axios.get(`/industries/source_account?source_account_id=${sourceAccountId}`);
  },

  // Custom Metrics
  createCustomMetric({ organizationId, params }) {
    return axios.post(`organizations/${organizationId}/custom_metric`, params);
  },

  getCustomMetricsUsage({ brandIds }) {
    return axios.post(`/custom_metrics/usage`, { brandIds });
  },

  getCustomMetrics({ organizationIds, brandIds, params }) {
    let payload;
    if (organizationIds) payload = { organizationIds };
    else if (brandIds) payload = { brandIds };
    return axios.post('/custom_metrics', payload, {
      params,
    });
  },

  updateCustomMetric({ organizationId, metricId, params }) {
    return axios.patch(`organizations/${organizationId}/custom_metric/${metricId}`, params);
  },
  deleteCustomMetric({ organizationId, metricId }) {
    return axios.delete(`organizations/${organizationId}/custom_metric/${metricId}`);
  },
};

export default api;
