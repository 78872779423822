import dayjs from 'dayjs';
import {
  autoPublishErrors,
  mediaValidationMap,
  pinterestVideoProcessInfoMessage,
  platformLabels,
  PLATFORMS,
  postDescriptions,
  postStatus,
  postTypes,
  videoStatus,
  FACEBOOK_SUCCESSFUL_PUBLISH_WITH_TARGETING_MESSAGE,
  APPROVAL_POLICY,
  APPROVALS_BANNER,
  APPROVAL_STATUS,
  draftOverdueBannerText,
} from '@/app/scheduler/constants';
import { overdueDelay, overdueDraftDelay } from '@/config';
import { mediaTypes } from '@/app/library/constants';
import { findDateRangeOverlap, validatePublishDates } from '@/app/scheduler/utils/publish-dates';

export const STATUS_TYPE = {
  danger: 'danger',
  warning: 'warning',
  success: 'success',
};

export const multiChannelPostErrors = {
  POST_DID_NOT_PUBLISH: 'POST_DID_NOT_PUBLISH',
  POST_WILL_NOT_PUBLISH: 'POST_WILL_NOT_PUBLISH',
  BOTH_PAST_AND_FUTURE_ERROR: 'BOTH_PAST_AND_FUTURE_ERROR',
};

export const isPosted = (post, { asThread }) =>
  post.status === postStatus.IMPORTED ||
  (!asThread && post.status === postStatus.PARTIALLY_FAILED) ||
  (post.status === postStatus.POSTED && (post.firstComment || post.auto_publish));

export const isFailed = (post, { asThread }) =>
  !isPosted(post, { asThread }) &&
  (post.autoPublishError ||
    post.status === postStatus.FAILED ||
    (asThread && post.status === postStatus.PARTIALLY_FAILED));

export const isOverdue = (post, { asThread }) => {
  const delay = post.status === postStatus.DRAFT ? overdueDraftDelay : overdueDelay;
  return (
    !isPosted(post, { asThread }) &&
    post.timestamp &&
    dayjs().isAfter(dayjs(post.timestamp).add(delay, 'minutes'))
  );
};

export const isMissingApprovals = (post, { asThread }) =>
  !isPosted(post, { asThread }) && post.timestamp && post.status === postStatus.MISSING_APPROVALS;

export const getPostTarget = (post) => {
  switch (post.platform) {
    case PLATFORMS.TIKTOK:
      return 'TikTok';
    case PLATFORMS.INSTAGRAM:
      switch (post.postType) {
        case postTypes.STORY:
          return 'your Instagram story';
        default:
          return 'Instagram';
      }
    case PLATFORMS.FACEBOOK:
      return 'Facebook';
    case PLATFORMS.PINTEREST:
      return 'Pinterest';
    case PLATFORMS.TWITTER:
      return 'your X feed';
    case PLATFORMS.LINKEDIN:
      return 'LinkedIn';
    default:
      throw new Error(`Unknown post platform: ${post.platform}!`);
  }
};

export const hasPublishDateError = (post) => {
  const overlap = findDateRangeOverlap(post.media);
  return !!validatePublishDates(overlap, post.timestamp);
};

export const showApprovalsInfoBanner = (post) => {
  const hasApprovalRequests = post?.approvalRequests?.length > 0;
  const approvalRequired = post?.approvalPolicy === APPROVAL_POLICY.APPROVAL_REQUIRED;
  return hasApprovalRequests || approvalRequired;
};

export const getPostStatusMessage = (post, { asThread, inEditor }) => {
  if (!Object.values(PLATFORMS).includes(post.platform)) {
    throw new Error('Post does not have a valid platform!');
  }

  const contentPublishing =
    [postStatus.AUTOPUBLISHING, postStatus.POSTED].includes(post.status) && !post.auto_publish;
  const isVideoPin =
    post.platform === PLATFORMS.PINTEREST &&
    post.media?.length === 1 &&
    post.media[0].media_type === mediaTypes.VIDEO;
  const isUnderReview =
    isVideoPin && !isPosted(post, { asThread }) && !isFailed(post, { asThread });
  const isPinRejected =
    isUnderReview &&
    [videoStatus.PROCESSING_FAILED, videoStatus.REVIEWING_FAILED].includes(post.videoStatus);
  const target = getPostTarget(post);
  const formattedPlatformName = platformLabels[post.platform];
  const description = postDescriptions[post.platform];

  const isScheduled = post.status === postStatus.SCHEDULED;
  const postApprovalStatus = post.approvalStatus;
  const isPendingApproval = [APPROVAL_STATUS.PENDING, APPROVAL_STATUS.UNASSIGNED].includes(
    postApprovalStatus,
  );

  if (isScheduled && showApprovalsInfoBanner(post)) {
    const banner = {
      message: APPROVALS_BANNER[post.approvalPolicy],
      type: 'information',
    };

    if (isPendingApproval) return banner;
  }

  if (hasPublishDateError(post) && post.status === postStatus.SCHEDULED && !inEditor) {
    return {
      message: `This ${description} will not go live because the media's approved date range has been updated.`,
      type: STATUS_TYPE.danger,
    };
  }

  if (isFailed(post, { asThread })) {
    const connectionPlatform =
      post.platform === PLATFORMS.INSTAGRAM ? 'Facebook' : formattedPlatformName;
    const postType = post.platform === PLATFORMS.INSTAGRAM ? 'instagramFeedPost' : post.platform;
    const imageValidations = mediaValidationMap[postType]?.aspectRatio?.image;
    if (asThread && post.status === postStatus.PARTIALLY_FAILED) {
      return {
        message:
          'One or more of your Posts did not publish successfully. To continue this thread, please publish any remaining threaded Posts from the X app.',
        type: STATUS_TYPE.danger,
      };
    }
    switch (post.autoPublishError) {
      case autoPublishErrors.DUPLICATE_POST:
        return {
          message: `You have already sent a ${description} with this text. Make a change to the text and try again.`,
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.TEXT_LENGTH_EXCEEDED:
        return {
          message: `This Post did not go live at the scheduled posting time because it exceeds X’s character limit. Please remove some characters and try again.`,
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.INVALID_IMAGE:
        return {
          message: `This image did not meet ${formattedPlatformName}'s size requirements. Please crop your image to be within a ${imageValidations.min} to
            ${imageValidations.max} range and try again!`,
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.INVALID_PIN_BOARD:
        return {
          message:
            `This ${description} did not publish successfully. ` +
            'The target board could not be found and may have been deleted. ' +
            'Please update the board and try again!',
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.AUTO_PUBLISH_LIMIT_EXCEEDED:
        if (post.platform === 'instagram') {
          return {
            message:
              'You have reached the API limit of 25 auto published posts in 24hrs. ' +
              'Please publish this post via push notification in the Dash Social app.',
            type: STATUS_TYPE.danger,
          };
        }
        if (post.platform === 'pinterest') {
          return {
            message:
              "You've reached the API posting frequency limit on Pinterest. " +
              'Please reschedule your pins to a later time or manually post them via the Pinterest app.',
            type: STATUS_TYPE.danger,
          };
        }
        return {
          message:
            'You have reached the API limit of auto published posts. ' +
            'Please try again later or publish this via the Dash Social app.',
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.ACCOUNT_RESTRICTED:
        if (post.platform === 'instagram') {
          return {
            message:
              'This Instagram post did not publish successfully due to a restriction on your ' +
              'account. Please complete any in-app actions Instagram is requiring to reenable ' +
              'your account or contact Instagram to resolve this issue.',
            type: STATUS_TYPE.danger,
          };
        }
        return {
          message: 'This post did not publish successfully due to a restriction on your account.',
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.PAGE_PUBLISHING_AUTH_REQUIRED:
        return {
          message: `<span>
              This content did not publish.
              To post to your connected Instagram account, please complete <a class="below-message" href="https://www.facebook.com/business/m/one-sheeters/page-publishing-authorization" target="_blank" rel="noopener">
              Facebook's Page Publishing Authorization.
              </a>
              </span>`,
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.AGAINST_COMMUNITY_STANDARDS:
        return {
          message:
            'This post failed to publish. Facebook has determined that it goes against their Community Guidelines. Please edit this post and try again',
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.INVALID_ACCOUNT_TYPE:
        return {
          message:
            'This post failed to publish. You must have an Instagram Business account to use auto publishing. Please use the Send Notification option and try again.',
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.INVALID_AUTH:
      case autoPublishErrors.MISSING_AUTH:
        return {
          message: `We were unable to publish your ${description}. Please reconnect your ${connectionPlatform} account and try again.`,
          type: STATUS_TYPE.danger,
        };
      case autoPublishErrors.FLAGGED_AS_SPAM:
        return {
          message: 'This post failed because Instagram flagged it as restricted content',
          type: 'danger',
        };
      case autoPublishErrors.APPROVED_PUBLISHING_DATES_HAVE_NO_OVERLAP:
      case autoPublishErrors.OUTSIDE_APPROVED_PUBLISHING_DATES:
        return {
          message: `This ${description} did not go live at the scheduled time because the media’s approved date range has been updated.`,
          type: 'danger',
        };
      case autoPublishErrors.INVALID_TARGETING:
        return {
          message: `This post was not published due to an error with Audience targeting. Please remove and re-add the selected location(s), then proceed with scheduling. Contact us if the issue persists.`,
          type: 'danger',
        };
      default:
        return {
          message: `This ${description} did not publish successfully. Please try again!`,
          type: STATUS_TYPE.danger,
        };
    }
  }

  if (post.status === postStatus.SKIPPED) {
    return {
      message: `This ${description} has been skipped because a previous ${description} failed to publish.`,
      type: STATUS_TYPE.warning,
    };
  }

  if (isMissingApprovals(post, { asThread })) {
    return {
      message: 'This post did not go live because it needs approval. Please reschedule the post',
      type: STATUS_TYPE.danger,
    };
  }

  if (isOverdue(post, { asThread }) && post.status === postStatus.DRAFT) {
    return {
      message: draftOverdueBannerText,
      type: STATUS_TYPE.warning,
    };
  }

  if (isOverdue(post, { asThread })) {
    const location = post.status === postStatus.EXPIRED ? 'calendar' : 'timeline';
    return {
      message: `This ${description} did not go live at the scheduled posting time. You can remove it from your ${location} or reschedule.`,
      type: STATUS_TYPE.danger,
    };
  }

  if (contentPublishing) {
    if (
      [PLATFORMS.INSTAGRAM, PLATFORMS.TIKTOK, PLATFORMS.LINKEDIN].includes(post.platform) &&
      post.status === postStatus.POSTED &&
      !post.autoPublish
    ) {
      return {
        message: `This post hasn't been published yet. If you haven't manually published it, check your phone's camera roll and publish from the ${
          platformLabels[post.platform]
        } app.`,
        type: STATUS_TYPE.warning,
      };
    }
    return {
      message: 'This post is currently being published. This may take a few minutes.',
      type: STATUS_TYPE.warning,
    };
  }

  if (isPinRejected) {
    return {
      message: `
          <p>
            This video was rejected from publishing by Pinterest. Please make sure the video doesn’t violate
            <a target="_blank" href="https://policy.pinterest.com/en/community-guidelines"> Pinterest Community Guidelines </a>
            and try again!
          </p>
        `,
      type: STATUS_TYPE.danger,
    };
  }

  if (isPosted(post, { asThread })) {
    if (post.has_invalid_product_tags) {
      return {
        message: `<span>
          This content was successfully published to ${target} but product tags could not be added. This may be because one or more of your products
          are invalid. You can add product tags to the published post on the Instagram app.
          </span>`,
        type: STATUS_TYPE.warning,
        actionText: `See it on ${formattedPlatformName}`,
        actionTo: post.livePostUrl,
      };
    }
    if (post.livePostUrl) {
      let message = `This content was successfully published to ${target}.`;
      if (post.targeting) {
        message = FACEBOOK_SUCCESSFUL_PUBLISH_WITH_TARGETING_MESSAGE;
      }
      return {
        message,
        type: STATUS_TYPE.success,
        actionText: `See it on ${formattedPlatformName}`,
        actionTo: post.livePostUrl,
      };
    }
    return {
      message: `<span>This content was successfully published to ${target}.</span>`,
      type: STATUS_TYPE.success,
    };
  }

  if (isUnderReview) {
    return { message: pinterestVideoProcessInfoMessage, type: 'information' };
  }

  return null;
};

export const hasPastError = (post, { asThread }) =>
  getPostStatusMessage(post, { asThread })?.type === STATUS_TYPE.danger &&
  post.status !== postStatus.SCHEDULED;

export const hasFutureError = (post, { asThread }) =>
  getPostStatusMessage(post, { asThread })?.type === STATUS_TYPE.danger &&
  post.status === postStatus.SCHEDULED;
