import { useTrendingHashtagsSearchArgs } from '@/app/tiktok/composables/useTrendingHashtagsSearchArgs';
import { getCountryFlag } from '@/utils/countries';

export const tooltips = {
  engagements:
    'The total number of engagements on your videos during the selected date range. Total Engagements = Likes + Comments + Shares',
  followersGrowthRate:
    'The percentage your follower count increased by during the selected date range. Follower Growth Rate = (New Followers - Existing Followers) / Existing Followers x 100%',
  trendingVideos:
    'Your videos that received the highest number of views during the selected date range.',
  trendingVideosWithEntertainmentScore:
    'Your videos that received the highest number of views during the selected date range. These posts are compared to the average Entertainment Score from posts published in the last 12 weeks.',
  totalVideoViews: 'Total Video Views',
  avgCompletionRate:
    'The average percentage of this video that people watched. Retention Rate = Average Time Watched / Video Duration',
  fypViews: 'The total percentage of video views that came from the For You page.',
  effectivenessRate:
    'The percentage of people that saw and engaged with this video. Effectiveness Rate = (Likes + Comments + Shares + Video Views) / Reach',
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noUserPermission: "Looks like you don't have permission to use this feature!",
  contactCallToAction:
    'Dash Social brings you all of your essential social media solutions, including LikeShop! Ask your Dash Social administrator to provide you access.',
  connectCallToAction:
    "Dash Social brings you all of the insights you love, including TikTok! Ask your Dash Social administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
  engagementRate:
    'The percentage of unique viewers that engaged with this video. Engagement Rate = Total Engagements / Reach',
  totalFollowers:
    'The total number of people who have followed your account by the last day of the selected date range.',
  netNewFollowers:
    'The net new number of people who have followed your account during the selected date range.',
  impressionsPersonalProfileRate:
    'The total percentage of video views that came from viewers on your personal profile.',
  fullVideoWatchedRate: 'The percentage of viewers that watched your entire video.',
  impressionsSearchRate:
    'The total percentage of video views that came from users performing a search.',
  impressionsSoundRate: "The total percentage of video views that came from the sound's page.",
  impressionsFollowRate:
    'The total percentage of video views that came from viewers on their Following page.',
  impressionsHashtagRate: 'The total percentage of video views that came from hashtags.',
  noConnection:
    'Before clicking Connect, log in to the correct TikTok Business account in a separate browser window. <br><br>' +
    "If this is a new connection, you'll need to provide your TikTok handle to your customer success rep first.",
  followerBreakdown: 'The top locations and gender breakdown of your account’s followers.',
  entertainmentScore: 'Entertainment Score',
  likeShopLinkCopyTooltip:
    'Click to copy. Paste this link into the website field of your TikTok profile on mobile.',
  ugcWordcloudTooltip:
    'The top caption keywords used in the 100 most recent videos that mention you in the past 90 days',
  ugcHashtagsTooltip:
    'Top videos from the last 90 days featuring your branded hashtags in the caption. Only hashtags submitted and approved by TikTok will be included.',
};

export const timeScaleTypes = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
};

export const tiktokLikeShopEmptyStatusMessage =
  'No active LikeShop posts found. Adjust your filters or add links to your TikTok posts.';

export const tiktokUGCMetricErrors = {
  TOO_OLD:
    "Metrics on this video are no longer up-to-date. TikTok's API does not support updated metrics on posts published more than 90 days ago.",
  NO_LONGER_RELEVANT:
    "Due to a limitation with TikTok's API, metrics on this video are no longer up-to-date.",
};

export const tiktokConnectionMessages = {
  title: 'Connect Your TikTok Account',
  message: 'You must have a Business TikTok account to access your insights in Dash Social.',
};

export const tiktokNoBrandUGCAccessMessage = {
  title: `Find Out Who’s Talking About Your Brand`,
  message: `Never miss an influential TikTok post that features your brand. Get insight into how your audience talks about you and engage with that brand love.`,
};

export const tiktokNoUserUGCAccessMessage = {
  title: `Looks Like You Don't Have Permission To Use This Feature!`,
  message: `Dash Social brings you all the insights you love, including User Generated Content. Ask your Dash Social administrator to provide you access.`,
};

export const tiktokTopPerformingVideosSectionInfo = {
  title: 'Top Performing Videos',
  tooltip: 'Your highest performing posts published within the selected date range.',
  emptyMessage: 'No TikTok posts found during this reporting period',
};

export const tiktokTrendingVideosSectionInfo = {
  title: 'Trending Videos',
  tooltip: 'Your videos with the highest video views during the selected reporting period.',
  emptyMessage: 'No TikTok posts found during this reporting period',
};

export const tiktokPostMetricKeys = {
  VIDEO_VIEWS: 'TIKTOK_VIEWS',
  TOTAL_ENGAGEMENTS: 'TIKTOK_TOTAL_ENGAGEMENTS',
  EFFECTIVENESS: 'TIKTOK_EFFECTIVENESS',
  ENGAGEMENT_RATE: 'TIKTOK_ENGAGEMENT_RATE',
  RETENTION_RATE: 'TIKTOK_AVERAGE_COMPLETION_RATE',
  FYP_VIEWS: 'TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
  DATE: 'DATE',
  IMPRESSIONS_PERSONAL_PROFILE_RATE: 'TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
  AVERAGE_TIME_WATCHED: 'TIKTOK_AVERAGE_TIME_WATCHED',
  REACH: 'TIKTOK_REACH',
  COMMENTS: 'TIKTOK_COMMENTS',
  FULL_VIDEO_WATCHED_RATE: 'TIKTOK_FULL_VIDEO_WATCHED_RATE',
  IMPRESSIONS_SEARCH_RATE: 'TIKTOK_IMPRESSIONS_SEARCH_RATE',
  SHARES: 'TIKTOK_SHARES',
  IMPRESSIONS_SOUND_RATE: 'TIKTOK_IMPRESSIONS_SOUND_RATE',
  IMPRESSIONS_FOLLOW_RATE: 'TIKTOK_IMPRESSIONS_FOLLOW_RATE',
  TOTAL_TIME_WATCHED: 'TIKTOK_TOTAL_TIME_WATCHED',
  IMPRESSIONS_HASHTAG_RATE: 'TIKTOK_IMPRESSIONS_HASHTAG_RATE',
  DURATION: 'TIKTOK_DURATION',
  LIKES: 'TIKTOK_LIKES',
  ENTERTAINMENT_SCORE: 'TIKTOK_ENTERTAINMENT_SCORE',
};

const tiktokPostDefaultMetrics = [
  tiktokPostMetricKeys.ENTERTAINMENT_SCORE,
  tiktokPostMetricKeys.VIDEO_VIEWS,
  tiktokPostMetricKeys.TOTAL_ENGAGEMENTS,
  tiktokPostMetricKeys.AVERAGE_TIME_WATCHED,
];

const tiktokPostMetricsV2 = [tiktokPostMetricKeys.ENTERTAINMENT_SCORE];

export const tiktokMediaCardDefaults = {
  metrics: ['entertainmentScore', 'views', 'totalEngagements', 'averageTimeWatched'],
  labels: {
    entertainmentScore: 'Entertainment Score',
    views: 'Video Views',
    totalEngagements: 'Total Engagements',
    averageTimeWatched: 'Avg. Time Watched',
  },
};

export const tiktokGalleryCardDefaults = {
  metrics: [
    'tiktokTotalVideoViews',
    'tiktokAvgVideoViews',
    'tiktokAvgRetentionRate',
    'tiktokAvgEngagementRate',
  ],
  labels: {
    tiktokTotalVideoViews: 'Total Video Views',
    tiktokAvgVideoViews: 'Avg. Video Views',
    tiktokAvgRetentionRate: 'Avg. Retention Rate',
    tiktokAvgEngagementRate: 'Avg. Engagement Rate',
  },
};

export const tiktokMetricFormats = {
  tiktokAvgRetentionRate: 'percent',
  tiktokAvgEngagementRate: 'percent',
};

const tiktokPostMetrics = {};
tiktokPostMetrics[tiktokPostMetricKeys.VIDEO_VIEWS] = {
  field: 'views',
  label: 'Video Views',
};
tiktokPostMetrics[tiktokPostMetricKeys.TOTAL_ENGAGEMENTS] = {
  field: 'totalEngagements',
  label: 'Total Engagements',
};
tiktokPostMetrics[tiktokPostMetricKeys.EFFECTIVENESS] = {
  field: 'effectiveness',
  label: 'Effectiveness',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.ENGAGEMENT_RATE] = {
  field: 'engagementRate',
  label: 'Engagement Rate',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.ENTERTAINMENT_SCORE] = {
  field: 'entertainmentScore',
  label: 'Entertainment Score',
  format: 'two_decimal_float',
};
tiktokPostMetrics[tiktokPostMetricKeys.RETENTION_RATE] = {
  field: 'averageCompletionRate',
  label: 'Retention Rate',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.FYP_VIEWS] = {
  field: 'impressionsForYouRate',
  label: 'FYP Views %',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.DATE] = {
  label: 'Recently Published',
};
tiktokPostMetrics[tiktokPostMetricKeys.IMPRESSIONS_PERSONAL_PROFILE_RATE] = {
  field: 'impressionsPersonalProfileRate',
  label: 'Profile Views %',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.AVERAGE_TIME_WATCHED] = {
  field: 'averageTimeWatched',
  label: 'Avg. Time Watched',
  format: 'duration',
  num_decimals: 1,
};
tiktokPostMetrics[tiktokPostMetricKeys.REACH] = {
  field: 'reach',
  label: 'Reach',
};
tiktokPostMetrics[tiktokPostMetricKeys.COMMENTS] = {
  field: 'comments',
  label: 'Comments',
};
tiktokPostMetrics[tiktokPostMetricKeys.FULL_VIDEO_WATCHED_RATE] = {
  field: 'fullVideoWatchedRate',
  label: 'Completion Rate',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.IMPRESSIONS_SEARCH_RATE] = {
  field: 'impressionsSearchRate',
  label: 'Search Views %',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.SHARES] = {
  field: 'shares',
  label: 'Shares',
};
tiktokPostMetrics[tiktokPostMetricKeys.IMPRESSIONS_SOUND_RATE] = {
  field: 'impressionsSoundRate',
  label: 'Sound Views %',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.IMPRESSIONS_FOLLOW_RATE] = {
  field: 'impressionsFollowRate',
  label: 'Following Page Views %',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.TOTAL_TIME_WATCHED] = {
  field: 'totalTimeWatched',
  label: 'Total Time Watched',
  format: 'duration',
};
tiktokPostMetrics[tiktokPostMetricKeys.IMPRESSIONS_HASHTAG_RATE] = {
  field: 'impressionsHashtagRate',
  label: 'Hashtag Views %',
  format: 'percent',
};
tiktokPostMetrics[tiktokPostMetricKeys.DURATION] = {
  field: 'duration',
  label: 'Video Duration',
  format: 'duration',
};
tiktokPostMetrics[tiktokPostMetricKeys.LIKES] = {
  field: 'likes',
  label: 'Likes',
};

const yourPostsSortOptions = [
  {
    value: 'DATE',
    label: 'Recently Published',
  },
  {
    value: 'TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
    label: 'Profile Views %',
    field: 'impressionsPersonalProfileRate',
  },
  {
    value: 'TIKTOK_AVERAGE_TIME_WATCHED',
    label: 'Avg. Time Watched',
    field: 'averageTimeWatched',
  },
  {
    value: 'TIKTOK_REACH',
    label: 'Reach',
    field: 'reach',
  },
  {
    value: 'TIKTOK_COMMENTS',
    label: 'Comments',
    field: 'comments',
  },
  {
    value: 'TIKTOK_AVERAGE_COMPLETION_RATE',
    label: 'Retention Rate',
    field: 'averageCompletionRate',
  },
  {
    value: 'TIKTOK_FULL_VIDEO_WATCHED_RATE',
    label: 'Completion Rate',
    field: 'fullVideoWatchedRate',
  },
  {
    value: 'TIKTOK_IMPRESSIONS_SEARCH_RATE',
    label: 'Search Views %',
    field: 'impressionsSearchRate',
  },
  {
    value: 'TIKTOK_ENGAGEMENT_RATE',
    label: 'Engagement Rate',
    field: 'engagementRate',
  },
  {
    value: 'TIKTOK_SHARES',
    label: 'Shares',
    field: 'shares',
  },
  {
    value: 'TIKTOK_ENTERTAINMENT_SCORE',
    label: 'Entertainment Score',
    field: 'entertainmentScore',
  },
  {
    value: 'TIKTOK_IMPRESSIONS_SOUND_RATE',
    label: 'Sound Views %',
    field: 'impressionsSoundRate',
  },
  {
    value: 'TIKTOK_IMPRESSIONS_FOLLOW_RATE',
    label: 'Following Page Views %',
    field: 'impressionsFollowRate',
  },
  {
    value: 'TIKTOK_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
    field: 'totalEngagements',
  },
  {
    value: 'TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
    label: 'For You Page Views %',
    field: 'impressionsForYouRate',
  },
  {
    value: 'TIKTOK_TOTAL_TIME_WATCHED',
    label: 'Total Time Watched',
    field: 'totalTimeWatched',
  },
  {
    value: 'TIKTOK_IMPRESSIONS_HASHTAG_RATE',
    label: 'Hashtag Views %',
    field: 'impressionsHashtagRate',
  },
  {
    value: 'TIKTOK_DURATION',
    label: 'Video Duration',
    field: 'duration',
  },
  {
    value: 'TIKTOK_LIKES',
    label: 'Likes',
    field: 'likes',
  },
  {
    value: 'TIKTOK_VIEWS',
    label: 'Video Views',
    field: 'views',
  },
];

const galleriesSortOptions = [
  {
    value: 'CREATED',
    label: 'Recently Added',
  },
  {
    value: 'TIKTOK_AVG_ENGAGEMENT_RATE',
    label: 'Avg. Engagement Rate',
  },
  {
    value: 'TIKTOK_AVG_RETENTION_RATE',
    label: 'Avg. Retention Rate',
  },
  {
    value: 'TIKTOK_AVG_VIDEO_VIEWS',
    label: 'Avg. Video Views',
  },
  {
    value: 'TIKTOK_TOTAL_VIDEO_VIEWS',
    label: 'Total Video Views',
  },
];

const ugcDefaultSort = 'DATE';
const UGC_SORT_OPTIONS = Object.freeze([
  {
    value: 'DATE',
    label: 'Recently Published',
  },
  {
    value: 'TIKTOK_VIEWS',
    label: 'Video Views',
    field: 'views',
  },
  {
    value: 'TIKTOK_REACH',
    label: 'Reach',
    field: 'reach',
  },
  {
    value: 'TIKTOK_TOTAL_ENGAGEMENTS',
    label: 'Engagements',
    field: 'totalEngagements',
  },
  {
    value: 'TIKTOK_COMMENTS',
    label: 'Comments',
    field: 'comments',
  },
  {
    value: 'TIKTOK_LIKES',
    label: 'Likes',
    field: 'likes',
  },
  {
    value: 'TIKTOK_SHARES',
    label: 'Shares',
    field: 'shares',
  },
]);

const UGC_HASHTAG_SORT_OPTIONS = Object.freeze([
  {
    value: 'DATE',
    label: 'Recently Published',
  },
  {
    value: 'TIKTOK_TOTAL_ENGAGEMENTS',
    label: 'Engagements',
    field: 'totalEngagements',
  },
  {
    value: 'TIKTOK_LIKES',
    label: 'Likes',
    field: 'likes',
  },
  {
    value: 'TIKTOK_COMMENTS',
    label: 'Comments',
    field: 'comments',
  },
  {
    value: 'TIKTOK_SHARES',
    label: 'Shares',
    field: 'shares',
  },
]);
export const tiktokBoardsDetailSortOptions = [
  {
    value: '-DATE',
    label: 'Recently Published',
  },
  {
    value: '-TIKTOK_IMPRESSIONS_PERSONAL_PROFILE_RATE',
    label: 'Profile Views %',
  },
  {
    value: '-TIKTOK_AVERAGE_TIME_WATCHED',
    label: 'Avg. Time Watched',
  },
  {
    value: '-TIKTOK_REACH',
    label: 'Reach',
  },
  {
    value: '-TIKTOK_COMMENTS',
    label: 'Comments',
  },
  {
    value: '-TIKTOK_AVERAGE_COMPLETION_RATE',
    label: 'Retention Rate',
  },
  {
    value: '-TIKTOK_FULL_VIDEO_WATCHED_RATE',
    label: 'Completion Rate',
  },
  {
    value: '-TIKTOK_IMPRESSIONS_SEARCH_RATE',
    label: 'Search Views %',
  },
  {
    value: '-TIKTOK_ENGAGEMENT_RATE',
    label: 'Engagement Rate',
  },
  {
    value: '-TIKTOK_SHARES',
    label: 'Shares',
  },
  {
    value: '-TIKTOK_ENTERTAINMENT_SCORE',
    label: 'Entertainment Score',
  },
  {
    value: '-TIKTOK_IMPRESSIONS_SOUND_RATE',
    label: 'Sound Views %',
  },
  {
    value: '-TIKTOK_IMPRESSIONS_FOLLOW_RATE',
    label: 'Following Page Views %',
  },
  {
    value: '-TIKTOK_TOTAL_ENGAGEMENTS',
    label: 'Total Engagements',
  },
  {
    value: '-TIKTOK_IMPRESSIONS_FOR_YOU_RATE',
    label: 'For You Page Views %',
  },
  {
    value: '-TIKTOK_TOTAL_TIME_WATCHED',
    label: 'Total Time Watched',
  },
  {
    value: '-TIKTOK_IMPRESSIONS_HASHTAG_RATE',
    label: 'Hashtag Views %',
  },
  {
    value: '-TIKTOK_DURATION',
    label: 'Video Duration',
  },
  {
    value: '-TIKTOK_LIKES',
    label: 'Likes',
  },
  {
    value: '-TIKTOK_VIEWS',
    label: 'Video Views',
  },
];

export const topPerformingVideosSortOptions = [
  {
    value: '-TIKTOK_ENTERTAINMENT_SCORE',
    label: 'Entertainment Score',
  },
  {
    value: '-TIKTOK_VIEWS',
    label: 'Video Views',
  },
];

const galleriesFilterWidgetsConfig = {
  tags: {
    name: 'MediaFilterTagsWidget',
    label: 'Tags',
    noDuplicate: true,
    options: {
      tagsList: [],
    },
  },
};

const galleriesFilterOptions = [
  {
    label: 'Tags',
    value: 'tags',
  },
];

export const genderDistributionChartLabels = {
  MALE: 'male',
  FEMALE: 'female',
  UNKNOWN: 'unknown',
};

export const tiktokCommentRequiredScopes = ['comment.list', 'comment.list.manage'];

export const tiktokLikeshopTabNames = {
  feed: 'All Feed Posts',
  customContent: 'Custom Content',
};

export const tiktokLikeshopTabs = [
  {
    name: tiktokLikeshopTabNames.feed,
    tooltip: 'Add links to content published on TikTok to add it to your LikeShop page',
  },
  {
    name: tiktokLikeshopTabNames.customContent,
    tooltip: 'Customize and measure the performance of your LikeShop banner and pinned content',
  },
];

export const tiktokLikeshopAllFeedPostsSortOptions = [
  {
    value: '-POSTED',
    label: 'Recently Published',
  },
  {
    value: '-CLICKS',
    label: 'Clicks',
  },
];

export const tikTokLikeShopCustomContentMessage =
  'Pin top performing content and brand your <br />LikeShop by adding a banner.';

export const tikTokLikeShopCustomContentSettings = {
  pinnedItemLimit: 3,
  bannerLinksLimit: 1,
  bannerTitle: 'Banner Image',
  bannerMessage: '1080px width or greater required. 2:1 aspect ratio recommended.',
  pinnedContentTitle: 'Pin Content',
  pinnedContentMessage:
    'Pin up to three pieces. At least one link is required on each piece of content.',
  existingMediaErrorMessage:
    'Existing LikeShop media cannot be used as a banner image or pinned content.',
  bannerWidthErrorMessage:
    'Your banner has a low resolution which will compromise its quality when published. Please add an image with a width of 1080px or greater.',
  pinnedContentWidthWarningMessage:
    'One or more of your images are low resolution, which may compromise its quality when published. We recommend using an image with a width of 1080px or greater.',
  pinnedImageLinksLimit: 25,
  recommendedCustomContentWidth: 1080,
  pinnedContentMissingLinksMessage: 'Pinned content must contain links.',
  hasUploadingMediaMessage: 'Please wait for media to finish uploading.',
};

export const mostEntertainingPostsSortOptions = [
  {
    value: '-TIKTOK_ENTERTAINMENT_SCORE',
    label: 'Entertainment Score',
  },
  {
    value: '-TIKTOK_VIEWS',
    label: 'Video Views',
  },
];

export const tiktokUgcMediaCardDefaults = {
  labels: {
    views: 'Video Views',
    reach: 'Reach',
    totalEngagements: 'Engagements',
    likes: 'Likes',
    comments: 'Comments',
    shares: 'Shares',
  },
  metrics: ['views', 'reach', 'totalEngagements', 'likes', 'comments'],
};

export const tiktokUgcHashtagMediaCardMetrics = {
  labels: {
    totalEngagements: 'Engagements',
    likes: 'Likes',
    comments: 'Comments',
    shares: 'Shares',
  },
  metrics: ['totalEngagements', 'likes', 'comments', 'shares'],
};

export const tiktokUgcDateOptions = [
  { label: 'Last 24 Hours', value: 1 },
  { label: 'Last 3 Days', value: 3 },
  { label: 'Last 7 Days', value: 7 },
  { label: 'Last 4 Weeks', value: 28 },
];

export const tiktokUgcNoKeywordsFound =
  'Keywords will be displayed here when your brand is mentioned in video captions within the last 90 days.';
export const tiktokUgcKeywordsError =
  'We are currently unable to retrieve Top Keywords from TikTok. Please try again later.';

export const tiktokUgcKeywordWeights = {
  SINGLE_KEYWORD: '400',
  MULTI_KEYWORD: '500',
  HOVER_ANY_KEYWORD: '700',
};

export const tiktokUgcTruncatedKeywordLength = {
  KEYWORDS: 30,
};

export const tiktokTopLineStatsMetrics = [
  { value: 'VIDEO_VIEWS', label: 'Video Views' },
  { value: 'TOTAL_FOLLOWERS', label: 'Total Followers', tooltip: tooltips.totalFollowers },
  { value: 'NET_NEW_FOLLOWERS', label: 'Net New Followers', tooltip: tooltips.netNewFollowers },
  { value: 'LIKES', label: 'Likes', overrideContextMetricName: 'ORGANIC_LIKES' },
  { value: 'COMMENTS', label: 'Comments', overrideContextMetricName: 'ORGANIC_COMMENTS' },
  { value: 'SHARES', label: 'Shares' },
  { value: 'PROFILE_VIEWS', label: 'Profile Views' },
];

export const analyticsDisabledForPersonalAccountLinkToReadMore =
  'https://help.dashsocial.com/hc/en-us/articles/6045974975757-Connecting-Your-TikTok-Account-to-Dash-Hudson';
export const analyticsDisabledForPersonalAccountStatus = 'ANALYTICS_DISABLED_FOR_PERSONAL_ACCOUNT';

export const trendingHashtagsTooltips = {
  title: 'Stay up to date on the most popular hashtags currently used in TikTok captions',
  ageChartTooltip: 'The approximate percentage of viewers associated with different age groups',
  interestGraphTooltip:
    'Each data point compares the relative volume against the peak volume of the trend. A value of 100 is the peak popularity for the trend.',
};

export const trendingRankChangeNew = 'NEW';

export const SORT_DETAILS = {
  TIKTOK_BOARDS: {
    options: [
      { label: 'Recently Added', value: 'CREATED' },
      { label: 'Avg. Engagement Rate', value: 'TIKTOK_AVG_ENGAGEMENT_RATE' },
      { label: 'Avg. Retention Rate', value: 'TIKTOK_AVG_RETENTION_RATE' },
      { label: 'Avg. Video Views', value: 'TIKTOK_AVG_VIDEO_VIEWS' },
      { label: 'Total Video Views', value: 'TIKTOK_TOTAL_VIDEO_VIEWS' },
    ],
    default: {
      sortOrder: 'DESC',
      selected: { label: 'Recently Added', value: 'CREATED' },
    },
  },
};

const COUNTRY_CODE_OPTIONS = useTrendingHashtagsSearchArgs().countryCodes.map((country) => {
  return { label: `${getCountryFlag(country.value)} ${country.label}`, value: country.value };
});

export const TIKTOK_FILTER_DETAILS = Object.freeze({
  TRENDING_HASHTAGS: {
    scope: 'tiktokTrendingHashtags',
    options: {
      reportingPeriod: useTrendingHashtagsSearchArgs().dateRanges,
      location: COUNTRY_CODE_OPTIONS,
      industry: useTrendingHashtagsSearchArgs().categoryNames.filter(
        (industry) => industry.value !== 'ALL',
      ),
    },
    default: {
      reportingPeriod: { preset: '7DAY' },
      location: 'US',
      industry: undefined,
    },
  },
  UGC: {
    scope: 'tiktokUGC',
    options: {
      isHashtag: [
        { value: false, label: 'Posts Featuring You' },
        { value: true, label: 'Posts Using Your Hashtags', tooltip: tooltips.ugcHashtagsTooltip },
      ],
    },
    default: {
      reportingPeriod: { preset: 'LAST_7_DAYS' },
      isHashtag: false,
    },
  },
});

export const UGC_LIMIT_EMPTY_STATE = {
  title: 'Experience Unlimited UGC',
  message: (limit) =>
    `Your current subscription is limited to displaying ${limit || '50'} post${limit === 1 ? '' : 's'}. Trial the Advance Social Plan to unlock more UGC.`,
};
export default {
  galleriesFilterOptions,
  galleriesFilterWidgetsConfig,
  galleriesSortOptions,
  tiktokPostDefaultMetrics,
  tiktokPostMetrics,
  tiktokPostMetricsV2,
  topPerformingVideosSortOptions,
  yourPostsDefaultSort: 'DATE',
  yourPostsSortOptions,
  ugcDefaultSort,
  UGC_SORT_OPTIONS,
  UGC_HASHTAG_SORT_OPTIONS,
};
