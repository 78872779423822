import isAfter from 'date-fns/isAfter';
import isDate from 'date-fns/isDate';
import parseISO from 'date-fns/parseISO';
import { camelizeKeys } from 'humps';
import { camelCase } from 'lodash/string';
import {
  decodeLinkedinNonReservedCharsForTiptap,
  encodedMentionRegex,
  encodeLinkedinNonReservedCharsForTiptap,
} from '@/app/scheduler/utils/tiptap';
import {
  CROSS_DUPLICATE_POST_TO_BRAND_KEYS,
  DIALOG_TYPES,
  PLATFORMS,
  postStatus,
} from '@/app/scheduler/constants';
import { mergeCase } from '@/app/scheduler/utils';
import { exhaustiveMap } from '@/utils/index';
import { useFlagStore } from '@/stores/flag';

export const CROSS_CHANNEL_DUPLICATE_PLATFORMS = [
  'twitter',
  'instagram',
  'pinterest',
  'facebook',
  'tiktok',
  'linkedin',
];

export const CROSS_BRAND_DUPLICATE_PLATFORMS = [
  PLATFORMS.TWITTER,
  PLATFORMS.FACEBOOK,
  PLATFORMS.LINKEDIN,
];

export const DUPLICATION_POST_TYPES = {
  FACEBOOK_POST: 'facebook_post',
  INSTAGRAM_REEL: 'instagram_reel',
  INSTAGRAM_STORY: 'instagram_story',
  INSTAGRAM_POST: 'instagram_post',
  PINTEREST_PIN: 'pinterest_pin',
  TIKTOK_POST: 'tiktok_post',
  TWITTER_TWEET: 'twitter_tweet',
  LINKEDIN_POST: 'linkedin_post',
};

export const PLATFORM_POST_TYPE_MAP = {
  [PLATFORMS.INSTAGRAM]: {
    FEED: DUPLICATION_POST_TYPES.INSTAGRAM_POST,
    STORY: DUPLICATION_POST_TYPES.INSTAGRAM_STORY,
    REEL: DUPLICATION_POST_TYPES.INSTAGRAM_REEL,
  },
  [PLATFORMS.FACEBOOK]: DUPLICATION_POST_TYPES.FACEBOOK_POST,
  [PLATFORMS.TWITTER]: DUPLICATION_POST_TYPES.TWITTER_TWEET,
  [PLATFORMS.PINTEREST]: DUPLICATION_POST_TYPES.PINTEREST_PIN,
  [PLATFORMS.TIKTOK]: DUPLICATION_POST_TYPES.TIKTOK_POST,
  [PLATFORMS.LINKEDIN]: DUPLICATION_POST_TYPES.LINKEDIN_POST,
};

export const DUPLICATION_TYPES = {
  SAVE_AND_DUPLICATE: 'SAVE_AND_DUPLICATE',
  PUBLISH_NOW_AND_DUPLICATE: 'PUBLISH_NOW_AND_DUPLICATE',
  DUPLICATE_FROM_SCHEDULED: 'DUPLICATE_FROM_SCHEDULED',
  DUPLICATE_FROM_PUBLISHED_CHANNEL: 'DUPLICATE_FROM_PUBLISHED_CHANNEL',
  DUPLICATE_FROM_PUBLISHED_BRAND: 'DUPLICATE_FROM_PUBLISHED_BRAND',
  DUPLICATE_BRAND_ID: 'DUPLICATE_BRAND_ID',
  DUPLICATE_BRAND_NAME: 'DUPLICATE_BRAND_NAME',
};

export const duplicationOptionsGDS = (action, canDelete = false) => {
  return [
    {
      label: 'TikTok',
      icon: 'tiktok-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.TIKTOK_POST);
      },
    },
    {
      label: 'Instagram Post',
      icon: 'instagram-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.INSTAGRAM_POST);
      },
    },
    {
      label: 'Instagram Story',
      icon: 'instagram-story-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.INSTAGRAM_STORY);
      },
    },
    {
      label: 'Instagram Reel',
      icon: 'instagram-reel-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.INSTAGRAM_REEL);
      },
    },
    {
      label: 'Facebook',
      icon: 'facebook-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.FACEBOOK_POST);
      },
    },
    {
      label: 'Pinterest Pin',
      icon: 'pinterest-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.PINTEREST_PIN);
      },
    },
    {
      label: 'X Post',
      icon: 'x-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.TWITTER_TWEET);
      },
    },
    {
      label: 'LinkedIn Post',
      icon: 'linkedin-color',
      command: () => {
        action(DUPLICATION_POST_TYPES.LINKEDIN_POST);
      },
    },
    ...(canDelete
      ? [
          { divider: true },
          {
            label: 'Delete Post',
            icon: 'delete',
            command: () => {
              action();
            },
          },
        ]
      : []),
  ];
};

export const duplicationOptions = (flagStore, action = {}) => {
  const duplicateChannelOptions = [
    {
      text: 'TikTok',
      iconName: 'tiktok',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.TIKTOK_POST },
    },
    {
      text: 'Instagram Post',
      iconName: 'instagram',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.INSTAGRAM_POST },
    },
    {
      text: 'Instagram Story',
      iconName: 'stories',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.INSTAGRAM_STORY },
    },
    {
      text: 'Instagram Reel',
      iconName: 'reels',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.INSTAGRAM_REEL },
    },
    {
      text: 'Facebook',
      iconName: 'facebook',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.FACEBOOK_POST },
    },
    {
      text: 'Pinterest Pin',
      iconName: 'pinterest',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.PINTEREST_PIN },
    },
    {
      text: 'X Post',
      iconName: 'twitter',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.TWITTER_TWEET },
    },
    {
      text: 'LinkedIn Post',
      iconName: 'linkedin',
      action,
      actionParams: { toType: DUPLICATION_POST_TYPES.LINKEDIN_POST },
    },
  ];

  return duplicateChannelOptions.filter((channelOption) =>
    flagStore.ready && flagStore.flags.linkedinAutoPublishingWeb
      ? channelOption
      : channelOption.iconName !== 'linkedin',
  );
};

export function allowDuplication(platform) {
  return CROSS_CHANNEL_DUPLICATE_PLATFORMS.includes(platform);
}

/*
 * Select the fields shared between platforms
 */
const getSharedFields = (post) => {
  const flagStore = useFlagStore();
  const {
    media,
    mediaIds,
    thumbnailUrl,
    thumbOffset,
    timestamp,
    autoPublish,
    campaigns,
    campaignIds,
    altTextMediaMap,
    status,
  } = post;
  const convertedTimestamp = isDate(timestamp) ? timestamp : parseISO(timestamp);
  return {
    media,
    mediaIds,
    thumbnailUrl,
    thumbOffset,
    autoPublish,
    campaigns,
    campaignIds,
    altTextMediaMap,
    timestamp:
      convertedTimestamp && isAfter(convertedTimestamp, new Date()) ? convertedTimestamp : null,
    ...(status === postStatus.DRAFT && flagStore.ready && flagStore.flags.schedulerDrafts
      ? { status: postStatus.DRAFT }
      : {}),
  };
};

export function postTypeByPlatform(platform, post) {
  switch (platform) {
    case 'instagram':
      return (
        DUPLICATION_POST_TYPES[`INSTAGRAM_${post.post_type}`] ??
        DUPLICATION_POST_TYPES.INSTAGRAM_POST
      );
    case 'pinterest':
      return DUPLICATION_POST_TYPES.PINTEREST_PIN;
    case 'facebook':
      return DUPLICATION_POST_TYPES.FACEBOOK_POST;
    case 'twitter':
      return DUPLICATION_POST_TYPES.TWITTER_TWEET;
    case 'tiktok':
      return DUPLICATION_POST_TYPES.TIKTOK_POST;
    case 'linkedin':
      return DUPLICATION_POST_TYPES.LINKEDIN_POST;
    default:
      return null;
  }
}

const CONVERT_FROM_BY_TYPE = exhaustiveMap(DUPLICATION_POST_TYPES, {
  instagram_post: (post) => ({
    message: post.caption,
    facebookLocation: post.location,
    facebookLocationId: post.locationId,
    instagramBoardIds: post.boardIds,
    instagramBoards: post.boards,
  }),
  instagram_story: (post) => ({
    message: post.caption,
    instagramStoryLink: post.storyLink,
    instagramBoardIds: post.boardIds,
    instagramBoards: post.boards,
  }),
  instagram_reel: (post) => ({
    message: post.caption,
    facebookLocation: post.location,
    facebookLocationId: post.locationId,
    instagramBoardIds: post.boardIds,
    instagramBoards: post.boards,
    customCoverImage: post.coverImageMediaId,
    coverImageMedia: post.coverImageMedia,
  }),
  facebook_post: (post) => ({
    message: post.message,
    facebookLocation: post.location,
    facebookLocationId: post.locationId,
    facebookBoardIds: post.boardIds,
    facebookBoards: post.boards,
    targeting: post.targeting,
  }),
  twitter_tweet: (post) => ({
    message: post.tweetStatus,
    twitterLocation: post.location,
    twitterLocationId: post.locationId,
    twitterBoardIds: post.boardIds,
    twitterBoards: post.boards,
  }),
  pinterest_pin: (post) => ({
    message: post.note,
    pinterestLink: post.link,
    pinterestBoard: post.meta.pinterestBoard,
    pinterestTitle: post.meta.title,
    pinterestBoardIds: post.boardIds,
    pinterestBoards: post.boards,
  }),
  tiktok_post: (post) => ({
    message: post.text,
    tiktokDisableComment: post.disableComment,
    tiktokDisableDuet: post.disableDuet,
    tiktokDisableStitch: post.disableStitch,
    tiktokBoardIds: post.boardIds,
    tiktokBoards: post.boards,
  }),
  linkedin_post: (post) => ({
    message: encodeLinkedinNonReservedCharsForTiptap(post.text),
  }),
});

const CONVERT_TO_BY_TYPE = exhaustiveMap(DUPLICATION_POST_TYPES, {
  instagram_post: (post) => ({
    data: {
      post: {
        ...getSharedFields(post),
        platform: PLATFORMS.INSTAGRAM,
        caption: post.message.replace(encodedMentionRegex, '$1'),
        postType: 'FEED',
        location: post.facebookLocation,
        locationId: post.facebookLocationId,
        boardIds: post.instagramBoardIds,
        boards: post.instagramBoards,
        // Clear out thumb details if it is a custom thumbnail as it is not supported by this post type
        thumbnailUrl: post?.customCoverImage ? null : post.thumbnailUrl,
        thumbOffset: post?.customCoverImage ? null : post.thumbOffset,
        targeting: post.targeting ?? null,
      },
    },
    type: DIALOG_TYPES.INSTAGRAM,
  }),
  instagram_story: (post) => ({
    data: {
      post: {
        ...getSharedFields(post),
        platform: PLATFORMS.INSTAGRAM,
        caption: post.message.replace(encodedMentionRegex, '$1'),
        postType: 'STORY',
        location: post.facebookLocation,
        locationId: post.facebookLocationId,
        storyLink: post.instagramStoryLink,
        boardIds: post.instagramBoardIds,
        boards: post.instagramBoards,
        thumbnailUrl: post?.customCoverImage ? null : post.thumbnailUrl,
        thumbOffset: post?.customCoverImage ? null : post.thumbOffset,
      },
    },
    type: DIALOG_TYPES.INSTAGRAM,
  }),
  instagram_reel: (post) => ({
    data: {
      post: {
        ...getSharedFields(post),
        platform: PLATFORMS.INSTAGRAM,
        caption: post.message.replace(encodedMentionRegex, '$1'),
        postType: 'REEL',
        location: post.facebookLocation,
        locationId: post.facebookLocationId,
        autoPublish: true,
        shareToFeed: true,
        boardIds: post.instagramBoardIds,
        boards: post.instagramBoards,
        coverImageMedia: post.coverImageMedia,
      },
    },
    type: DIALOG_TYPES.INSTAGRAM,
  }),
  facebook_post: (post) => ({
    data: {
      post: {
        ...getSharedFields(post),
        platform: PLATFORMS.FACEBOOK,
        location: post.facebookLocation,
        locationId: post.facebookLocationId,
        message: post.message,
        boardIds: post.facebookBoardIds,
        boards: post.facebookBoards,
        thumbnailUrl: post?.customCoverImage ? null : post.thumbnailUrl,
        thumbOffset: post?.customCoverImage ? null : post.thumbOffset,
        targeting: post.targeting ?? null,
      },
    },
    type: DIALOG_TYPES.FACEBOOK,
  }),
  twitter_tweet: (post) => ({
    data: {
      post: {
        ...getSharedFields(post),
        platform: PLATFORMS.TWITTER,
        tweetStatus: post.message.replace(encodedMentionRegex, '$1'),
        location: post.twitterLocation,
        locationId: post.twitterLocationId,
        boardIds: post.twitterBoardIds,
        boards: post.twitterBoards,
        thumbnailUrl: post?.customCoverImage ? null : post.thumbnailUrl,
        thumbOffset: post?.customCoverImage ? null : post.thumbOffset,
      },
    },
    type: DIALOG_TYPES.TWITTER,
  }),
  pinterest_pin: (post) =>
    post.media && post.media.length > 1
      ? {
          data: {
            mediaList: [
              {
                ...post.media[0],
                description: post.message.replace(encodedMentionRegex, '$1'),
              },
              ...post.media.slice(1),
            ],
            initialPublishType: post.autoPublish ? 'autoPublish' : 'notification',
          },
          type: DIALOG_TYPES.PINTEREST_BULK,
        }
      : {
          data: {
            post: {
              ...getSharedFields(post),
              platform: PLATFORMS.PINTEREST,
              note: post.message.replace(encodedMentionRegex, '$1'),
              link: post.pinterestLink,
              boardIds: post.pinterestBoardIds,
              boards: post.pinterestBoards,
              thumbnailUrl: post?.customCoverImage ? null : post.thumbnailUrl,
              thumbOffset: post?.customCoverImage ? null : post.thumbOffset,
              meta: {
                thumbOffset: post.thumbOffset,
                title: post.pinterestTitle,
                pinterestBoard: post.pinterestBoard,
              },
            },
          },
          type: DIALOG_TYPES.PINTEREST,
        },
  tiktok_post: (post) => ({
    data: {
      post: {
        ...getSharedFields(post),
        platform: PLATFORMS.TIKTOK,
        text: post.message.replace(encodedMentionRegex, '$1'),
        autoPublish: post.autoPublish,
        disableComment: post.tiktokDisableComment,
        disableDuet: post.tiktokDisableDuet,
        disableStitch: post.tiktokDisableStitch,
        boardIds: post.tiktokBoardIds,
        boards: post.tiktokBoards,
        thumbnailUrl: post?.customCoverImage ? null : post.thumbnailUrl,
        thumbOffset: post?.customCoverImage ? null : post.thumbOffset,
      },
    },
    type: DIALOG_TYPES.TIKTOK,
  }),

  linkedin_post: (post) => ({
    data: {
      post: {
        ...getSharedFields(post),
        platform: PLATFORMS.LINKEDIN,
        text: decodeLinkedinNonReservedCharsForTiptap(
          post.message.replace(encodedMentionRegex, '$1'),
        ),
      },
    },
    type: DIALOG_TYPES.LINKEDIN,
  }),
});

/*
 * Convert a post of type fromType to a base post format, from which it can be converted to other
 * formats (using the facebook post format as the base format).
 */
export const convertPostFrom = (post, fromType) => ({
  ...getSharedFields(camelizeKeys(post)),
  ...CONVERT_FROM_BY_TYPE[fromType](camelizeKeys(post)),
});

/*
 * Convert a post from the base format (see above) to a particular toType
 */
export const convertPostTo = (basePost, toType) => mergeCase(CONVERT_TO_BY_TYPE[toType](basePost));

/*
 * Convert a post to be cross brand duplicated
 */
export function convertCrossBrandPost(post) {
  return Object.entries(post).reduce((obj, [key, value]) => {
    return {
      ...obj,
      [key]:
        CROSS_DUPLICATE_POST_TO_BRAND_KEYS.includes(key) ||
        CROSS_DUPLICATE_POST_TO_BRAND_KEYS.includes(camelCase(key))
          ? value
          : null,
    };
  }, {});
}
