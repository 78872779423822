<script setup>
import { defineAsyncComponent, watch, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Drawer, useDrawer, useFilterSidePanel } from '@dashhudson/dashing-ui';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import { isScreenXl } from '@/utils/tailwind';
import { SOCIAL_LISTENING_DRAWERS } from '@/app/socialListening/constants';
import { useGlobalModals } from '@/composables/useGlobalModals';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import { CREATOR_DRAWERS } from '@/app/creators/constants';
import { LIKESHOP_DRAWERS, ECOMMERCE_DRAWERS } from '@/app/ecommerce/constants';
import { getGlobalModals } from '@/utils/globalModals';

const route = useRoute();
const router = useRouter();

const { registerDrawer, stateKey, launchDrawer, closeDrawer, currentDrawer, parseStateKey } =
  useDrawer();

// Centralized drawer registration
registerDrawer(
  'creatorDeliverablesPosts',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorDeliverablesPosts.vue')),
);
registerDrawer(
  CREATOR_DRAWERS.CREATOR_PROFILE,
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorProfile.vue')),
);
registerDrawer(
  CREATOR_DRAWERS.ACCOUNT_PROFILE,
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/AccountProfile.vue')),
);
registerDrawer(
  'assignDeliverables',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Deliverables/AssignDeliverables.vue')),
);
registerDrawer(
  'topPerformingCreators',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/TopPerformingCreators.vue')),
);
registerDrawer(
  'addCreator',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Creators/AddCreatorsWizard.vue')),
);
registerDrawer(
  'creatorEmailThread',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorEmailThread.vue')),
);
registerDrawer(
  'creatorDeliverablesDateRangeEditor',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Deliverables/DateRangeEditor.vue')),
);
registerDrawer(
  'emailInvitation',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/EmailInvitation.vue')),
);

registerDrawer(
  'media',
  defineAsyncComponent(() => import('@/app/library/components/MediaPopup.vue')),
  null,
  { pushHistoryOnPropChange: true },
);
registerDrawer(
  'publicMedia',
  defineAsyncComponent(() => import('@/components/common/MediaPopupV2/PublicMediaPopup.vue')),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TOPIC_CREATOR,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopicCreator.vue'),
  ),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TRENDING_KEYWORD,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTrendingKeywords.vue'),
  ),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TOP_POST,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopPerformingPosts.vue'),
  ),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.VISUAL_TRENDS,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningVisualTrends.vue'),
  ),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.VISUAL_TREND,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningVisualTrend.vue'),
  ),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.MANAGE_TOPIC_LIMITS,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningManageTopicLimits.vue'),
  ),
);

registerDrawer(
  'campaignCreation',
  defineAsyncComponent(() => import('@/app/campaigns/pages/CampaignCreationDrawer.vue')),
);
registerDrawer(
  SOCIAL_LISTENING_DRAWERS.WEB_RESULTS,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopWebResults.vue'),
  ),
);

registerDrawer(
  'selectMedia',
  defineAsyncComponent(() => import('@/components/core/SelectMediaPopup.vue')),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.TOPIC_ALERT,
  defineAsyncComponent(() => import('@/app/socialListening/drawers/SocialListeningTopicAlert.vue')),
);

registerDrawer(
  'startTrial',
  defineAsyncComponent(() => import('@/components/InAppTrials/StartTrialPopup.vue')),
);

registerDrawer(
  'SchedulerEventDrawer',
  defineAsyncComponent(() => import('@/app/scheduler/components/EventDrawer.vue')),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.VISUAL_FILTERS,
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningVisualFilters.vue'),
  ),
);

registerDrawer(
  SOCIAL_LISTENING_DRAWERS.MEDIA_POST,
  defineAsyncComponent(() => import('@/app/socialListening/drawers/SocialListeningMediaPost.vue')),
);

registerDrawer(
  LIKESHOP_DRAWERS.SETTINGS,
  defineAsyncComponent(
    () => import('@/app/ecommerce/components/drawers/LikeShopSettingsDrawer.vue'),
  ),
);

registerDrawer(
  ECOMMERCE_DRAWERS.MEDIA_LINKS,
  defineAsyncComponent(() => import('@/app/ecommerce/components/drawers/MediaLinksDrawer.vue')),
);

registerDrawer(
  ECOMMERCE_DRAWERS.PRODUCT,
  defineAsyncComponent(() => import('@/app/ecommerce/components/drawers/ProductDrawer.vue')),
);

registerDrawer(
  ECOMMERCE_DRAWERS.FEED_REORDER,
  defineAsyncComponent(
    () => import('@/app/ecommerce/components/drawers/LikeShopFeedReorderDrawer.vue'),
  ),
);

registerDrawer(
  ECOMMERCE_DRAWERS.UTM_SETTINGS,
  defineAsyncComponent(() => import('@/app/ecommerce/components/drawers/UtmSettingsDrawer.vue')),
);

const { hasGlobalModal } = useGlobalModals();
watch(
  () => hasGlobalModal.value,
  () => {
    if (hasGlobalModal.value) {
      closeDrawer();
    }
  },
);

function isSameStateKey(leftStateKey, rightStateKey) {
  return isEqual(leftStateKey, rightStateKey);
}

function isSameDrawer(leftStateKey, rightStateKey) {
  const leftStateKeyObject = parseStateKey(leftStateKey);
  const rightStateKeyObject = parseStateKey(rightStateKey);
  return isEqual(leftStateKeyObject?.name, rightStateKeyObject?.name);
}

function changeDrawerQuery({ d, replace = false }) {
  const globalModalsOpen = getGlobalModals()?.length > 0;

  if (!globalModalsOpen) {
    // work around for global modals
    router[replace ? 'replace' : 'push']({
      ...route,
      query: {
        ...route.query,
        d,
      },
    });
  }
}

const pushOnPropChange = computed(() => {
  if (isFunction(currentDrawer.value?.meta?.pushHistoryOnPropChange)) {
    return currentDrawer.value.meta.pushHistoryOnPropChange();
  }
  return !!currentDrawer.value?.meta?.pushHistoryOnPropChange;
});

// Watch for stateKey changes and update the route query
watch(
  () => stateKey.value,
  (sk, oldSk) => {
    if (!sk) {
      if (!hasGlobalModal.value) {
        changeDrawerQuery({ d: undefined });
      }
    } else if (!isSameDrawer(sk, oldSk) || pushOnPropChange.value) {
      changeDrawerQuery({ d: sk });
    } else if (!isSameStateKey(sk, route.query.d)) {
      changeDrawerQuery({ d: sk, replace: true });
    }
  },
);

function updateDrawerState() {
  if (isSameStateKey(stateKey.value, route.query.d)) {
    return;
  }

  if (route.query?.d) {
    launchDrawer({ ...route.query, stateKey: route.query.d });
  } else {
    closeDrawer();
  }
}

onMounted(() => {
  updateDrawerState();
});

watch(route, () => {
  updateDrawerState();
});

const { expanded, collapseNavigationPanel } = useSecondaryNavigationPanel();

watch(
  () => [isScreenXl.value, currentDrawer.value],
  () => {
    if (!isScreenXl.value && expanded.value && currentDrawer.value) {
      collapseNavigationPanel();
    }
  },
);

router.afterEach((to, from) => {
  if (to?.path !== from?.path) {
    closeDrawer();
  }
});

const { visible: filterVisible } = useFilterSidePanel();

const drawerMaxWidth = computed(() => {
  let usedSpace = '(var(--primary-navigation-width) + 40px)';
  if (filterVisible.value) {
    usedSpace = 'var(--filter-side-panel-width)';
  } else if (expanded.value) {
    if (isScreenXl.value) {
      usedSpace = '(var(--combined-navigation-width) + 40px)';
    }
  }
  return `calc(100% - ${usedSpace})`;
});
</script>

<template>
  <Drawer
    :keep-alive="{
      exclude: ['MediaPopup', 'CreatorDeliverablesPosts', 'CreatorEmailThread'],
    }"
    :style="{
      maxWidth: drawerMaxWidth,
    }"
  />
</template>

<style lang="postcss">
.hs-web-interactives-top-banner-open {
  [data-dash-drawer] {
    top: var(--hubspot-banner-height) !important;
  }
}
</style>
