import {
  DEFAULT_OPERATOR_BY_GROUP_TYPE,
  ILLEGAL_KEYWORD_SPECIAL_CHARACTERS,
  KEYWORD_EDITOR_CHAR_TO_CLASS,
  KEYWORD_GROUP_TYPE,
  KEYWORD_TEXT_EDITOR_ERRORS,
  KEYWORD_VALIDATION_REGEX,
  keywordDuplicateError,
  MAX_TOPIC_KEYWORD_LENGTH,
  nestingDepthExceededError,
  notClauseContainsAndError,
  OPERATOR_MAP,
} from '@/app/socialListening/constants';
import uniq from 'lodash/uniq';
import { useKeywordExpressionValidators } from '@/app/socialListening/composables/useKeywordExpressionValidators';
import { useListeningPermissions } from '@/app/socialListening/composables/useListeningPermissions';
import uniqueId from 'lodash/uniqueId';

export function addKeywordToGroup(keywordGroups, idx, word) {
  const updatedGroup = {
    ...keywordGroups[idx],
    keywords: [...keywordGroups[idx].keywords, word],
  };
  keywordGroups.splice(idx, 1, updatedGroup);
}

export function removeKeywordFromGroup(keywordGroups, idx, word) {
  const updatedGroup = {
    ...keywordGroups[idx],
    keywords: keywordGroups[idx].keywords.filter((kw) => kw !== word),
  };
  // splice & replace with updated group
  keywordGroups.splice(idx, 1, updatedGroup);
}

export function addGroup(keywordGroups, groupType, defaultOperator, newGroup = {}) {
  const includeGroups = keywordGroups.filter((group) => !group.operators.includes('not'));
  let excludeGroup = keywordGroups.find((group) => group.operators.includes('not'));
  const groupLength = keywordGroups?.length;
  if (groupLength) {
    // if there are existing groups
    if (groupType === KEYWORD_GROUP_TYPE.INCLUDES) {
      const previousGroupIndex = includeGroups.length - 1;
      const previousGroup = includeGroups[previousGroupIndex];
      let newIncludeGroup;
      if (excludeGroup && previousGroupIndex < 0) {
        newIncludeGroup = {
          operators: ['or'],
          level: 0,
          keywords: [],
          id: uniqueId(),
          ...newGroup,
        };
        excludeGroup.operators = [OPERATOR_MAP.AND.toLowerCase(), OPERATOR_MAP.NOT.toLowerCase()];
      } else {
        if (previousGroup && defaultOperator === OPERATOR_MAP.AND) {
          includeGroups[previousGroupIndex].level = 1;
        }
        newIncludeGroup = {
          operators: [defaultOperator.toLowerCase()],
          level: defaultOperator === OPERATOR_MAP.AND ? 1 : 0,
          keywords: [],
          id: uniqueId(),
          ...newGroup,
        };
      }
      keywordGroups.splice(includeGroups.length, 0, newIncludeGroup);
    } else {
      excludeGroup = {
        keywords: newGroup.keywords ?? [],
        operators: !includeGroups.length ? ['not'] : ['and', 'not'],
        level: 0,
        id: uniqueId(),
      };
      keywordGroups.push(excludeGroup);
    }
  } else {
    // no existing groups
    const operators = groupType === KEYWORD_GROUP_TYPE.EXCLUDES ? ['not'] : ['or'];
    keywordGroups.push({
      level: 0,
      keywords: [],
      operators,
      id: uniqueId(),
      ...newGroup,
    });
  }
  return keywordGroups;
}

export function updateGroup(keywordGroups, index, params) {
  const group = keywordGroups[index];
  const includeGroups = keywordGroups.filter((g) => !g.operators.includes('not'));
  const { operators } = params;
  let updatedGroup = { operators };
  let previousGroup = null;
  let nextGroup = null;

  if (index > 0) {
    previousGroup = keywordGroups[index - 1];
  }
  if (index < includeGroups.length - 1) {
    nextGroup = keywordGroups[index + 1];
  }
  if (operators.includes('and') && !operators.includes('not')) {
    // if we change a group from or -> and
    if (previousGroup && !previousGroup.operators.includes('not')) {
      // if the previous group is not an excludes group then change to an and group
      updatedGroup = {
        ...updatedGroup,
        level: 1,
        operators: [OPERATOR_MAP.AND.toLowerCase()],
      };
      // if the previous group is an or level 0
      keywordGroups.splice(index - 1, 1, {
        ...previousGroup,
        level: 1,
      });
    }
  } else if (operators.includes('or')) {
    // if we change from and -> or
    if (previousGroup && previousGroup.operators.includes('or')) {
      // if the previous group was an includes or we should un-nest that group
      keywordGroups.splice(index - 1, 1, {
        ...previousGroup,
        level: 0,
      });
    }
    if (nextGroup && nextGroup.operators.includes('and') && !nextGroup.operators.includes('not')) {
      // we need to check if the next includes group is an "and"
      // if it is, we need to change the level of the current group to 1
      updatedGroup = {
        ...updatedGroup,
        level: 1,
      };
    } else {
      // just put it on level 0
      updatedGroup = {
        ...updatedGroup,
        level: 0,
        operators: ['or'],
      };
    }
  }

  if (operators.includes('not')) {
    // remove and add to avoid index clashes since "exclude" groups have their index changed to the last in the list
    keywordGroups.splice(index, 1);
    addGroup(
      keywordGroups,
      KEYWORD_GROUP_TYPE.EXCLUDES,
      DEFAULT_OPERATOR_BY_GROUP_TYPE[KEYWORD_GROUP_TYPE.EXCLUDES],
      { ...group, ...updatedGroup },
    );
  } else {
    keywordGroups.splice(index, 1, { ...group, ...updatedGroup });
  }
}

export function removeGroup(keywordGroups, index) {
  keywordGroups.splice(index, 1);
  // if a "parent" "includes" statement has its immediate child group removed
  if (keywordGroups?.length && keywordGroups?.[index - 1]?.operators?.includes('or')) {
    updateGroup(keywordGroups, index - 1, { operators: ['or'] });
  } else if (keywordGroups.length === 1 && keywordGroups[0]?.operators.includes('not')) {
    // if the last group remaining is an "excludes" group change and not -> not
    updateGroup(keywordGroups, 0, { operators: ['not'] });
  }
}

export function createOrUpdateExcludeGroup(keywordGroups, groupType, { keywords }) {
  // if an excludes group exists already just append the keywords
  const excludeGroup = keywordGroups.find((group) => group.operators.includes('not'));
  const index = keywordGroups.findIndex((group) => group.operators.includes('not'));
  if (index >= 0) {
    keywordGroups.splice(index, 1, {
      ...excludeGroup,
      keywords: [...excludeGroup.keywords, ...keywords],
    });
  } else {
    addGroup(keywordGroups, groupType, DEFAULT_OPERATOR_BY_GROUP_TYPE[groupType], {
      keywords,
    });
  }
  return keywordGroups;
}

export function escapeBeforeRegex(snippet) {
  if (snippet === null) {
    return '';
  }
  return snippet.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function allowUnlimitedWhitespaceBetweenMultiWord(snippet) {
  return snippet.replace(/\s/g, '\\s+');
}

export function captureLongWordRegex(str) {
  const flexibleWhitespaceStr = str.replace(/\s+/g, '\\s*');
  return RegExp(`(\\s*${flexibleWhitespaceStr}\\s*)`, 'g');
}

export function genericUnexpectedError(unexpected, line) {
  const onLineVerbiage = line ? `, on line ${line}` : '';
  return unexpected ? `Unexpected character, "${unexpected}"${onLineVerbiage}.` : '';
}

export function genericExpectedError(expected, line) {
  const verbage = expected?.length > 1 ? 'one of the characters' : 'character';
  const onLineVerbiage = line ? `, on line ${line}` : '';
  return expected ? `Expected ${verbage} "${expected.join(', ')}"${onLineVerbiage}.` : '';
}

export function buildMessageForParseTreeError(unexpected, expected, line) {
  if (expected.length === 1 && expected[0] === ')') {
    return KEYWORD_TEXT_EDITOR_ERRORS.MISSING_CLOSING_BRACKET;
  }
  if (expected.length === 1 && expected[0] === 'END' && unexpected === ')') {
    return KEYWORD_TEXT_EDITOR_ERRORS.MISSING_STARTING_BRACKET;
  }
  if (expected.length > 1 && unexpected === ')') {
    return KEYWORD_TEXT_EDITOR_ERRORS.UNEXPECTED_CLOSING_BRACKET;
  }
  if (['AND', 'OR', 'NOT'].includes(unexpected)) {
    return KEYWORD_TEXT_EDITOR_ERRORS.MULTIPLE_OPERATORS;
  }
  if (unexpected) {
    return genericUnexpectedError(unexpected, line);
  }
  if (expected) {
    return genericExpectedError(expected, line);
  }
  return `${unexpected} ${expected}`;
}

export function keywordHasSpecialCharacter(text) {
  const { hasSpecialSearchFeatureFlag } = useListeningPermissions();
  if (hasSpecialSearchFeatureFlag.value) {
    return ILLEGAL_KEYWORD_SPECIAL_CHARACTERS.test(text);
  }
  return KEYWORD_VALIDATION_REGEX.test(text);
}

export function handleTextEditorErrorsUsingKeywordGroups(keywordGroups) {
  const keywordList = [];
  const duplicateKeywords = [];
  keywordGroups.forEach((group) => {
    const keywords = group.keywords;
    if (keywords?.length > 0) {
      keywordList.push(...keywords);
      duplicateKeywords.push(...keywords.filter((k, i) => keywords.indexOf(k) < i));
    }
  });
  const maxReached = keywordList.filter((k) => k.length > MAX_TOPIC_KEYWORD_LENGTH);
  const unsupportedChar = keywordList.filter((k) => keywordHasSpecialCharacter(k));
  const errors = [];
  if (duplicateKeywords.length) {
    errors.push(
      ...uniq(duplicateKeywords).map((k) => ({
        error: keywordDuplicateError(k),
      })),
    );
  }
  if (maxReached.length) {
    maxReached.forEach((word) => {
      errors.push({
        error: KEYWORD_TEXT_EDITOR_ERRORS.MAX_LENGTH_EXCEEDED,
        regex: {
          pattern: captureLongWordRegex(word),
        },
      });
    });
  }

  if (unsupportedChar.length) {
    unsupportedChar.forEach((char) => {
      errors.push({
        error: KEYWORD_TEXT_EDITOR_ERRORS.UNSUPPORTED_CHARACTER,
        regex: {
          pattern: RegExp(
            `(${allowUnlimitedWhitespaceBetweenMultiWord(escapeBeforeRegex(char))})`,
            'g',
          ),
        },
      });
    });
  }

  return errors;
}

export function handleTextEditorErrorsUsingParseTree(tree) {
  const { notClauseContainsAndNear, andDepthExceededNear, parenthesisDepthExceededNear } =
    useKeywordExpressionValidators();

  const errors = [];
  // not clause containing and operators
  let nearSnippet = notClauseContainsAndNear(tree);
  if (nearSnippet) {
    const keyword = nearSnippet.split(/\s/).slice(0)?.[0];
    const operator = nearSnippet.split(/\s/).slice(-1)?.[0];
    errors.push({
      error: notClauseContainsAndError(keyword),
      regex: {
        pattern: RegExp(`(?:\\s*NOT\\s+\\(.*${keyword}\\s+)(\\b${operator}\\b)`, 'g'),
        inside: {
          'error-inside': RegExp(`(\\b${operator}\\b)`),
        },
      },
    });
  }
  // handle the 2 types of depth errors separately
  nearSnippet = andDepthExceededNear(tree);
  if (nearSnippet) {
    const snippetList = nearSnippet
      .split(/\s/)
      .slice(-2)
      .map((snippet) => escapeBeforeRegex(snippet));
    errors.push({
      error: nestingDepthExceededError(snippetList?.[0]),
      regex: {
        pattern: RegExp(`(?:\\s*${snippetList?.[0]}\\s+)(${snippetList?.[1]})`, 'g'),
        inside: {
          'error-inside': RegExp(`(\\b${snippetList.slice(-1)?.[0]}\\b)`, 'g'),
        },
      },
    });
  } else {
    nearSnippet = parenthesisDepthExceededNear(tree);
    if (nearSnippet) {
      const snippetList = nearSnippet
        .split(/\s/)
        .slice(-2)
        .map((snippet) => escapeBeforeRegex(snippet));
      errors.push({
        error: nestingDepthExceededError(snippetList?.slice(-1)?.[0]),
        regex: {
          pattern: RegExp(`(?:(\\s*${snippetList?.[0]}\\s+)*\\(()${snippetList?.[1]})`, 'g'),
          inside: {
            'error-inside': /(\()/g,
          },
        },
      });
    }
  }

  return errors;
}

export function highlightMissingBrackets(errors, codeEditor) {
  errors.forEach((err) => {
    const { error } = err;
    if (
      error === KEYWORD_TEXT_EDITOR_ERRORS.MISSING_STARTING_BRACKET ||
      error === KEYWORD_TEXT_EDITOR_ERRORS.MISSING_CLOSING_BRACKET
    ) {
      const { itemIndex, adjacency } =
        error === KEYWORD_TEXT_EDITOR_ERRORS.MISSING_STARTING_BRACKET
          ? {
              itemIndex: 0,
              adjacency: 'beforebegin',
            }
          : { itemIndex: -1, adjacency: 'afterend' };
      // find the error token for the highlighted closing bracket
      const codeBlockContent = codeEditor.value?.codeBlockContent;
      if (codeBlockContent) {
        const tokens = Array.from(codeBlockContent.querySelectorAll('.token'));
        // insert a preceding error token with one space to underline red
        if (tokens.length) {
          tokens
            .slice(itemIndex)?.[0]
            .insertAdjacentHTML(adjacency, '<span class="token error temp">&nbsp;</span>');
        }
      }
    }
  });
}

function isAnOperatorChar(char) {
  return ['(', ')', 'AND', 'NOT', 'OR'].includes(char);
}

function getTokenElementsFromDOM(node, char) {
  if (isAnOperatorChar(char)) {
    return node.querySelectorAll(KEYWORD_EDITOR_CHAR_TO_CLASS?.[char]);
  }
  return node.querySelectorAll('.token.keyword, .token.multiword-keyword');
}

function addErrorClassForToken(tokens, value, offset = -1) {
  const target = tokens.item(value + offset);
  if (target) {
    target.classList.add('error');
  }
}

function getKeywordTokenTargetIndices(tokens, keyword) {
  return Array.from(tokens)
    .map((node, index) => (node.innerHTML === keyword ? index : -1))
    .filter((index) => index > -1);
}

export function markTokensAsErrorByOccurrence(codeBlockContent, errorOccurrenceMap) {
  if (codeBlockContent) {
    // tag the relevant tokens with the error class
    Object.entries(errorOccurrenceMap).forEach(([token, value]) => {
      // if a token type has a value, mark its nth occurrence as an error
      const targets = getTokenElementsFromDOM(codeBlockContent, token);
      if (typeof value === 'number' && value > 0) {
        // mark a single token
        if (isAnOperatorChar(token)) {
          addErrorClassForToken(targets, value);
        } else {
          const targetIndices = getKeywordTokenTargetIndices(targets, token);
          addErrorClassForToken(targets, targetIndices[value - 1], 0);
        }
      } else if (Array.isArray(value) && value?.length) {
        // mark a list of identical tokens
        if (isAnOperatorChar(token)) {
          value.forEach((n) => {
            addErrorClassForToken(targets, n);
          });
        } else {
          const targetIndices = getKeywordTokenTargetIndices(targets, token);
          value.forEach((n) => {
            addErrorClassForToken(targets, targetIndices[n - 1], 0);
          });
        }
      } else if (value === '*') {
        // mark all tokens
        targets.forEach((node) => node.classList.add('error'));
      }
    });
  }
}
