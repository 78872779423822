import dayjs from 'dayjs';
import numeral from 'numeral';
import startCase from 'lodash/startCase';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import round from 'lodash/round';
import humps from 'humps';
import enumTypes, { durationUnits } from '@/app/library/constants';
import { differenceBetweenDays, reformatDateString } from '@/utils/dateUtils';
import { mostEntertainingMinAbbrNumber, constants } from '@/config';
import { colours } from '@/ux/colours';
import { PLATFORM_CONNECTION_NAMES } from '@/models/platform/platform-connection.enum';
import { DURATION_FORMATS } from '@/models/dashboards/metrics.constants';

export const TIME_UNIT_OPTIONS = {
  MILLISECOND: 'millisecond',
  SECOND: 'second',
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  YEAR: 'year',
};

export const FULL_TIME_UNITS = [
  { unit: TIME_UNIT_OPTIONS.MILLISECOND, relativeSize: 1000 },
  { unit: TIME_UNIT_OPTIONS.SECOND, relativeSize: 60 },
  { unit: TIME_UNIT_OPTIONS.MINUTE, relativeSize: 60 },
  { unit: TIME_UNIT_OPTIONS.HOUR, relativeSize: 24 },
  { unit: TIME_UNIT_OPTIONS.DAY, relativeSize: 365 },
  { unit: TIME_UNIT_OPTIONS.YEAR },
];

const DEFAULT_TIME_UNITS = [
  { unit: TIME_UNIT_OPTIONS.SECOND, relativeSize: 60 },
  { unit: TIME_UNIT_OPTIONS.MINUTE, relativeSize: 60 },
  { unit: TIME_UNIT_OPTIONS.HOUR },
];

export const UNIT_DISPLAY_OPTIONS = {
  /*
    This value is passed in as an option to the toLocaleString()
    It it used to determine how the unit is displayed next to the value
    For example:
    "long" will show (2 Seconds),
    "short" will show (2 Sec),
    "narrow" will show (2 S),
  */
  LONG: 'long',
  SHORT: 'short',
  NARROW: 'narrow',
};

export function localize(value, localeOptions = { maximumFractionDigits: 0 }) {
  if (value) {
    const localizedValue = value.toLocaleString('en', localeOptions);
    if (localizedValue === '-0') return '0';
    return localizedValue;
  }
  return '0';
}

/*
  params:
  - value: float. The currency amount.
  - code: string. Currency codes (ISO 4217)
  expected output: ${currencySymbol}${amount.toFixed(2) ${code}}
*/
export function localizeCurrency(value, code) {
  try {
    let fmtCurrency = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: code,
      maximumFractionDigits: 2,
    }).format(value);
    // the formatted value can be CA$1234.56, replace the prefix abbreviation.
    fmtCurrency = fmtCurrency.replace(/^[a-z]*/i, '');
    return `${fmtCurrency} ${code}`;
  } catch (e) {
    return '-';
  }
}

export function localizePercent(value, symbol) {
  if (value) {
    const number = value.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 });
    if (symbol && value >= 0) {
      return `+ ${number}`;
    }
    return number;
  }
  return '0%';
}

export function localizePercentWithSign(value) {
  if (value) {
    if (value > 0) {
      return `+${value.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}`;
    }
    return value.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 });
  }
  return '+0%';
}

export function localizePercentOrNumber(value, forcePercentage) {
  // one component can deal with large number or percentage number in different places
  // this filter handles value formatting accordingly so that a percentage number won't be formatted to 0.
  if (forcePercentage) {
    return localizePercent(value);
  }
  if (value > -1 && value < 1 && value !== 0) {
    return localizePercent(value);
  }
  return localize(value);
}

function standardDate(date) {
  if (date instanceof dayjs) {
    return date;
  }
  return dayjs(date);
}

export function formatDatetime(dateStr, format) {
  const formatStr = format || 'MMM D, YYYY h:mmA';
  return dateStr ? dayjs(dateStr).format(formatStr) : '';
}

export function formatDuration(
  duration,
  numDecimals = 0,
  smallestUnit = TIME_UNIT_OPTIONS.SECOND,
  removeTrailingZeros = true,
  units = DEFAULT_TIME_UNITS,
  unitDisplay = UNIT_DISPLAY_OPTIONS.NARROW,
) {
  if (duration == null || duration < 0 || duration === '-') {
    return '-';
  }
  // relativeSize is compared to the next unit (required for all but last unit)
  let smallestUnitIndex = units.findIndex((unit) => unit.unit === smallestUnit);
  if (smallestUnitIndex === -1) {
    smallestUnitIndex = 0;
  }
  /*
    At each iteration, remaining is the amount of time (in the iteration's current unit) not yet
    represented by a smaller unit.
    Example: If duration = 4850, this is what each iteration looks like:
      unit = 'second' -> remaining = 4850 (seconds) -> value = 50 (seconds)
      unit = 'minute' -> remaining = 80 (minutes)   -> value = 20 (minutes)
      unit = 'hour'   -> remaining = 1 (hour)       -> value = 1 (hour)
  */
  let remaining = duration;
  return units
    .reduce((formattedUnits, unit, index) => {
      const isSmallestUnit = unit.unit === smallestUnit;
      // Stop adding units if there's no time left to add (unless smallest unit i.e. duration = 0)
      if (remaining > 0 || isSmallestUnit) {
        if (index >= smallestUnitIndex) {
          // Determine unit value and reduce remaining to be in terms of the next unit
          let value = unit?.relativeSize ? remaining % unit.relativeSize : remaining;
          remaining = unit?.relativeSize ? (remaining - value) / unit.relativeSize : 0;
          value = round(value, isSmallestUnit ? numDecimals : 0);

          // Edge case: seconds round up to 60, for example
          if (value === unit?.relativeSize) {
            remaining += 1;
            value = 0;
          }

          const localeOptions = {
            style: 'unit',
            unit: unit.unit,
            unitDisplay,
            minimumIntegerDigits: value === 0 && remaining > 0 ? 2 : 1,
            minimumFractionDigits: isSmallestUnit ? numDecimals : 0,
            maximumFractionDigits: isSmallestUnit ? numDecimals : 0,
          };

          // need to determine if the current value is a trailing 0 (if we want to remove them)
          // if the current value is 0, the amount remaining is greater than 0, and we haven't
          // added any formatted units yet, then it is a trailing zero

          if (!removeTrailingZeros || value > 0 || remaining === 0 || formattedUnits.length > 0) {
            formattedUnits.push(value.toLocaleString('en', localeOptions));
          }
        } else {
          // Reduce remaining to be in terms of the next unit
          remaining /= unit.relativeSize;
        }
      }
      return formattedUnits;
    }, [])
    .reverse()
    .join(':');
}

export function formatDynamicDuration(
  value,
  rawUnit = TIME_UNIT_OPTIONS.SECOND,
  unitDisplay = UNIT_DISPLAY_OPTIONS.NARROW,
) {
  const rawUnitIndex = FULL_TIME_UNITS.findIndex((unit) => unit.unit === rawUnit);
  const timeUnits = FULL_TIME_UNITS.slice(rawUnitIndex);
  return formatDuration(
    value,
    value >= 60 ? 0 : 1,
    TIME_UNIT_OPTIONS.SECOND,
    true,
    timeUnits,
    unitDisplay,
  )
    .split(':')
    .slice(0, 2)
    .join(':');
}

export function visualizeDateRange(start, end) {
  const startDate = standardDate(start);
  const endDate = standardDate(end);

  if (startDate && startDate.isSame(endDate, 'year')) {
    if (startDate.isSame(endDate, 'month')) {
      if (startDate.isSame(endDate, 'day')) {
        return startDate.format('MMM DD, YYYY');
      }
      return `${startDate.format('MMM')} ${startDate.format('DD')} - ${endDate.format(
        'DD',
      )}, ${startDate.year()}`;
    }
    return `${startDate.format('MMM DD')} - ${endDate.format('MMM DD')}, ${startDate.year()}`;
  }
  return `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`;
}

export function getDateFormatPattern(options) {
  if (typeof options === 'undefined') {
    return 'YYYY-MM-DD';
  }
  let formatPattern = '';
  switch (options.scale) {
    case enumTypes.HOURLY:
      formatPattern = 'MMM D, ha';
      break;
    case enumTypes.MONTHLY:
      formatPattern = 'MMM';
      break;
    default:
      formatPattern = 'MMM DD';
      break;
  }
  if (options.displayYear) {
    formatPattern += ', YYYY';
  }
  return formatPattern;
}

export function abbrLargeNumber(value, useK, fractionDigits = 2, localeOptions = {}) {
  if (value > 999999) {
    return `${(value / 1000000).toFixed(fractionDigits)}M`;
  }
  if (useK && value > 999) {
    return `${(value / 1000).toFixed(fractionDigits)}K`;
  }
  return localize(value, localeOptions);
}

export function formatPercentage(
  value,
  minimumDecimalPlaces = 0,
  maximumDecimalPlaces = undefined,
) {
  return isNumber(value)
    ? value.toLocaleString('en', {
        style: 'percent',
        minimumFractionDigits: minimumDecimalPlaces,
        maximumFractionDigits: maximumDecimalPlaces ?? minimumDecimalPlaces,
      })
    : '-';
}

export function formatNumber(value, percentage = false, minAbbrNumber = null, decimals = null) {
  if (typeof value !== 'number') {
    return '-';
  }

  const absNumber = Math.abs(value);

  // Percentage Values
  if (percentage) {
    const flooredValue = absNumber >= 0.00001 ? value : 0; // Preventing '-0%'
    return numeral(flooredValue).format('0[.]00%');
  }

  // Abbreviated Numbers
  if (absNumber >= minAbbrNumber && minAbbrNumber !== null) {
    return numeral(value).format('0[.]00a').toUpperCase();
  }

  // round to whole number or specified decimal places
  const format = decimals !== null ? `0,0[.]${'0'.repeat(decimals)}` : '0,0';
  return numeral(value).format(format);
}

export function formatNumberWithCommas(num) {
  return isNumber(num) ? numeral(num).format('0,0') : '';
}

export function formatDecimal(value, places = 0) {
  if (typeof value !== 'number') {
    return '-';
  }
  return `${value.toFixed(places)}`;
}

export function formatMillisecondsAsTime(value) {
  const minutes = Math.floor(value / 60000);
  const seconds = Math.floor(value / 1000 - minutes * 60);
  const formatSeconds = `${seconds.toString().length === 1 ? '0' : ''}${seconds}`;
  return `${minutes}:${formatSeconds}`;
}

export function formatHour(hour) {
  return dayjs().set('hour', hour).set('minute', 0).format('h:00 a');
}

export function formatDatePattern(date, pattern) {
  return dayjs(date).format(pattern);
}

export function formatDateRangeLabel(
  start,
  end,
  { forceYearDisplay = false, withTime = false } = {},
) {
  if (start && end) {
    const startDate = dayjs.isDayjs(start) ? start : dayjs(start);
    let endDate = dayjs.isDayjs(end) ? end : dayjs(end);
    if (withTime) {
      endDate = endDate.subtract(1, 'minute');
    }
    const today = dayjs();
    const rangeInCurrentYear = today.isSame(startDate, 'year') && today.isSame(endDate, 'year');
    const yearFormat = rangeInCurrentYear && !forceYearDisplay ? '' : ', YYYY';

    const sameYear = startDate.isSame(endDate, 'year');
    const sameMonth = startDate.isSame(endDate, 'month');
    const sameDay = startDate.isSame(endDate, 'day');

    let startFormat = 'MMM D';
    let endFormat = 'D';

    if (withTime) {
      startFormat += ', h:mma';
      endFormat += ', h:mma';
    }
    if (sameDay) {
      if (withTime) {
        return `${startDate.format(startFormat)} - ${endDate.format(`h:mma${yearFormat}`)}`;
      }
      return `${startDate.format(startFormat + yearFormat)}`;
    }
    if (!sameMonth || withTime) {
      // Want to always include month if with time because adding time would makes the
      // format harder to follow (e.g. Feb 1, 12:00am - 28, 11:59pm)
      endFormat = `MMM ${endFormat}`;
    }
    if (sameYear) {
      endFormat += yearFormat;
    } else {
      startFormat += ', YYYY';
      endFormat += ', YYYY';
    }
    return `${startDate.format(startFormat)} - ${endDate.format(endFormat)}`;
  }
  return '';
}

export function formatPlatformConnectionLabel(platform) {
  switch (platform) {
    // TODO sc-106472: Remove _new and make this default
    case 'facebook_ads_new':
      return 'Meta Ads';
    case 'facebook_ads':
      return 'Facebook Ads';
    case 'instagram_influencer':
      return 'Facebook';
    case 'tiktok':
      return 'TikTok';
    case 'tiktok_ads':
      return 'TikTok Ads';
    case 'tiktok_creator_marketplace':
      return 'TikTok Creator Marketplace';
    case 'youtube':
      return 'YouTube';
    case 'ga':
      return 'Google Analytics';
    case 'linkedin':
      return 'LinkedIn';
    case 'twitter':
      return 'X';
    default:
      return startCase(platform.split('_')[0]);
  }
}

export function getPlatformLabelFromProvider(provider) {
  switch (provider) {
    case PLATFORM_CONNECTION_NAMES.INSTAGRAM:
      return 'Instagram';
    default:
      return formatPlatformConnectionLabel(provider);
  }
}

export function formatPlatformLabel(platform) {
  switch (platform) {
    case 'ga':
      return 'Google';
    case 'youtube':
      return 'Google';
    case 'facebook_ads':
      return 'Facebook';
    default:
      return formatPlatformConnectionLabel(platform);
  }
}

export function formatProviderLabel(provider) {
  switch (provider) {
    case 'tiktok':
      return 'TikTok';
    case 'youtube':
      return 'YouTube';
    case 'ga':
      return 'Google Analytics';
    case 'pinterest_v5':
      return 'Pinterest';
    case 'twitter':
      return 'X';
    default:
      return startCase(provider);
  }
}

export const formatPatternList = ['YYYY-MM-DD hA', 'YYYY-MM-DD', 'YYYY-MM'];

export function capitalize(word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function dateValues(date) {
  if (!isString(date)) {
    return {};
  }
  const split = date.split('-');
  return {
    year: parseInt(split[0], 10),
    month: parseInt(split[1], 10),
    day: parseInt(split[2], 10),
  };
}

export function timeseriesMonthlyLabel(date) {
  return dayjs(date).format('MMM');
}

export function timeseriesDailyLabel(date) {
  return dayjs(date).format('MMM D');
}

export function timeseriesMonthlyTooltip(date, startMonth, startDay, endMonth, endDay) {
  const currentMonth = parseInt(date.split('-')[1], 10);

  let startOfMonth = 1;
  let endOfMonth = dayjs(date).endOf('month').date();

  if (currentMonth === startMonth && startDay > startOfMonth) {
    startOfMonth = startDay;
  }
  if (currentMonth === endMonth && endDay < endOfMonth) {
    endOfMonth = endDay;
  }

  // Avoid "Sep 1 - 1", should just show "Sep 1"
  const dateLabel = startOfMonth !== endOfMonth ? `${startOfMonth} - ${endOfMonth}` : startOfMonth;

  return `${timeseriesMonthlyLabel(date)} ${dateLabel}`;
}

export function timeseriesDailyTooltip(date) {
  return timeseriesDailyLabel(date);
}

export function timeseriesHourlyTooltip(date) {
  const metricYear = dayjs(date).year();
  const currentYear = dayjs().year();
  if (currentYear !== metricYear) {
    return dayjs(date).format(`MMM D, ${metricYear} ha`);
  }
  return dayjs(date).format('MMM D ha');
}

export function timeseriesYearSuffix(date, title) {
  const metricYear = dayjs(date).year();
  const currentYear = dayjs().year();
  if (currentYear !== metricYear) {
    return `${title}, ${metricYear}`;
  }
  return title;
}

export function formatTime(totalSeconds) {
  const hours = Math.floor(Math.round(totalSeconds) / 3600);
  const minutes = Math.floor(Math.round(totalSeconds - hours * 3600) / 60);
  const seconds = `0${Math.round(totalSeconds) % 60}`.slice(-2);
  if (hours > 0) {
    return `${hours}:${`0${minutes}`.slice(-2)}:${seconds}`;
  }
  if (minutes === 0) {
    return `00:${seconds}`;
  }
  return `${minutes}:${seconds}`;
}

// formatter for stats metrics from InfluencerTile, StoryCard, etc
// expects 'metric' to include a format string and value
export function formatMetric(metric) {
  if (metric.value === -1 || metric.value == null || metric.value === '') {
    return '-';
  }

  switch (metric.format) {
    case 'two_decimal_float':
      return `${metric.value.toFixed(2)}`;
    case 'percent':
      return (metric.value * 100).toFixed(2) % 1 === 0
        ? `${(metric.value * 100).toFixed()}%`
        : `${(metric.value * 100).toFixed(2)}%`;
    case 'one_decimal_percent':
      return `${(metric.value * 100).toFixed(1)}%`;
    case 'currency':
      return `$${abbrLargeNumber(metric.value, false)}`;
    case 'datetime':
      return formatDatetime(metric.value) || '-';
    case 'duration':
      return formatDynamicDuration(metric.value) || '-';
    case 'number_with_commas':
      return formatNumberWithCommas(metric.value) || '-';
    case 'large_integer':
      return abbrLargeNumber(metric.value, false, 2, { maximumFractionDigits: 0 }) || '-';
    default: {
      return abbrLargeNumber(metric.value, false);
    }
  }
}

export function formatOptional(value, emptyValue = '-') {
  if (value === null) {
    return emptyValue;
  }
  return value;
}

export function formatOverallSentiment(sentiment) {
  if (!sentiment) {
    return { value: '-' };
  }
  const formattedSentiment = humps.camelizeKeys(sentiment);
  if (formattedSentiment.isPositive) {
    return { value: 'Positive', sentimentColor: colours.SUCCESS.SUCCESS_500 };
  }
  if (formattedSentiment.isNegative) {
    return { value: 'Negative', sentimentColor: colours.ERROR.ERROR_500 };
  }
  return { value: 'Neutral', sentimentColor: colours.ACTION.ACTION_500 };
}

export function formatPlatformCommentSentiment(platformType) {
  if (platformType.toLowerCase() === constants.TWITTER.toLowerCase()) {
    return 'Replies';
  }
  return 'Comments';
}

/**
 * Takes any two valid start and end date timestamps and converts them to a conjoined string
 * See https://day.js.org/docs/en/parse/parse for timestamp info
 *
 * @param startDate start date timestamp
 * @param endDate end date timestamp
 * @param dateFormat the date formatting the output should adhere to
 * @param separator string separating the two dates
 * @returns {string}
 */
export function formatDateRange(startDate, endDate, dateFormat, separator = '-') {
  const startFormatted = dayjs(startDate).format(dateFormat);
  const endFormatted = dayjs(endDate).format(dateFormat);

  return `${startFormatted}${separator}${endFormatted}`;
}

/**
 * Outputs a common sort parameter format used by APIs
 *
 * @param lowToHigh boolean indicating order, e.g. asc/desc
 * @param sortField field to sort by, e.g. TIMESTAMP
 * @returns {string}
 */
export function formatSortString(lowToHigh, sortField) {
  return `${lowToHigh ? '' : '-'}${sortField}`;
}

export function getDateOptionLabel(startDate, endDate) {
  if (!startDate && !endDate) {
    return 'All Time';
  }

  if (!startDate || !endDate) {
    return 'Custom';
  }

  const dateOptions = {
    1: 'Last 24 Hours',
    3: 'Last 3 Days',
    7: 'Last 7 Days',
    28: 'Last 4 Weeks',
  };

  const currentDate = dayjs().format('YYYY-MM-DD');
  const daysInterval = differenceBetweenDays(startDate, currentDate);
  return dateOptions[daysInterval] ?? 'Custom';
}

export function formatGraphDateLabels(dates, scale) {
  const targetDateFormat = scale === enumTypes.MONTHLY ? 'MMM' : 'MMM dd';
  return dates.map((label) => reformatDateString(label, 'yyyy-MM-dd', targetDateFormat));
}

export function formatTabName(name) {
  const expr = /like\s?shop\b/i;
  const tabName = name.replace(expr, 'LikeShop');

  switch (tabName.toLowerCase()) {
    case 'tiktok':
      return 'TikTok';
    case 'likeshop':
      return 'LikeShop';
    case 'instagram likeshop':
      return 'Instagram LikeShop';
    case 'tiktok likeshop':
      return 'TikTok LikeShop';
    case 'likeshop feed':
      return 'LikeShop Feed';
    case 'youtube':
      return 'YouTube';
    case 'ugc':
      return 'UGC';
    case 'ecomm':
      return 'Social Commerce';
    case 'all':
      return 'All Tabs';
    default:
      return startCase(tabName);
  }
}

export function formatTopPostMetrics(values, icons, tooltips) {
  return [
    {
      value: values[0],
      icon: icons[0],
      tooltip: tooltips[0],
      isPercent: false,
      minAbbrNumber: mostEntertainingMinAbbrNumber,
    },
    {
      value: values[1],
      icon: icons[1],
      tooltip: tooltips[1],
      isPercent: false,
      isDecimal: true,
    },
  ];
}

export function formatPercent(value) {
  if (value !== '-') {
    return numeral(value).format('0.[00]%');
  }
  return value;
}

export function formatVideoDurationInSeconds(duration, channel) {
  if (!duration) return undefined;
  if (channel === enumTypes.TWITTER) return duration / 1000;
  return duration;
}

export function formatVideoDuration(duration, channel) {
  if (!duration) return '--:--';
  if (channel === enumTypes.TWITTER) return formatTime(duration / 1000);
  return formatTime(duration);
}

export function formatVideoDurationUnit(duration, unit) {
  if (!duration) return '--:--';
  if (unit === durationUnits.MILLISECONDS) return formatTime(duration / 1000);
  return formatTime(duration);
}

export function formatChannelForPublicMediaRoute(channel) {
  return channel?.toLowerCase();
}

export function reduceNumber(value) {
  if (value >= 1e9 || value <= -1e9) {
    return [value / 1e9, 'B'];
  }
  if (value >= 1e6 || value <= -1e6) {
    return [value / 1e6, 'M'];
  }
  if (value >= 1000 || value <= -1000) {
    return [value / 1000, 'K'];
  }
  return [value, ''];
}

/**
 * Format number to adhere to style guide:
 * https://dashhudson.slab.com/posts/how-to-display-metric-names-and-values-fequr7ae#h5xfv-applicable-to-integers
 *
 * @param {number} value
 * @returns {string}
 */
export function scaleLabelFormatter(value) {
  const [reduced, suffix] = reduceNumber(value);
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: suffix === 'K' ? 1 : 2,
  });
  return `${formatter.format(reduced)}${suffix}`;
}

export const addCommas = (n) =>
  n.length === 0 || n.match(/[^0-9]/) ? n : numeral(n).format('0,0');

export const stripFormatting = (n) =>
  n.length === 0 || n.match(/[^0-9, ]/) || numeral(n).value() === null
    ? n
    : `${numeral(n).value()}`;

export function formatValueByFormatType(value, format, emptyValue) {
  let updatedFormat = format;
  if (format === '0a' && value > 1000) {
    updatedFormat = '0.00a';
  }
  switch (format) {
    case DURATION_FORMATS.HOURS:
      return formatDuration(value, 0, 'hour');
    case DURATION_FORMATS.HOURS_MINUTES:
      return formatDuration(value, 0, 'minute');
    case DURATION_FORMATS.HOURS_MINUTES_SECONDS:
      return formatDuration(value, value >= 60 ? 0 : 1);
    case DURATION_FORMATS.SHORTENED_YEARS_DAYS_HOURS_MINUTES_SECONDS:
      return formatDynamicDuration(value);
    default:
      return isNumber(value) ? numeral(value).format(updatedFormat).toUpperCase() : emptyValue;
  }
}

export function roundPercentages(entries) {
  /*
   * Method to round given percentage values so that their integer sum equals 100 when rounded
   *
   * @property entries {Object} Object containing the entries to be rounded. Keys are arbitrary,
   * values are decimal percentage values to be rounded
   * @returns {Object} Object containing the rounded entries
   */

  // If the sum of the values is 100, we don't need to round
  if (
    Object.values(entries).reduce(
      (accumulator, current) => Math.round(current * 100) + accumulator,
      0,
    ) === 100
  ) {
    return entries;
  }

  const formattedEntries = {};
  const decimals = {};

  Object.entries(entries).forEach(([key, value]) => {
    const roundedInteger = Math.floor(value * 100);
    formattedEntries[key] = roundedInteger;
    decimals[key] = value * 100 - roundedInteger;
  });

  // To get the most accurate results, we want to find the largest decimal values and
  // increment the corresponding number until we sum to 100
  for (let i = 0; i < Object.keys(decimals).length - 1; i += 1) {
    const largestDecimalKey = Object.entries(decimals).reduce((currentMaxKey, [key, value]) =>
      value > decimals[currentMaxKey] ? key : currentMaxKey,
    );
    formattedEntries[largestDecimalKey] += 1;
    decimals[largestDecimalKey] = 0;

    const sum = Object.values(formattedEntries).reduce(
      (accumulator, current) => accumulator + current,
      0,
    );

    if (sum === 100) {
      break;
    }
  }

  // Convert the rounded integers back to percentages
  Object.entries(formattedEntries).forEach(([key, value]) => {
    formattedEntries[key] = value / 100;
  });
  return formattedEntries;
}
