<template>
  <section>
    <div v-if="warningType === 'PROCESSING'" class="message-box">
      <Icon name="hourglass" height="30" width="30" />
      <h5>Pending</h5>
      <p>
        Videos require manual review & approval from Pinterest. This process may take up to 48 hours
        from the publishing time in Dash Social.
      </p>
    </div>
    <div v-if="warningType === 'FAILED'" class="message-box">
      <Icon name="alertCircle" height="30" width="30" color="colours.ERROR.ERROR_500" />
      <h5 class="warning-message">Video Pin Rejected</h5>
      <p>
        This video pin has been reviewed and rejected by Pinterest. Check out Pinterest's
        <a href="https://policy.pinterest.com/en/community-guidelines">community guidelines</a>
        to see why your pin was rejected.
      </p>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'VideoStatusOverlay',
  components: { Icon },
  props: { warningType: { type: String, default: 'PROCESSING' } },
  computed: {
    colours() {
      return colours;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  .message-box {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    display: inline-block;
    width: 20rem;
    background-color: rgb(255 255 255 / 70%);
    box-shadow: var(--shadow-1);
    border-radius: var(--round-corner-small);
    padding: var(--space-16);

    .svg-icon {
      margin-bottom: var(--space-4);
    }

    p {
      font-size: var(--x14);
      line-height: var(--space-16);
    }

    a {
      color: var(--action-500);
      font-weight: var(--font-medium);
      cursor: pointer;
    }
  }

  .warning-message {
    color: var(--error-500);
  }
}
</style>
