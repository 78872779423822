<template>
  <Popup
    :close="handleCloseLinkPopup"
    :close-confirmation="confirmation"
    class="media-links-container"
    :inline-styles="{
      width: 'fit-content',
      display: 'flex',
      padding: '0',
    }"
    show-when-drawer-open
  >
    <ProductPopup
      v-if="showProductPopup"
      :key="selectedIndex"
      @on-change="updateLinkWithProduct"
      @close="closeProductPopup"
    />
    <AddProductPopup
      v-if="showEditProductPopup && productToEditIndex !== null"
      :existing-product="links[productToEditIndex].product"
      :custom-save="handleProductEdited"
      :close="closeEditProductPopup"
    />
    <div class="flex">
      <div class="w-[60rem]">
        <div class="links-header">
          <div class="title">
            <Icon class="icon" name="link" />
            Links
          </div>
        </div>
        <div v-if="mediaLinksStore.pending.mediaLinks && !isSavingLinks" class="edit-content">
          <CircularLoader />
        </div>
        <div v-else class="edit-content">
          <div class="left-container">
            <MediaView
              :media-list="mediaList"
              :media-links="links"
              :link-limit="linkLimit"
              :show-link-view="true"
              :media-selected="mediaSelected"
              :enable-operation="false"
              @update-links="linksUpdated"
            />
          </div>

          <div id="linkEditorsContainer" class="right-container">
            <section class="link-editors">
              <div v-if="links.length === 0" class="empty-multi-link">
                Click on media to add link
              </div>
              <MediaLinkEditor
                v-for="(link, index) in links"
                ref="multiLinks"
                :key="index"
                :preset-link="link"
                @on-input="setIsEditing"
                @on-edit-product="openProductPopup"
                @on-change="updateLink"
                @on-remove="removeLink"
                @error-validation-changed="checkLinksInvalid"
                @open-edit-product-popup="openEditProductPopup"
                @utm-value-modified="(linkCustomized) => utmValueModified(linkCustomized, index)"
              />
            </section>
            <Button
              :disabled="isSavingLinks || disableSaveMultipleLinks"
              primary
              :loading="isSavingLinks"
              class="save-button"
              data-cy="links-save-button"
              @click="saveLinks"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div id="MediaLinksSidePanel" class="rounded-r bg-[--background-300]"></div>
    </div>
  </Popup>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import humps from 'humps';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { useProductStore } from '@/stores/product';
import {
  discardConfirmMessage,
  linkValidationMessages,
  constants as appConstants,
  mediaSourceType,
} from '@/config';
import Button from '@/components/foundation/Button.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import Icon from '@/components/foundation/Icon.vue';
import MediaLinkEditor from '@/components/MediaLinkEditor.vue';
import MediaView from '@/app/scheduler/components/EditPost/MediaViewer/MediaView.vue';
import Popup from '@/components/Popup.vue';
import { useCropperStore } from '@/stores/cropper';
import { useMediaLinksStore } from '@/stores/media-links';
import { useMediaDetailStore } from '@/stores/media-detail';
import AddProductPopup from '@/app/library/pages/AddProduct.vue';
import { productFeedType } from '@/utils/productFeed';
import { utmChannel } from '@/app/settings/components/Utm/const';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import enumTypes from '@/app/library/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaLinks',
  components: {
    Button,
    CircularLoader,
    Icon,
    MediaLinkEditor,
    MediaView,
    Popup,
    ProductPopup: defineAsyncComponent(
      () => import('@/app/library/components/MediaPopup/ProductPopup.vue'),
    ),
    AddProductPopup,
  },
  props: {
    context: { type: String, default: null },
    linkLimit: { type: Number, default: null },
    prefetchLinks: { type: Boolean, default: true },
  },
  data() {
    return {
      initialLinks: [],
      isEditing: false,
      isSavingLinks: false,
      linkErrorPresent: false,
      links: [],
      selectedIndex: 0,
      showProductPopup: false,
      showEditProductPopup: false,
      productToEditIndex: null,
      urlLinkCustomized: false,
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useCropperStore,
      useAuthStore,
      useMediaLinksStore,
      useMediaDetailStore,
      useProductStore,
      useTrackingStore,
    ),
    confirmation() {
      if (this.hasUnsavedChanges() && !this.isSavingLinks) {
        return discardConfirmMessage;
      }
      return null;
    },
    disableSaveMultipleLinks() {
      return (
        (this.links.length === 0 && this.mediaLinksStore.mediaLinks.length === 0) ||
        this.isEditing ||
        this.productStore.pending.urlMetadata ||
        this.productStore.pending.findProductByUrl ||
        this.linkErrorPresent
      );
    },
    mediaList() {
      return this.media ? [this.media] : [];
    },
    media() {
      return this.mediaLinksStore.mediaItem;
    },
    hasUtmSource() {
      return Boolean(this.mediaLinksStore.utmChannel);
    },
  },
  watch: {
    'mediaLinksStore.mediaLinks': {
      immediate: true,
      handler(to) {
        if (to) {
          this.links = this.formatMediaLinks(to);
          this.mediaLinksStore.editedMediaLinks = cloneDeep(this.links);
        }
      },
    },
    links(links) {
      if (!links.length) this.mediaLinksStore.closeUtmEditorPanel();
    },
  },
  async created() {
    if (this.media?.id && this.prefetchLinks) {
      await this.mediaLinksStore.listMediaLinks({
        brandId: this.authStore.currentBrand.id,
        mediaId: this.media.id,
      });
      this.initialLinks = cloneDeep(this.links);
    }

    this.mediaLinksStore.isCompetitiveMedia = enumTypes.COMPETITIVE_LIST.includes(
      this.media?.sourceType,
    );

    // Scheduler will set the utmChannel value for us, we don't want it being overriden
    if (
      !this.mediaLinksStore.utmChannel &&
      this.media?.sourceType !== mediaSourceType.INSTAGRAM_STORY
    ) {
      if (
        this.media?.source === CHANNELS.INSTAGRAM.value ||
        this.media?.sourceType.startsWith(CHANNELS.INSTAGRAM.value)
      ) {
        this.mediaLinksStore.isUgcMedia = enumTypes.UGC_LIST.includes(this.media.sourceType);
        this.mediaLinksStore.utmChannel = utmChannel.INSTAGRAM_LIKESHOP;
        this.mediaLinksStore.fetchUtmSettings();
      } else if (
        this.media?.source === CHANNELS.TIKTOK.value ||
        this.media?.sourceType.startsWith(CHANNELS.TIKTOK.value)
      ) {
        this.mediaLinksStore.isUgcMedia = enumTypes.UGC_LIST.includes(this.media.sourceType);
        this.mediaLinksStore.utmChannel = utmChannel.TIKTOK_LIKESHOP;
        this.mediaLinksStore.fetchUtmSettings();
      }
    }
  },
  unmounted() {
    this.mediaLinksStore.utmChannel = undefined;
    this.mediaLinksStore.utmTrackingData = null;
  },
  methods: {
    checkLinksInvalid() {
      let res = false;
      if (this.$refs.multiLinks) {
        this.$refs.multiLinks.forEach((link, index) => {
          if (index < this.links.length) {
            // to make sure we aren't checking references that existed before deletion
            res ||= link.hasLinkError;
          }
        });
      }
      this.linkErrorPresent = res;
    },
    linksUpdated(links) {
      this.links = links;
    },
    hasUnsavedChanges() {
      if (this.mediaLinksStore.mediaLinks.length === this.links.length) {
        let hasUnsavedLink = false;
        // if saved links and current links has same length, compare links one by one
        this.mediaLinksStore.mediaLinks.forEach((savedLink, index) => {
          const currentLink = this.links[index];
          if (
            savedLink.product.title !== currentLink.product?.title ||
            savedLink.product.imageUrl !== currentLink.product?.imageUrl ||
            savedLink.productUrlInput !== currentLink?.productUrlInput ||
            (savedLink.x && currentLink.position.x && savedLink.x !== currentLink.position.x) ||
            (savedLink.y && currentLink.position.y && savedLink.y !== currentLink.position.y)
          ) {
            hasUnsavedLink = true;
          }
        });
        return hasUnsavedLink;
      }
      // if saved links and current links has different length, definitely has unsaved link.
      return true;
    },
    updateLink(link, index) {
      if (index !== undefined) {
        this.links.splice(index, 1, { ...this.links[index], ...link });
      } else {
        this.links = [link];
      }
      if (
        this.hasUtmSource &&
        !this.mediaLinksStore.isUgcMedia &&
        !this.mediaLinksStore.isCompetitiveMedia
      ) {
        this.mediaLinksStore.openUtmEditorPanel(index + 1);
      }
    },
    updateLinkWithProduct(link, index) {
      this.links.splice(index, 1, {
        ...this.links[index],
        ...link,
        invalidUrl: false,
      });
      if (
        this.hasUtmSource &&
        !this.mediaLinksStore.isUgcMedia &&
        !this.mediaLinksStore.isCompetitiveMedia
      ) {
        this.mediaLinksStore.openUtmEditorPanel(index + 1);
      }
    },
    removeLink(index) {
      this.links.splice(index || 0, 1);
      this.isEditing = false;
      this.checkLinksInvalid();
    },
    setLinksSavingValue(value) {
      if (this.$refs.multiLinks) {
        this.$refs.multiLinks.forEach((link) => {
          link.saving = value;
        });
      }
    },
    async saveLinks() {
      if (this.hasUnsavedChanges()) {
        // Only bother saving if there are unsaved changes
        this.isSavingLinks = true;

        this.checkLinksInvalid();
        const mediaLinksData = this.links;
        const previousMediaLinks = this.mediaLinksStore.mediaLinks;

        if (this.disableSaveMultipleLinks) {
          this.isSavingLinks = false;
          return;
        }

        // filter out those empty links.
        const data = this.deformatMediaLinks(mediaLinksData).filter((item) => item.url);
        this.setLinksSavingValue(true);
        this.mediaLinksStore.setLinkUpdateSource(this.context);

        await this.mediaLinksStore.updateMediaLinks({
          brandId: this.authStore.currentBrand.id,
          mediaId: this.media.id,
          data,
        });

        if (this.mediaLinksStore.linkUpdateStatus === 'error') {
          this.notificationStore.setToast({
            message: linkValidationMessages.linkUpdateFailure,
            type: 'error',
          });
          this.setLinksSavingValue(false);
          // Return early so the link popup isn't closed.
          this.isSavingLinks = false;
          return;
        }

        const linksSavedData = {
          mediaId: this.media.id,
          link: data,
          brandId: this.authStore.currentBrand.id,
          'total links saved': data.length,
          page: this.$route.meta.analytics,
        };

        // handle mixpanel before data is cleared
        if (this.context === 'mediaPopup') {
          linksSavedData.component = 'Media Popup';
        } else if (this.context === 'schedulerPopup') {
          linksSavedData.component = 'Scheduler Popup';
        } else if (this.$route.name === 'tiktok.likeshop') {
          linksSavedData.component = 'TikTok LikeShop';
          linksSavedData.platform = appConstants.TIKTOK;
        } else if (this.$route.name === 'instagram.likeshop') {
          linksSavedData.component = 'Instagram LikeShop';
          linksSavedData.platform = appConstants.INSTAGRAM;
        }

        const initialLinksLength = this.initialLinks.length;
        const savedLinksLength = data.length;

        if (initialLinksLength > savedLinksLength) {
          // links removed
          linksSavedData.linksDeleted = initialLinksLength - savedLinksLength;
          linksSavedData.actionPerformed = 'Deleted';
        } else if (initialLinksLength < savedLinksLength) {
          // links added
          linksSavedData.linksAdded = savedLinksLength - initialLinksLength;
          linksSavedData.actionPerformed = 'Added';
        }

        linksSavedData.urlLinkCustomized = this.urlLinkCustomized;

        this.trackingStore.track('Links Saved', linksSavedData);
        this.sendProductLinkMixpanelEvents(previousMediaLinks, mediaLinksData);
        this.cropperStore.removeAdjustLinksPrompt();

        // If media popup is open and the media id is the same as the media links popup media id, call list media links before closing to update the links count icon in the media action buttons
        if (
          this.mediaDetailStore.showMediaPopup &&
          this.mediaDetailStore.mediaDetail.id === this.media.id
        ) {
          this.mediaLinksStore.listMediaLinks({
            brandId: this.authStore.currentBrand.id,
            mediaId: this.media.id,
          });
        }
      }
      this.isSavingLinks = false;
      this.mediaLinksStore.closeLinkPopup();
      this.mediaLinksStore.closeUtmEditorPanel();
    },
    handleCloseLinkPopup() {
      if (!this.isSavingLinks) {
        this.mediaLinksStore.closeLinkPopup();
        this.mediaLinksStore.closeUtmEditorPanel();
      }
    },
    openProductPopup(index) {
      this.selectedIndex = index;
      this.showProductPopup = true;
    },
    closeProductPopup() {
      this.showProductPopup = false;
    },
    formatMediaLinks(mediaLinksData) {
      // TODO Get rid of formatting eventually, just be consistent with server data
      const res = mediaLinksData.map((data) => ({
        id: data.id,
        position: {
          x: data.x,
          y: data.y,
        },
        product: humps.camelizeKeys(data.product),
      }));
      return res;
    },
    deformatMediaLinks(mediaLinksData) {
      // TODO Get rid of formatting eventually, just be consistent with server data
      const res = mediaLinksData.map((data) => {
        const inputUrl = data.productUrlInput ?? data.product.url;
        const queryParamsIndex = inputUrl.indexOf('?');

        let [url] = data.product.url.split('?');
        if (queryParamsIndex > 0) url = `${url}${inputUrl.slice(queryParamsIndex)}`;
        return {
          id: data.id,
          product_id: data.product.id,
          image_url: data.product.imageUrl,
          title: data.product.title,
          url,
          x: data.position.x,
          y: data.position.y,
          // hide media in PPW when add link through scheduler popup
          hide_in_widget: this.context === 'schedulerPopup',
        };
      });
      return res;
    },
    mediaSelected(media) {
      // TODO This does not work and might not be necessary
      this.mediaList = [media];
    },
    setIsEditing(val) {
      this.isEditing = val;
    },
    openEditProductPopup(index) {
      this.productToEditIndex = index;
      this.showEditProductPopup = true;
    },
    handleProductEdited(productUpdates) {
      this.links.splice(this.productToEditIndex, 1, {
        ...this.links[this.productToEditIndex],
        product: {
          ...this.links[this.productToEditIndex].product,
          ...productUpdates,
        },
      });
      this.closeEditProductPopup();
    },
    closeEditProductPopup() {
      this.productToEditIndex = null;
      this.showEditProductPopup = false;
    },
    doProductPropertiesMatch(propertyA, propertyB) {
      if (!propertyA && !propertyB) return true;

      return propertyA === propertyB;
    },
    sendProductLinkMixpanelEvents(existingLinks, savedLinks) {
      if (savedLinks.length === 0) return;

      const existingMediaLinkMap = {};

      const filteredLinks = savedLinks.filter((link) => link.product?.url);
      existingLinks.forEach((link) => {
        existingMediaLinkMap[link.product.url] = {
          title: link.product.title,
          imageUrl: link.product.image_url,
        };
      });

      filteredLinks.forEach((link) => {
        const { imageUrl, title, url, id } = link.product;

        const isNewProduct = !existingMediaLinkMap[url] && id == null;
        // If product already exists for the brand, it will have an ID
        if (isNewProduct) {
          this.trackingStore.track('Product Link Added', {
            product: {
              image_url: imageUrl,
              title,
              url,
            },
            productAddedContext: 'Media Links',
          });
          return;
        }

        const isUpdatedProduct =
          existingMediaLinkMap[url] &&
          !(
            this.doProductPropertiesMatch(existingMediaLinkMap[url].imageUrl, imageUrl) &&
            this.doProductPropertiesMatch(existingMediaLinkMap[url].title, title)
          );

        if (isUpdatedProduct) {
          this.trackingStore.track('Product Link Updated', {
            product: link,
            feedType: productFeedType(link.product),
            productCatalog: link.product?.productFeed?.productCatalog?.name ?? null,
            productEditContext: 'Media Links',
          });
        }
      });
    },
    utmValueModified(urlLinkCustomized, index) {
      this.urlLinkCustomized = urlLinkCustomized;
      this.links[index].urlLinkCustomized = urlLinkCustomized;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-links-container {
  .links-header {
    width: 100%;
    display: flex;
    padding: var(--space-24);
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    font-weight: var(--font-medium);
    font-size: var(--x18);

    .title {
      display: flex;

      svg {
        margin: 0 var(--space-8);
      }
    }
  }

  .edit-content {
    display: flex;
    max-height: 80vh;

    .left-container {
      width: 45%;
      border-radius: var(--round-corner) 0 0 var(--round-corner);
      text-align: center;
      background: var(--background-300);
      padding: var(--space-32);
    }

    .right-container {
      width: 55%;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: var(--space-32) var(--space-32) 0;

      section {
        width: 100%;
      }

      .save-button {
        min-width: 10rem;
        min-height: 2.5rem;
      }

      .link-editors {
        .link-editor-container {
          margin: 0 0 var(--space-32);
        }

        .empty-multi-link {
          height: 15rem;
          font-size: var(--x18);
          font-weight: var(--font-medium);
          color: var(--text-secondary);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    /* Fix for FireFox not taking into account padding/margins in a scroll element */
    .right-container::after {
      content: '';
      height: var(--space-32);
      flex-shrink: 0;
      display: block;
      width: 100%;
    }
  }
}

@media (max-width: 55rem) {
  .media-links-container {
    flex-direction: column;

    .left-container {
      border-radius: var(--round-corner) var(--round-corner) 0 0;
      width: 100%;
    }

    .right-container {
      border-radius: 0 0 var(--round-corner) var(--round-corner);
      width: 100%;
    }
  }
}
</style>
