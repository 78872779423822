import dayjs from 'dayjs';
import { UserEventTracker } from '@/utils/analytics/tracking';
import { TIME_UNIT_OPTIONS, UNIT_DISPLAY_OPTIONS, formatDynamicDuration } from '@/utils/formatters';
import { TEMPLATES } from './utils/templates.enum';

export class DashboardUserEventTracker extends UserEventTracker {
  constructor() {
    super();
    this.initialEventTriggerTime = null;
  }

  EVENT_NAMES = {
    ADD_REPORT_CLICK: 'Dashboard Add Report - Click',
    ADD_REPORT_TYPE_CLICKED: 'Dashboard Add Report - Report Type Clicked',
    CREATE_DASHBOARD_CLICK: 'Dashboard Create Dashboard - Click',
    CREATE_DASHBOARD_TEMPLATE_CLICKED: 'Dashboard Create Dashboard - Template Clicked',
    ADD_REPORT_STEPS: 'Dashboard Add Report - Steps',
    CLICKED_SEE_MORE: 'Dashboard See More Clicked',
  };

  addReportClick() {
    this.trackingStore.track(this.EVENT_NAMES.ADD_REPORT_CLICK, {});
  }

  addReportTypeClicked(reportType, reportFilter) {
    this.trackingStore.track(this.EVENT_NAMES.ADD_REPORT_TYPE_CLICKED, {
      reportType,
      reportTypeIndex: reportFilter,
    });
  }

  createDashboardClick() {
    this.trackingStore.track(this.EVENT_NAMES.CREATE_DASHBOARD_CLICK, {});
    this.initialEventTriggerTime = Date.now();
  }

  createDashboardTemplateClicked({ templateId }) {
    const startTime = dayjs(this.initialEventTriggerTime);
    const endTime = dayjs(Date.now());

    const eventData = {
      templateType: TEMPLATES[templateId].name,
      duration: formatDynamicDuration(
        endTime.diff(startTime),
        TIME_UNIT_OPTIONS.MILLISECOND,
        UNIT_DISPLAY_OPTIONS.SHORT,
      ),
    };

    this.trackingStore.track(this.EVENT_NAMES.CREATE_DASHBOARD_TEMPLATE_CLICKED, eventData);
  }

  addReportSteps({ stepNumber, stepLabel, stepTransition }) {
    const eventData = {
      stepNumber,
      stepLabel,
      stepTransition,
    };

    this.trackingStore.track(this.EVENT_NAMES.ADD_REPORT_STEPS, eventData);
  }

  seeMoreClicked(reportType, reportId, location) {
    this.trackingStore.track(this.EVENT_NAMES.CLICKED_SEE_MORE, {
      reportType,
      reportId,
      location,
    });
  }
}
