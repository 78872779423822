<script setup>
import { onMounted, computed, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useTiktokStore } from '@/stores/tiktok';
import CircularLoader from '@/components/CircularLoader.vue';
import {
  Menu,
  Panel,
  StatusChip,
  Icon,
  Form,
  FormItem,
  Select,
  Chip,
  Tooltip,
  ClipboardButton,
  Button,
  useToast,
} from '@dashhudson/dashing-ui';
import dayjs from 'dayjs';
import {
  sparkAdsCodeStatus,
  SparkAdsLearnMoreUrl,
  tooltips,
  SPARK_ADS_AUTHORIZATION_PERIOD_OPTIONS,
} from '@/app/creators/constants';
import { useRouter } from 'vue-router';
import startCase from 'lodash/startCase';

const authStore = useAuthStore();
const tiktokStore = useTiktokStore();

const router = useRouter();

const brandId = authStore.currentBrand.id;

const props = defineProps({
  mediaItem: {
    type: Object,
    default: null,
  },
});

const { addToast } = useToast({
  life: 5000,
});

const adAccounts = ref({});

const dropdownContent = ref({});

const multipleAccounts = ref(false);

const synced = ref(false);

const displayCode = ref('');

const requestSubmitted = ref(false);

const loading = computed(() => tiktokStore.pending.sparkAdsStatus);

const sparkAdData = computed(() => tiktokStore.sparkAdsStatus);

const timeZoneName = computed(() => authStore.identity?.time_zone_name);

const endDate = computed(() =>
  dayjs.utc(sparkAdData.value.authCodeEndTime).tz(timeZoneName.value).format('MMM D, h:mmA'),
);

const startDate = computed(() =>
  dayjs.utc(sparkAdData.value.authCodeStartTime).tz(timeZoneName.value).format('MMM D, h:mmA'),
);

const isEligible = computed(() => sparkAdData.value !== 'Not Eligible');

const authCodeDeleted = computed(
  () =>
    sparkAdData.value?.authCodeStatus === sparkAdsCodeStatus.DELETED ||
    sparkAdData.value?.authStatus === 'REJECTED',
);

const authCodeExpired = computed(
  () =>
    sparkAdData.value?.authCodeStatus === sparkAdsCodeStatus.EXPIRED ||
    dayjs.utc(sparkAdData.value.authCodeEndTime) < dayjs(),
);

const canRequest = computed(
  () => sparkAdData.value.videoId && !sparkAdData.value?.authStatus && !requestSubmitted.value,
);

const requested = computed(() => sparkAdData.value.videoId && sparkAdData.value?.authStatus);

const waitingResponse = computed(
  () =>
    (sparkAdData.value?.authStatus === 'WAITING' && !sparkAdData.value?.authCode) ||
    requestSubmitted.value,
);

const statusChipParams = computed(() => {
  if (sparkAdData.value.authCodeStatus) {
    if (authCodeDeleted.value) {
      return { label: 'Rejected', severity: 'warning' };
    }
    if (authCodeExpired.value) {
      return { label: 'Expired', severity: 'warning' };
    }
    if (sparkAdData.value.authCodeStatus === sparkAdsCodeStatus.IN_USE || synced.value) {
      return { label: 'In Use', severity: 'positive' };
    }
    if (sparkAdData.value.authCodeStatus === sparkAdsCodeStatus.NOT_USED) {
      return { label: 'Not Used', severity: 'statusNeutral' };
    }
  }
  if (sparkAdData.value?.authStatus === 'ACCEPTED') {
    return { label: 'Accepted', severity: 'positive' };
  }
  return {
    label: waitingResponse.value
      ? 'Waiting'
      : startCase(sparkAdData.value.authStatus.toLowerCase()),
    severity: waitingResponse.value ? 'statusNeutral' : 'neutral',
  };
});

const requestAuthorizationPending = ref(false);

const formData = ref({
  authorizationPeriod: 30,
});

async function fetchSparkAdsStatus() {
  await tiktokStore.getSparkAdsStatus({
    brandId,
    sourceVideoId: props?.mediaItem?.postSourceId,
  });
}

async function syncToAdAccount(sourceAdvertiserId = null) {
  await tiktokStore.postSyncToAdAccount({
    brandId,
    authCode: sparkAdData.value.authCode,
    sourceAdvertiserId: sourceAdvertiserId || adAccounts.value[brandId][0]?.sourceAdvertiserId,
  });
  if (tiktokStore.error.sparkAdsSyncStatus) {
    addToast({
      severity: 'error',
      summary: 'Sync Failed',
      message: 'Something went wrong, please try again',
    });
  } else {
    addToast({
      severity: 'success',
      summary: 'Content Authorization Synced',
      message: 'Log in to your TikTok Ads Manager to create your Spark Ad',
    });
    // We know it succeeded (responded 200), but would need to wait 2seconds before fetching data
    // from TikTok to update this, so instead we just update the ui to avoid another setTimeout
    synced.value = true;
  }
}

async function connectAccount() {
  router.push({
    name: 'settings.socialPlatform',
  });
}

async function fetchAdAccounts() {
  adAccounts.value = await tiktokStore.getAdAccounts({ brandIds: [brandId] });

  dropdownContent.value = adAccounts.value[brandId].map((account) => ({
    label: account.name,
    icon: 'status-change',
    command: () => syncToAdAccount(account.sourceAdvertiserId),
  }));
  dropdownContent.value.push({
    label: 'Manage Accounts',
    icon: 'settings',
    command: connectAccount,
  });

  multipleAccounts.value = adAccounts.value[brandId].length > 1;
}

async function requestAuthorization() {
  requestAuthorizationPending.value = true;
  await tiktokStore
    .requestSparkAdsAuthorization({
      brandId: authStore.currentBrand.id,
      sourceVideoId: props?.mediaItem?.postSourceId,
      authorizationDays: formData.value.authorizationPeriod,
    })
    .then(() => {
      requestAuthorizationPending.value = false;
      addToast({
        severity: 'success',
        message: 'Request sent.',
      });
      requestSubmitted.value = true;
    })
    .catch((error) => {
      requestAuthorizationPending.value = false;
      addToast({
        severity: 'error',
        message:
          error?.response?.status === 404
            ? 'Please connect TikTok Ads'
            : 'Something went wrong, please try again.',
      });
    });
}

onMounted(async () => {
  await fetchAdAccounts();
  await fetchSparkAdsStatus();

  const firstFive = sparkAdData.value.authCode?.slice(0, 5);
  const lastFive = sparkAdData.value.authCode?.slice(-5);
  displayCode.value = `${firstFive}...${lastFive}`;
});
</script>

<template>
  <div class="dash-w-full dash-text-left">
    <Panel
      title="TikTok Spark Ad Authorization"
      channel="TIKTOK"
      :tooltip="tooltips?.tiktokSparkAdsHeader"
    >
      <template v-if="isEligible && !loading && (requested || waitingResponse)" #titleAppendRight>
        <Chip
          v-if="sparkAdData.authCodeStatus && !authCodeDeleted"
          :badge="{ severity: statusChipParams.severity }"
          :label="statusChipParams.label"
          size="md-wide"
        >
          <template #right>
            <Chip label="Accepted" size="sm" severity="success" />
          </template>
        </Chip>
        <StatusChip v-else :label="statusChipParams.label" :severity="statusChipParams.severity" />
      </template>
      <template #default>
        <div v-if="loading">
          <CircularLoader />
        </div>
        <div v-else-if="!isEligible" class="dash-p-6 dash-text-center">
          <Icon name="info-circle" size="24" />
          <p class="learn-more">
            Spark Ad authorization isn't available for this post because it's not linked to a
            Campaign via Invite Link.
            <a class="learn-more-link" :href="SparkAdsLearnMoreUrl" target="_blank" rel="noopener"
              >Learn More
            </a>
          </p>
        </div>
        <div v-else-if="canRequest" class="dash-flex dash-flex-col dash-gap-6">
          <p class="learn-more dash-text-center">
            Select the authorization period to request from the creator to run this content as a
            Spark Ad.
            <a class="learn-more-link" :href="SparkAdsLearnMoreUrl" target="_blank" rel="noopener"
              >Learn More
            </a>
          </p>
          <div class="dash-flex dash-flex-row dash-justify-between dash-items-end">
            <Form v-model="formData" class="dash-w-1/2">
              <FormItem
                v-slot="{ inputProps, inputEvents }"
                name="authorizationPeriod"
                label="Authorization Period"
                required
              >
                <Select
                  v-bind="inputProps"
                  :options="SPARK_ADS_AUTHORIZATION_PERIOD_OPTIONS"
                  v-on="inputEvents"
                />
              </FormItem>
            </Form>
            <Button
              severity="primary"
              label="Request"
              :loading="requestAuthorizationPending"
              @click="requestAuthorization"
            />
          </div>
        </div>
        <div v-else-if="waitingResponse">
          <p>TikTok Spark Ad permission requested. Awaiting creator's response.</p>
        </div>
        <div v-else-if="authCodeDeleted">
          <p>
            The Spark Ads Authorization has been deleted by this creator. To boost this content, the
            creator can generate a new authorization code through the TikTok app.
          </p>
        </div>
        <div v-else-if="authCodeExpired" class="flex flex-col gap-2">
          <p>
            The authorization period is in the past. Contact {{ mediaItem.userName }} directly if
            you’d like to extend it.
          </p>
          <div v-if="sparkAdData.authCode" class="flex flex-row items-center gap-1">
            From
            <Chip :label="startDate" />
            To
            <Chip :label="endDate" />
          </div>
        </div>
        <div v-else class="flex flex-col gap-2">
          <span class="block text-left">
            {{ mediaItem.userName }} has authorized the Spark Ad request for the following date
            period:
          </span>
          <div v-if="sparkAdData.authCode" class="flex flex-row items-center gap-1">
            From
            <Chip :label="startDate" />
            To
            <Chip :label="endDate" />
          </div>

          <div class="mt-4 flex flex-row flex-wrap items-end justify-between gap-1">
            <div class="flex flex-col gap-2">
              <div class="dash-typo-lg-semibold flex flex-row items-center gap-1">
                Authorization Code <Tooltip :tooltip="tooltips.tiktokSparkAdsAuthCode" />
              </div>
              <div v-if="sparkAdData.authCode" class="flex flex-row items-center gap-2">
                {{ displayCode }}
                <ClipboardButton
                  :value="sparkAdData.authCode"
                  severity="tertiary"
                  @click="
                    addToast({
                      severity: 'success',
                      message: 'Code copied',
                    })
                  "
                />
              </div>
              <div v-else>Generating</div>
            </div>
            <Menu
              v-if="multipleAccounts"
              :disabled="false"
              :disable-hover="false"
              icon="status-change"
              size="sm"
              :model="dropdownContent"
            >
              <Button
                severity="primary"
                size="lg"
                label="Sync to Ad Account"
                icon-right="status-change"
              />
            </Menu>
            <Button
              v-else-if="adAccounts?.[brandId]?.length"
              severity="primary"
              size="lg"
              label="Sync to Ad Account"
              icon-right="status-change"
              @click="syncToAdAccount()"
            />
            <Button
              v-else
              severity="primary"
              size="lg"
              label="Connect Ad Account"
              icon-right="arrow-right"
              @click="connectAccount()"
            >
            </Button>
          </div>
        </div>
      </template>
    </Panel>
  </div>
</template>
