import cloneDeep from 'lodash/cloneDeep';

class ModuleNode {
  constructor() {
    this.parent = null;
    this.children = [];

    this.moduleName = null;
    this.label = null;
    this.permission = null;
    this.restricted = false;
    this.brandPermission = null;
  }
}

export const approvalsPermissionEnum = {
  CAN_REVIEW_SCHEDULED_POST: 'can_review_scheduled_post',
  REQUIRE_APPROVALS_TO_POST: 'require_approvals_to_post',
  SCHEDULER_NUM_OF_APPROVALS_REQUIRED: 'scheduler_num_of_approvals_required',
};

export const isApprovalPermission = (module) => {
  return [
    approvalsPermissionEnum.CAN_REVIEW_SCHEDULED_POST,
    approvalsPermissionEnum.REQUIRE_APPROVALS_TO_POST,
    approvalsPermissionEnum.SCHEDULER_NUM_OF_APPROVALS_REQUIRED,
  ].includes(module.permission);
};

const nonBooleanDefaultUserPermissions = {
  [approvalsPermissionEnum.SCHEDULER_NUM_OF_APPROVALS_REQUIRED]: 1,
};

export const permissionDefault = (permission, defaultBoolean) =>
  nonBooleanDefaultUserPermissions?.[permission] || defaultBoolean;

export const invalidApprovalsNumErr = 'The number of approvals must be between 1 and 15.';

// Note: If a new module or permission being added is part of an in-dev feature, make sure to either
// remove it before it gets merged upstream into master, or consider disabling it based on a feature
// flag in src/components/UserPermissions/UserPermissions.vue. Otherwise the permission will leak
// into the production app.
export const userPermissionsTemplate = [
  {
    moduleName: 'TikTok',
    label: 'Product Access',
    permission: 'can_access_tiktok_insights',
    children: [
      {
        label: 'LikeShop',
        permission: 'can_access_tiktok_likeshop',
      },
      {
        label: 'Manage TikTok Ads',
        permission: 'can_manage_tiktok_ads',
        requiredFeatureFlag: 'tiktokAdsConnection',
      },
    ],
  },
  {
    moduleName: 'Instagram',
    label: 'Product Access',
    permission: 'can_access_instagram',
    children: [
      { label: 'Stories', permission: 'can_access_stories' },
      { label: 'Visual IQ', permission: 'can_access_viq' },
      { label: 'Boards', permission: 'can_access_boards' },
      {
        label: 'LikeShop',
        permission: 'can_access_likeshop',
        children: [
          {
            label: 'Email Capture',
            permission: 'can_access_likeshop_email_capture',
          },
        ],
      },
      {
        // TODO: remove this item and requiredFeatureFlagToHide in UserPermissions.vue if not used when removing feature flag adAccountConnection in ticket 106472
        label: 'Manage Ads Account',
        permission: 'can_access_fb_ad_accounts_manager',
        requiredFeatureFlagToHide: 'adAccountConnection',
      },
    ],
  },
  {
    moduleName: 'Facebook',
    label: 'Product Access',
    permission: 'can_access_fb_insights',
    children: [
      {
        label: 'Manage Meta Ads',
        permission: 'can_access_fb_ad_accounts_manager',
        requiredFeatureFlag: 'adAccountConnection',
      },
    ],
  },
  {
    moduleName: 'Pinterest',
    label: 'Product Access',
    permission: 'can_access_pint',
    children: [{ label: 'DH Boards', permission: 'can_access_pint_boards' }],
  },
  {
    moduleName: 'X',
    label: 'Product Access',
    permission: 'can_access_twitter_insights',
    children: [],
  },
  {
    moduleName: 'LinkedIn',
    label: 'Product Access',
    permission: 'can_access_linkedin_insights',
    children: [],
  },
  {
    moduleName: 'Threads',
    label: 'Product Access',
    permission: 'can_access_threads_insights',
    children: [],
  },
  {
    moduleName: 'Scheduler',
    label: 'Product Access',
    permission: 'can_access_scheduler',
    children: [
      {
        label: 'TikTok',
        permission: 'can_access_scheduler_tiktok',
        children: [
          {
            label: 'TikTok Auto Publish',
            module: 'scheduler',
            permission: 'can_auto_publish_tiktok',
          },
        ],
      },
      {
        label: 'Instagram',
        permission: 'can_access_scheduler_ig',
        children: [
          {
            label: 'Instagram Auto Publish',
            module: 'scheduler',
            permission: 'can_auto_publish_instagram',
          },
        ],
      },
      {
        label: 'Facebook',
        permission: 'can_access_scheduler_fb',
        children: [{ label: 'Facebook Auto Publish', permission: 'can_auto_publish_facebook' }],
      },
      {
        label: 'Pinterest',
        permission: 'can_access_scheduler_pint',
        children: [
          {
            label: 'Pinterest Auto Publish',
            module: 'scheduler',
            permission: 'can_auto_publish_pinterest',
          },
        ],
      },
      {
        label: 'X',
        permission: 'can_access_scheduler_twit',
        children: [
          {
            label: 'X Auto Publish',
            module: 'scheduler',
            permission: 'can_auto_publish_twit',
          },
        ],
      },
      {
        label: 'LinkedIn',
        permission: 'can_access_scheduler_linkedin',
        requiredFeatureFlag: 'linkedinAutoPublishingWeb',
        children: [
          {
            label: 'LinkedIn Auto Publish',
            module: 'scheduler',
            permission: 'can_auto_publish_linkedin',
          },
        ],
      },
    ],
  },
  {
    moduleName: 'Approvals',
    label: 'Product Access',
    brandPermission: 'can_access_scheduler_approvals',
    children: [
      {
        label: 'Can approve posts',
        description: 'Manage who can approve posts',
        permission: 'can_review_scheduled_post',
      },
      {
        label: 'Requires approval to post',
        description: 'Set the required number of approvals to publish a post',
        permission: 'require_approvals_to_post',
        children: [
          {
            label: 'Number of Approvals Required',
            permission: 'scheduler_num_of_approvals_required',
          },
        ],
      },
    ],
  },
  {
    moduleName: 'Campaigns',
    label: 'Product Access',
    permission: 'can_access_campaigns',
    children: [
      {
        label: 'Google Analytics',
        permission: 'can_access_campaigns_google_analytics',
      },
    ],
  },
  {
    moduleName: 'Community',
    label: 'Product Access',
    permission: 'can_access_community',
    children: [
      {
        label: 'Instagram Direct',
        permission: 'can_access_instagram_direct',
      },
      {
        label: 'Instagram Comments',
        permission: 'can_access_instagram_comments',
      },
      {
        label: 'TikTok Comments',
        permission: 'can_access_tiktok_comments',
      },
      {
        label: 'Facebook Messenger',
        permission: 'can_access_facebook_messenger',
      },
      {
        label: 'Facebook Comments',
        permission: 'can_access_facebook_comments',
      },
      {
        label: 'X Mentions',
        permission: 'can_access_twitter_mentions',
      },
      {
        label: 'X Direct',
        permission: 'can_access_twitter_direct',
      },
      {
        label: 'Youtube Comments',
        permission: 'can_access_youtube_comments',
      },
    ],
  },
  {
    moduleName: 'Competitors',
    label: 'Product Access',
    permission: 'can_access_competitors',
    children: [],
  },
  {
    moduleName: 'Relationships',
    label: 'Product Access',
    permission: 'can_access_instagram_relationships',
    children: [
      {
        label: 'Manage TikTok Creator Marketplace',
        permission: 'can_manage_tiktok_creator_marketplace',
        requiredFeatureFlag: 'tiktokCreatorMarketplaceConnection',
      },
    ],
  },
  {
    moduleName: 'UGC',
    label: 'Product Access',
    description: 'Control access to UGC features throughout the platform',
    permission: 'can_access_ugc_more',
    children: [{ label: 'Instagram Content Rights', permission: 'can_access_rights_requests' }],
  },
  {
    moduleName: 'YouTube',
    label: 'Product Access',
    permission: 'can_access_youtube_insights',
    children: [],
  },
  {
    moduleName: 'Library',
    label: 'Approved Publishing Dates',
    description: 'Control access to edit which dates select media can be published',
    permission: 'can_edit_publish_dates',
    brandPermission: 'can_access_publishing_dates_and_bulk_uploading',
    requiredFeatureFlag: 'assetManagementGA',
    children: [],
  },
  {
    moduleName: 'Settings',
    requiredFeatureFlag: 'utmSettings',
    children: [
      {
        label: 'UTMs',
        description: 'Control access to edit the default UTMs',
        permission: 'can_access_utm_settings',
      },
    ],
  },
];

function buildNode(data) {
  const node = new ModuleNode();
  node.label = data.label;
  node.description = data.description;
  node.permission = data.permission;
  node.requiredFeatureFlag = data.requiredFeatureFlag;
  node.requiredFeatureFlagToHide = data.requiredFeatureFlagToHide;

  // The brand permission often has the same name as the user permission,
  // so default to that if the brand permission isn't specifically defined.
  // Use for whether to disable setting user permissions.
  node.brandPermission = data.brandPermission ?? data.permission;

  if (!data.children) {
    return node;
  }

  data.children.forEach((cData) => {
    const childNode = buildNode(cData);
    childNode.parent = node;
    node.children.push(childNode);
  });

  return node;
}

function buildModules() {
  const modules = [];

  userPermissionsTemplate.forEach((item) => {
    const node = buildNode(item);
    node.moduleName = item.moduleName;
    modules.push(node);
  });

  return modules;
}

// If the permission is turned off at the level of brands:
// 1) set the perm as restricted
// 2) set the perm value as False
function applyBrandConstraints(
  permsModules,
  userPermissions,
  brandPermissions,
  parentRestricted = false,
) {
  const modules = [];
  let userPerms = { ...userPermissions };

  permsModules.forEach((module) => {
    const cloneModule = cloneDeep(module);
    if (
      (module.brandPermission && brandPermissions[module.brandPermission] === false) ||
      parentRestricted
    ) {
      cloneModule.restricted = true;
      userPerms[module.permission] = permissionDefault(module.permission, false);
    }

    if (module.children.length !== 0) {
      const result = applyBrandConstraints(
        module.children,
        {},
        brandPermissions,
        cloneModule.restricted,
      );
      cloneModule.children = result.modules;
      userPerms = { ...userPerms, ...result.userPerms };
    }

    modules.push(cloneModule);
  });
  return { modules, userPerms };
}

function restoreAdminPermissions(permsModules, brandPermissions, oldPermissions = {}) {
  let restoredPermissions = {};

  permsModules.forEach((module) => {
    // align with the brand permissions.
    if (module.permission && module.permission in brandPermissions) {
      restoredPermissions[module.permission] = brandPermissions[module.permission];
    }
    // if the perm is restricted, set as false.
    else if (module.restricted) {
      restoredPermissions[module.permission] = false;
    } else if (module.permission === approvalsPermissionEnum.REQUIRE_APPROVALS_TO_POST) {
      restoredPermissions[module.permission] = false;
    } else {
      restoredPermissions[module.permission] = true;
    }

    if (
      module.children.length !== 0 &&
      module.permission !== approvalsPermissionEnum.REQUIRE_APPROVALS_TO_POST
    ) {
      const childRestoredPerms = restoreAdminPermissions(module.children, brandPermissions);
      restoredPermissions = { ...restoredPermissions, ...childRestoredPerms };
    }
  });

  const result = { ...oldPermissions, ...restoredPermissions };
  return result;
}

const userPermissionsModules = Object.freeze(buildModules());

export const deepPickPermissions = (moduleName) => {
  const permissions = [];
  const pushPermissions = (node) => {
    permissions.push(node.permission);
    if (node.children) {
      node.children.forEach((child) => pushPermissions(child));
    }
  };
  pushPermissions(userPermissionsTemplate.find((m) => m.moduleName === moduleName));
  return permissions;
};

function extractVisibleBrandUserPermissions() {
  const visiblePermissions = [];
  const extractChildPermissions = (node) => {
    if (!visiblePermissions.includes(node.permission)) {
      visiblePermissions.push(node.permission);
    }
    if (node.children.length !== 0) {
      node.children.forEach((child) => extractChildPermissions(child));
    }
  };

  userPermissionsModules.forEach((parentNode) => extractChildPermissions(parentNode));

  return visiblePermissions;
}

export const flattenedVisibleBrandUserPermissions = Object.freeze(
  extractVisibleBrandUserPermissions(),
);

function flattenVisibleBrandUserPermissions(permissions) {
  const visibleBrandUserPermissions = {};

  Object.entries(permissions).forEach(([permKey, permValue]) => {
    if (flattenedVisibleBrandUserPermissions.includes(permKey)) {
      visibleBrandUserPermissions[permKey] = permValue;
    }
  });

  return visibleBrandUserPermissions;
}

export {
  applyBrandConstraints,
  flattenVisibleBrandUserPermissions,
  restoreAdminPermissions,
  userPermissionsModules,
};
