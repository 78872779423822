<script setup>
import { computed, ref } from 'vue';
import EmbedPreviewMediaView from '@/app/library/components/MediaPopup/EmbedPreviewMediaView.vue';

defineOptions({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
});

const props = defineProps({
  mediaItem: {
    type: Object,
    required: true,
  },
});

const iframeStyles = ref({
  width: '100%',
  height: '845px',
});

const srcUrl = computed(() => `${props.mediaItem.postUrl}/embed`);
</script>

<template>
  <EmbedPreviewMediaView
    :src-url="srcUrl"
    :styles="iframeStyles"
    :iframe-attributes="{ scrolling: 'no' }"
  />
</template>
