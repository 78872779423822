import humps from 'humps';
import { UPLOAD_STATUS, SOURCE_TO_METRIC_PROP } from '@/config';
import { formatTime } from '@/utils/formatters';
import enumTypes from '@/app/library/constants';
import { axios } from '@/apis/library';

export function getMediaTypeFromDetail(mediaDetail) {
  let mediaType = 'media';
  if (mediaDetail) {
    if (mediaDetail.mediaType === 'VIDEO') {
      mediaType = 'video';
    }
    if (mediaDetail.mediaType === 'IMAGE') {
      mediaType = 'photo';
    }
  }
  return mediaType;
}

// Following two functions are identical should remove
export function getImageDataFromMedia(media) {
  // Uploaded to scheduler popup (MediaV2)
  if (media.fullMediaObject) {
    return media.fullMediaObject.sizes.originalConverted || media.fullMediaObject.sizes.original;
  }
  // everything else MediaV2
  return media.sizes.originalConverted || media.sizes.original;
}

export function getVideoDataFromMedia(media) {
  // Uploaded to scheduler popup (MediaV2)
  if (media.fullMediaObject) {
    return media.fullMediaObject.sizes.originalConverted || media.fullMediaObject.sizes.original;
  }
  // everything else MediaV2
  return media.sizes?.originalConverted || media.sizes?.original || null;
}

export function mediaIsUploading(media) {
  return (
    Boolean(media?.uploadStatus) &&
    media?.uploadStatus !== UPLOAD_STATUS.SUCCESS &&
    media?.uploadStatus !== UPLOAD_STATUS.PROCESSING
  );
}

export async function getVideoDuration(media, brandId) {
  let videoDuration = null;
  // Only videos selected from the global popup will have a value for duration.
  // Otherwise we need to call library to get that value.
  if (media.duration === undefined || media.duration == null) {
    const res = await axios.get(`/brands/${brandId}/media/${media.id}`);
    videoDuration = res.data.duration;
  } else {
    videoDuration = media.duration;
  }
  return videoDuration;
}

export function mediaSizeIsCroppable(media) {
  if (media.type === 'IMAGE') {
    const mediaObject = media.fullMediaObject || media;
    const imageWidth = mediaObject.sizes.originalConverted
      ? mediaObject.sizes.originalConverted.width
      : mediaObject.sizes.original.width;
    const imageHeight = mediaObject.sizes.originalConverted
      ? mediaObject.sizes.originalConverted.height
      : mediaObject.sizes.original.height;

    // If an image is larger than 64 megapixels, the cropping tools break. Temporary solution is to
    // use hide the cropping tools. TODO: Revisit this approach when we have a better image API in place
    return imageWidth * imageHeight < 64000000;
  }
  // TODO: if future media size validation is decided upon, it can exist here. For now return
  // true if the media type is video.
  return media.type === 'VIDEO';
}

export function getMediaSourceKey(media) {
  return SOURCE_TO_METRIC_PROP[media?.source] || media?.source?.toLowerCase();
}

function getMediaSourceData(media) {
  const sourceKey = getMediaSourceKey(media);
  return media?.[sourceKey];
}

export function isMediaVideo(media) {
  const isVideoMedia = media?.type === 'VIDEO' || media?.mediaType === 'VIDEO';
  const isPinterestVideo = media?.pinterest?.is_video;
  const isTikTokVideo = media?.tiktok;
  const isYouTubeVideo = media?.youtube;
  const isFacebookVideo = media?.facebook?.type.toUpperCase() === 'VIDEO';
  return isVideoMedia || isPinterestVideo || isTikTokVideo || isYouTubeVideo || isFacebookVideo;
}

export function isMediaLink(media) {
  return (
    enumTypes.FACEBOOK_LINK_LIST.includes(media?.source) ||
    enumTypes.TWITTER_LINK_LIST.includes(media?.source)
  );
}

export function isMediaReel(media) {
  return media?.instagram?.postType?.toLowerCase() === 'reel';
}

function isMediaCarousel(media) {
  if (media?.instagram) {
    return media?.instagram?.isCarousel;
  }

  const sourceData = getMediaSourceData(media);
  if (sourceData?.twitter?.additionalMediaUrls) {
    return sourceData.twitter.additionalMediaUrls !== '';
  }
  return sourceData?.type?.toLowerCase() === 'carousel';
}

function getMediaImageUrl(media) {
  const isVideo = isMediaVideo(media);
  const isPinterestVideo = media?.pinterest?.isVideo;
  let mediaImages;

  if (isPinterestVideo) {
    mediaImages = media?.image?.sizes;
  } else {
    mediaImages = isVideo ? media?.video?.thumbnails : media?.image?.sizes;
  }
  return humps.camelizeKeys(mediaImages)?.smallSquare?.url;
}

function getMediaPostUrl(media) {
  const source = media?.source;
  const sourceData = media?.sourceData;
  if (source === 'PINTEREST') {
    return sourceData?.link;
  }
  if (source === 'TWITTER') {
    return sourceData?.permalinkUrl;
  }
  if (source === 'TIKTOK') {
    return sourceData?.shareUrl;
  }
  return sourceData?.url;
}

export function getMediaVideoDuration(media) {
  return (
    media?.video?.duration ||
    media?.tiktok?.duration ||
    media?.youtube?.duration ||
    media?.videoDuration ||
    null
  );
}

export function formatMediaVideoDuration(media) {
  // default duration string placeholder is '--:--'
  let durationString = '--:--';
  const isPinterestVideo = media?.pinterest?.isVideo ?? media?.pinterest?.is_video;
  const videoDuration = getMediaVideoDuration(media);
  if (isPinterestVideo) {
    // since pinterest doesn't give us the video file itself or duration data,
    // simply leave the duration string out
    durationString = '';
  } else if (videoDuration) {
    durationString = formatTime(videoDuration);
  }
  return durationString;
}

export function normalizeMedia(media) {
  const isVideo = isMediaVideo(media);
  const isCarousel = isMediaCarousel(media);
  const isLink = isMediaLink(media);
  const isReel = isMediaReel(media);
  const imageUrl = getMediaImageUrl(media);
  const postUrl = getMediaPostUrl(media);
  const videoDuration = getMediaVideoDuration(media);
  return {
    id: media?.id,
    isVideo,
    isCarousel,
    isLink,
    isReel,
    imageUrl,
    postUrl,
    videoDuration,
  };
}

export function checkTweetMediaIsOriginal(tweet) {
  let itemInsight;
  if (tweet?.twitter) {
    itemInsight = tweet?.twitter;
  } else {
    itemInsight = tweet?.insights?.twitter || tweet?.insights;
  }
  return !(
    itemInsight?.inReplyToUserId ||
    itemInsight?.inReplyToStatusId ||
    itemInsight?.retweetedStatusId ||
    itemInsight?.quotedStatusId
  );
}

export function getMetricValue(media, metricAliasMap) {
  const sourceData = getMediaSourceData(media);
  const channel = media.source === 'INSTAGRAM_STORY' ? media.source : media.source.split('_')[0];
  const metric = metricAliasMap[channel];
  return sourceData?.[metric];
}
