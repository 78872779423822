import axios from 'axios';
import humps from 'humps';
import cloneDeep from 'lodash/cloneDeep';
import isBoolean from 'lodash/isBoolean';
import { isRef, nextTick } from 'vue';

import { TOKEN_KEY } from '@/config';
import { useIdentityStore } from '@/stores/identity';
import { IMPERSONATED_USER_ID_KEY, useImpersonatorStore } from '@/stores/impersonator';
import {
  browserStorageGetItem,
  browserStorageGetSessionItem,
  browserStorageRemoveItem,
} from '@/utils/browserStorage';
import { createLazyInstance } from '@/utils/lazy';

function logout() {
  browserStorageRemoveItem(TOKEN_KEY);
  useIdentityStore().clearCurrentBrand();
  useIdentityStore().clearIdentity();
  useImpersonatorStore().clearImpersonatedUser();
  nextTick(() => {
    window.location.assign(`/logout`);
  });
}

function redirectIfBadToken(error) {
  const { status, data } = error.response;
  if (status === 401) {
    // Checking if status is 401 should be enough to determine if we need to redirect to login,
    // but just in case, we check the error message to ensure we're only redirecting when token
    // is invalid or expired.
    const tokenErrors = [
      'Authentication token missing or invalid.',
      'Authentication token invalid or expired.', // Deprecated
      'Token Expired.', // Deprecated
      'Invalid Token.', // Deprecated
    ];
    if (tokenErrors.indexOf(data.description) > -1) {
      logout();
    }
  }
}

function createAxios(
  baseURL,
  options = {
    includeAuth: true,
    camelizeKeys: false,
    decamelizeRequests: true,
    decamelizeIgnoreKeys: null,
  },
) {
  const axOptions = cloneDeep(options);
  axOptions.includeAuth = options.includeAuth === undefined ? true : options.includeAuth;
  axOptions.camelizeKeys = options.camelizeKeys === undefined ? false : options.camelizeKeys;
  axOptions.decamelizeRequests =
    options.decamelizeRequests === undefined ? true : options.decamelizeRequests;
  axOptions.decamelizeIgnoreKeys = options.decamelizeIgnoreKeys;
  const ax = axios.create({ baseURL });
  ax.interceptors.request.use(
    (axConfig) => {
      const { includeAuth } = axOptions;
      const decamelizeKeys = isBoolean(axConfig?.decamelizeRequests)
        ? axConfig?.decamelizeRequests
        : axOptions.decamelizeRequests;
      if (decamelizeKeys) {
        const decamelizeConvert = (key, convert, convertOptions) =>
          axOptions.decamelizeIgnoreKeys?.includes(key) ? key : convert(key, convertOptions);
        axConfig.params = humps.decamelizeKeys(axConfig.params, decamelizeConvert);
        axConfig.data = humps.decamelizeKeys(axConfig.data, decamelizeConvert);
      }
      const token = browserStorageGetItem(TOKEN_KEY);
      if (includeAuth) {
        axConfig.withCredentials = true;
        if (token && !axConfig.headers.Authorization) {
          axConfig.headers.Authorization = `Bearer ${token}`;
        }
      }
      const impersonatedUserId = browserStorageGetSessionItem(IMPERSONATED_USER_ID_KEY);
      if (impersonatedUserId) {
        axConfig.headers['X-On-Behalf-Of'] = `${impersonatedUserId}`;
      }
      return axConfig;
    },
    (error) => Promise.reject(error),
  );
  ax.interceptors.response.use(
    (response) => {
      const camelizeKeys = isBoolean(response?.config?.camelizeKeys)
        ? response?.config?.camelizeKeys
        : axOptions.camelizeKeys;
      if (camelizeKeys) {
        return humps.camelizeKeys(response);
      }
      return response;
    },
    (error) => {
      const token = browserStorageGetItem(TOKEN_KEY);
      if (token && error.response) {
        // we do const { status, data } = error.response.
        // want to make sure error.response exists.
        redirectIfBadToken(error);
      }
      return Promise.reject(error);
    },
  );
  return ax;
}

export function abortApiCall(abortControllerRef) {
  abortControllerRef?.value?.abort();
}

export function refreshAbortController(abortControllerRef) {
  if (!abortControllerRef || !isRef(abortControllerRef)) {
    throw new Error('ref required for refreshAbortController');
  }

  // Cancel last request if present
  abortApiCall(abortControllerRef);

  const newController = new AbortController();
  abortControllerRef.value = newController;
  return newController.signal;
}

export function refreshCancelToken(context, key) {
  // Cancel last request if found
  // eslint-disable-next-line no-unused-expressions
  context[key]?.cancel?.('Operation canceled due to new request.');

  // Create source token to cancel next request
  context[key] = axios.CancelToken.source();
  return context[key].token;
}

export function handleCancelError(error, callback = () => {}) {
  if (axios.isCancel(error)) {
    callback();
  } else {
    throw error;
  }
}

/**
 * This function can be used to create an axios instance for use with internal APIs
 * This adds handling for authentication, impersonation, and camalization
 *
 * Note: If you are making a call to an external API, use the helpers from external-apis.js
 *
 * @param baseURL base API url to use with request made by this instance
 * @param options axios options
 * @returns axios instance
 */
export function createAxiosInstance(baseURL, options) {
  return createLazyInstance(createAxios, [baseURL, options]);
}

function createSimpleAxios(options) {
  const ax = axios.create(options);
  ax.interceptors.request.use((axConfig) => {
    const impersonatedUserId = browserStorageGetSessionItem(IMPERSONATED_USER_ID_KEY);
    if (impersonatedUserId) {
      axConfig.headers['X-On-Behalf-Of'] = `${impersonatedUserId}`;
    }
    return axConfig;
  });
  return ax;
}

/**
 * This function can be used to create an axios instance for use with internal APIs
 * This only adds handling for impersonation
 *
 * Note: If you are making a call to an external API, use the helpers from external-apis.js
 *
 * @param options axios options
 * @returns axios instance
 */
export function createSimpleAxiosInstance(options) {
  return createLazyInstance(createSimpleAxios, [options]);
}
