import { computed, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { DashboardAPI } from '@/apis';
import { useIdentityStore } from '@/stores/identity';
import constants from '@/app/settings/components/CustomMetrics/constants';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { parseQuery } from '@/utils/query';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import { useTrackingStore } from '@/stores/tracking';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { useMetricsStore } from '@/stores/metrics';

export const useCustomMetricsStore = defineStore('customMetrics', () => {
  const identityStore = useIdentityStore();
  const trackingStore = useTrackingStore();
  const metricsStore = useMetricsStore();

  // States

  const channelConnectionStatuses = ref({});
  const customMetricList = ref([]);
  const showChannelMetrics = ref(false);
  const selectedChannel = ref(null);
  const customMetricsUsage = ref({});
  const createCustomMetricResponsePayload = ref({});
  const showCustomMetricWizardPopup = ref(false);
  const customMetricsListNext = ref(null);
  const availableMetricsPopupIsOpen = ref(false);
  const metricPredictionPopupIsOpen = ref(false);
  const typedMetric = ref('');
  const currentCharacter = ref('');
  const currentCustomMetricFormula = ref([]);
  const currentCustomMetricFormat = ref(constants.METRIC_TYPE.FLOAT.value);
  const currentMetricIndex = ref(null);
  const originalCustomMetricData = ref(null);
  const customMetricDetails = ref([]);
  const disableNextButton = ref(false);
  const customMetricWizardMode = ref(constants.CUSTOM_METRIC_SAVE_MODES.CREATE);

  // methods

  const formattedFormula = computed(() => {
    return currentCustomMetricFormula.value.join('');
  });

  const customMetricBuilder = reactive({
    aggregation: 'SUM',
    brandIds: [],
    description: '',
    formula: formattedFormula.value,
    metricFormat: currentCustomMetricFormat.value,
    name: '',
    step: 0,
    trendInterpretation: constants.METRIC_DETAILS.DESIRED_OUTCOME.options[0].value,
    mode: constants.CUSTOM_METRIC_SAVE_MODES.CREATE,
    id: null,
  });

  const loading = reactive({
    customMetrics: true,
    customMetricUpdate: false,
    customMetricCreation: false,
  });

  const hasCustomMetrics = computed(() => {
    return !!customMetricList.value?.data?.length;
  });

  async function getCustomMetricsUsage() {
    const brandIds = Object.values(identityStore.identity?.brands || {}).map((brand) => brand.id);

    const response = await DashboardAPI.getCustomMetricsUsage({ brandIds });
    customMetricsUsage.value = response?.data;
  }

  function openAvailableMetricsPopup() {
    availableMetricsPopupIsOpen.value = true;
  }

  function updateCustomMetricsLoading(loadingData) {
    Object.assign(loading, cloneDeep(loadingData));
  }

  function openCustomMetricsWizardPopup() {
    showCustomMetricWizardPopup.value = true;
  }

  function closeCustomMetricsWizardPopup() {
    showCustomMetricWizardPopup.value = false;
  }

  function addMixpanelEvent(eventName, properties) {
    trackingStore.track(eventName, properties);
  }

  function parseMetricChannelPairs(formulaString) {
    if (!formulaString) return [];
    const metricChannelPairs = formulaString.match(
      constants.CUSTOM_METRIC_REGEX.FORMULA_METRIC_PATTERN,
    );
    if (!metricChannelPairs) return [];
    return metricChannelPairs.map((match) => {
      const [matchMetricsAndChannels] = match.match(
        constants.CUSTOM_METRIC_REGEX.FORMULA_METRIC_PATTERN,
      );
      const [, channel, metricKey] = matchMetricsAndChannels.slice(1, -2).split(/[':]|:/);
      return {
        channel,
        metricKey,
      };
    });
  }

  function findDisplayNameByChannelMetricKey(metricKey) {
    const [channel, metric] = metricKey.split(':');
    const metricsReport = metricsStore.metricDetails[channel]?.metrics_report ?? {};
    return metricsReport[metric]?.display_name ?? metric;
  }

  function getChannelsAndMetricsFromFormula(formulaString) {
    if (!formulaString) return { channels: [], metrics: [] };

    const tokens = parseMetricChannelPairs(formulaString);
    const channels = new Set();
    const metrics = [];

    tokens.forEach((token) => {
      if (typeof token === 'object' && token.channel && token.metricKey) {
        channels.add(token.channel);
        metrics.push({
          channel: token.channel,
          metricKey: token.metricKey,
        });
      }
    });

    return {
      channels: Array.from(channels),
      metrics,
    };
  }

  function sendCustomMetricMixpanelData(customMetricResponsePayload, saveType) {
    const currentSaveType = saveType;
    const properties = {};

    const brandNames = customMetricResponsePayload.brand_ids
      .map((id) => {
        const brand = identityStore.identityBrandsById[id];
        return brand;
      })
      .filter((brand) => brand !== undefined)
      .map((brand) => brand.name);

    properties.brandSelected = brandNames;
    properties.tagsSelected = customMetricResponsePayload.tags ?? []; // currently tags are not supported

    properties.customMetricType =
      customMetricResponsePayload.metric_format === constants.METRIC_TYPE.PERCENTAGE.value
        ? constants.METRIC_TYPE.PERCENTAGE.label
        : constants.METRIC_TYPE.FLOAT.label;

    properties.customMetricGrowth =
      customMetricResponsePayload.trend_interpretation ===
      constants.TREND_INTERPRETATION.GROWTH.value
        ? constants.TREND_INTERPRETATION.GROWTH.label
        : constants.TREND_INTERPRETATION.DECLINE.label;

    const { channels, metrics } = getChannelsAndMetricsFromFormula(
      customMetricResponsePayload.formula,
    );
    properties.channelsIncluded = channels.map((channel) => CHANNELS[channel]?.text ?? channel);

    properties.metricsUsed = metrics.map((metric) => {
      const metricKey = `${metric.channel}:${metric.metricKey}`;
      const displayName = findDisplayNameByChannelMetricKey(metricKey);
      return displayName;
    });

    properties.customMetricName = customMetricResponsePayload.name;
    properties.customMetricId = customMetricResponsePayload.id;
    properties.customMetricFormula = customMetricResponsePayload.formula;
    properties.customMetricAction = constants?.ACTION_MAP[currentSaveType];

    const isUpdateOrDelete = [
      constants.CUSTOM_METRIC_SAVE_MODES.DELETE,
      constants.CUSTOM_METRIC_SAVE_MODES.UPDATE,
    ].includes(currentSaveType);

    const eventStatus = isUpdateOrDelete
      ? constants.CUSTOM_METRIC_MIXPANEL_EVENTS.UPDATE
      : constants.CUSTOM_METRIC_MIXPANEL_EVENTS.SAVED;

    addMixpanelEvent(eventStatus, properties);
  }

  async function getCustomMetrics({ resetPagination = false, appendData = false } = {}) {
    updateCustomMetricsLoading({
      customMetrics: true,
    });

    const brandIds = Object.values(identityStore.allUserBrands).map((brand) => brand.id);
    const limit = constants.GET_CUSTOM_METRIC_DETAILS.limit;
    const params = { limit };

    if (!resetPagination && customMetricsListNext.value) {
      merge(params, parseQuery(customMetricsListNext.value));
    }

    const response = await DashboardAPI.getCustomMetrics({
      brandIds,
      params,
    });

    const { paging } = response?.data || {};

    customMetricsListNext.value = paging?.next;

    if (appendData) {
      customMetricList.value.data = [
        ...(customMetricList.value?.data || []),
        ...(response?.data?.data || []),
      ];
    } else {
      customMetricList.value = response?.data;
    }

    updateCustomMetricsLoading({
      customMetrics: false,
    });
  }

  async function createCustomMetrics() {
    const [firstBrandId] = customMetricBuilder.brandIds;
    /**
     * NOTE: Select the proper org for the custom metric
     * 1. The first selected brand organization
     * 2. fallback to the user's organization
     *
     * TODO: We should instead selected the first brand org that the user has
     * can manage custom metric user org permission
     */
    const organizationId =
      Object.values(identityStore.identity?.brands).find((brand) => brand.id === firstBrandId)
        ?.organizationId ?? identityStore.identity?.organization.id;
    const payload = { ...customMetricBuilder };

    if (!payload.description) {
      delete payload.description;
    }
    delete payload.mode;
    delete payload.id;
    delete payload.step;

    updateCustomMetricsLoading({
      customMetricCreation: true,
    });
    try {
      const response = await DashboardAPI.createCustomMetric({
        organizationId,
        params: payload,
      });
      const createOrDuplicatePayload = response?.data;
      createCustomMetricResponsePayload.value = createOrDuplicatePayload;
      sendCustomMetricMixpanelData(createOrDuplicatePayload, customMetricBuilder.mode);
    } finally {
      await getCustomMetrics({ resetPagination: true });
      updateCustomMetricsLoading({
        customMetricCreation: false,
      });
      await getCustomMetricsUsage();
    }
  }

  function getModifiedFields(original, current) {
    if (!original || !current) return {};
    if (original.description === '') original.description = null;
    const modifiedFields = {};
    Object.keys(current).forEach((key) => {
      if (!isEqual(current[key], original[key])) {
        modifiedFields[key] = current[key];
      }
    });
    if (!isNil(modifiedFields?.step)) delete modifiedFields.step;
    return modifiedFields;
  }

  const modifiedFields = computed(() => {
    const customMetricPayload = { ...customMetricBuilder };

    delete customMetricPayload.mode;
    delete customMetricPayload.id;
    delete customMetricPayload.step;
    if (!customMetricPayload.description) {
      customMetricPayload.description = null;
    }

    return getModifiedFields(originalCustomMetricData.value, customMetricPayload);
  });

  const hasModifiedFields = computed(() => {
    return Object.keys(modifiedFields.value).length > 0;
  });

  async function updateCustomMetric() {
    if (!hasModifiedFields.value) return;

    const organizationId = identityStore.identity?.organization.id;
    const customMetricPayload = { ...customMetricBuilder };
    const metricId = customMetricPayload.id;

    updateCustomMetricsLoading({
      customMetricUpdate: true,
    });
    try {
      const response = await DashboardAPI.updateCustomMetric({
        organizationId,
        metricId,
        params: modifiedFields.value,
      });
      const payload = response?.data;
      createCustomMetricResponsePayload.value = payload;
      if (customMetricList.value.data) {
        const index = customMetricList.value.data.findIndex((metric) => metric.id === metricId);
        if (index !== -1) {
          customMetricList.value.data.splice(index, 1, payload);
        }
      }
      sendCustomMetricMixpanelData(payload, customMetricBuilder.mode);
    } finally {
      await getCustomMetrics({ resetPagination: true });
      await getCustomMetricsUsage();
      updateCustomMetricsLoading({
        customMetricUpdate: false,
      });
    }
  }

  async function handleSave() {
    if (
      customMetricBuilder.mode === constants.CUSTOM_METRIC_SAVE_MODES.CREATE ||
      customMetricBuilder.mode === constants.CUSTOM_METRIC_SAVE_MODES.DUPLICATE
    ) {
      await createCustomMetrics();
    } else if (customMetricBuilder.mode === constants.CUSTOM_METRIC_SAVE_MODES.UPDATE) {
      await updateCustomMetric();
    }
  }

  function handleCloseMetricPicker() {
    showChannelMetrics.value = false;
    availableMetricsPopupIsOpen.value = false;
    currentMetricIndex.value = null;
  }

  function handleUnmountCustomMetric() {
    customMetricsListNext.value = null;
    customMetricList.value = [];
  }
  function resetCustomMetricBuilder() {
    Object.assign(customMetricBuilder, {
      aggregation: 'SUM',
      brandIds: [],
      description: '',
      formula: '',
      metricFormat: constants.METRIC_TYPE.FLOAT.value,
      name: '',
      step: 0,
      trendInterpretation: constants.METRIC_DETAILS.DESIRED_OUTCOME.options[0].value,
      mode: constants.CUSTOM_METRIC_SAVE_MODES.CREATE,
      id: null,
    });
    originalCustomMetricData.value = null;
    currentCustomMetricFormula.value = [];
    loading.customMetricCreation = false;
    loading.customMetricUpdate = false;
    channelConnectionStatuses.value = {};
  }

  async function deleteCustomMetric(organizationId, metricId) {
    await DashboardAPI.deleteCustomMetric({ organizationId, metricId });
    await getCustomMetrics({ resetPagination: true });
    // Get the new list of custom metrics usage and update the store
    await getCustomMetricsUsage();
  }

  function sendMixpanelErrorData(errorReturned, payload) {
    const properties = {};

    properties.errorMessage =
      errorReturned.response?.data?.description ||
      errorReturned.message ||
      constants.CUSTOM_METRIC_ERROR_MESSAGES.ERROR_NOT_RETURNED;

    properties.customMetricError = constants.CUSTOM_METRIC_ERROR_MESSAGES.CONTAINS_ERROR;

    const customMetricAction = constants?.ACTION_MAP[payload?.mode];
    properties.customMetricErrorType = customMetricAction;
    properties.customMetricName = payload?.name;
    properties.customMetricId = payload?.id;
    properties.customMetricFormula = payload?.formula;
    addMixpanelEvent(constants.CUSTOM_METRIC_MIXPANEL_EVENTS.ERROR, properties);
  }

  function sendMixpanelWizardChangeData(index, form, previousStepNumber) {
    const currentStep = constants.CUSTOM_METRIC_STEPS_LABEL[index];
    const direction =
      currentStep.stepNumber > previousStepNumber
        ? constants.WIZARD_PANEL_DIRECTION.FORWARD
        : constants.WIZARD_PANEL_DIRECTION.BACKWARD;

    const mixpanelData = {
      stepNumber: currentStep.stepNumber,
      stepLabel: currentStep.stepLabel,
      StepTransiton: direction,
      customMetricAction: constants.ACTION_MAP[form.mode],
      customMetricName: form?.name,
      customMetricId: form?.id,
      customMetricBrandIDs: form?.brandIds,
      customMetricType: form?.metricFormat,
      customMetricFormula: form?.formula,
      customMetricDescription: form?.description,
      customMetricTrendInterpretation: form?.trendInterpretation,
    };
    addMixpanelEvent(constants.CUSTOM_METRIC_MIXPANEL_EVENTS.STEPS, mixpanelData);
  }
  return {
    customMetricList,
    customMetricsListNext,
    customMetricBuilder,
    hasCustomMetrics,
    loading,
    showCustomMetricWizardPopup,
    customMetricsUsage,
    createCustomMetricResponsePayload,
    hasModifiedFields,
    showChannelMetrics,
    selectedChannel,
    availableMetricsPopupIsOpen,
    currentCustomMetricFormula,
    metricPredictionPopupIsOpen,
    typedMetric,
    currentCharacter,
    formattedFormula,
    currentMetricIndex,
    channelConnectionStatuses,
    getModifiedFields,
    modifiedFields,
    openCustomMetricsWizardPopup,
    closeCustomMetricsWizardPopup,
    createCustomMetrics,
    updateCustomMetric,
    handleSave,
    resetCustomMetricBuilder,
    deleteCustomMetric,
    openAvailableMetricsPopup,
    handleCloseMetricPicker,
    handleUnmountCustomMetric,
    getCustomMetricsUsage,
    getCustomMetrics,
    customMetricDetails,
    updateCustomMetricsLoading,
    originalCustomMetricData,
    sendMixpanelErrorData,
    sendCustomMetricMixpanelData,
    sendMixpanelWizardChangeData,
    addMixpanelEvent,
    currentCustomMetricFormat,
    disableNextButton,
    customMetricWizardMode,
  };
});
