<script setup>
import Button from '@/components/foundation/Button.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useIdentityStore } from '@/stores/identity';
import { useNotificationStore } from '@/stores/notification';
import Icon from '@/components/foundation/Icon.vue';
import { useAuthStore } from '@/stores/auth';
import {
  APPROVAL_POLICY,
  APPROVAL_STATUS,
  PUBLISHING_APPROVALS_OVERRIDE_BANNER_LOCK,
  PUBLISHING_APPROVALS_OVERRIDE_BANNER_UNLOCK,
  postStatus,
} from '@/app/scheduler/constants';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import ApprovalStatus from '@/app/scheduler/components/ApprovalStatus.vue';
import AddReviewer from '@/app/scheduler/components/AddReviewer.vue';
import ApprovalStatusChip from '@/app/scheduler/components/ApprovalStatusChip.vue';
import { SchedulerUserEventTracker } from '@/app/scheduler/mixpanel';
import { discardConfirmTitle, discardConfirmMessage } from '@/config';
import { USER } from '@/models/auth/permissions.enum';

const props = defineProps({
  post: {
    type: Object,
    default: null,
  },
  platform: {
    type: String,
    default: null,
  },
  hasUnsavedChanges: {
    type: Boolean,
    default: false,
  },
});

const identityStore = useIdentityStore();
const authStore = useAuthStore();
const schedulerStore = useSchedulerStore();
const notificationStore = useNotificationStore();
const router = useRouter();

const addReviewer = ref(null);

const showApproveBtn = computed(() => {
  return (
    props.post?.approvalRequests?.filter((request) => {
      return request.reviewUserId === identityStore.identity.id;
    }).length > 0
  );
});

const showOverrideBtn = computed(() => {
  return authStore.guard(USER.SCHEDULER.CAN_REVIEW_SCHEDULED_POST) && props.post?.id;
});

const postIsLocked = computed(() => {
  return props.post?.approvalPolicy === APPROVAL_POLICY.APPROVAL_REQUIRED;
});
const overrideIconName = computed(() => {
  return postIsLocked.value ? 'lock-1' : 'lock-unlock-1';
});

const hasPendingReview = computed(() => {
  return (
    props.post?.approvalRequests?.filter((request) => {
      return (
        request.reviewUserId === identityStore.identity.id &&
        request.approvalStatus === APPROVAL_STATUS.PENDING
      );
    }).length > 0
  );
});

const approveBtnText = computed(() => {
  return hasPendingReview.value ? 'Approve' : 'Undo';
});

const overrideBtnText = computed(() => {
  return postIsLocked.value ? 'Unlock' : 'Lock';
});

const overrideBannerText = computed(() => {
  return postIsLocked.value
    ? PUBLISHING_APPROVALS_OVERRIDE_BANNER_UNLOCK
    : PUBLISHING_APPROVALS_OVERRIDE_BANNER_LOCK;
});

const approvalRequestId = computed(() => {
  return props.post?.approvalRequests?.filter((request) => {
    return request.reviewUserId === identityStore.identity.id;
  })[0].id;
});

const approvalChipText = computed(() => {
  const numOfApprovalRequired =
    props.post?.numOfApprovalsRequired ||
    authStore.user_can('scheduler', 'scheduler_num_of_approvals_required');
  return `${numOfApprovalRequired} required`;
});

const showChip = computed(() => {
  const requireApprovalsToPost = authStore.user_can('scheduler', 'require_approvals_to_post');
  return props.post?.approvalPolicy
    ? props.post?.approvalPolicy === APPROVAL_POLICY.APPROVAL_REQUIRED
    : requireApprovalsToPost;
});

const trackMixpanel = computed(() => {
  return new SchedulerUserEventTracker('Scheduler Editor');
});

const disabled = computed(() => {
  return [
    postStatus.AUTOPUBLISHING,
    postStatus.POSTED,
    postStatus.IMPORTED,
    postStatus.PARTIALLY_FAILED,
  ].includes(props.post?.status);
});

function buttonClickedEvent(btnText) {
  trackMixpanel.value.approvalButtonClicked(
    props.post,
    props.post?.platform,
    props.post?.approvalRequests || [],
    btnText,
  );
}

async function manageApprovalSettingsClicked() {
  if (
    props.hasUnsavedChanges ||
    schedulerStore.approvalRequests.find((req) => !req.approvalStatus)
  ) {
    await notificationStore.confirm(discardConfirmTitle, discardConfirmMessage, {
      confirmAlias: 'Discard',
      onConfirm: () => {
        router.push({ name: 'settings.permissions' });
        buttonClickedEvent('Manage Approval Settings');
      },
    });
  } else {
    router.push({ name: 'settings.permissions' });
    buttonClickedEvent('Manage Approval Settings');
  }
}

function updatePostApprovalPolicy() {
  schedulerStore.updatePostPolicy({ id: props.post?.id, platform: props.post?.platform });
  buttonClickedEvent(overrideBtnText.value);
}

function removeReviewer(id, uncheckUser) {
  schedulerStore.approvalRequests = schedulerStore.approvalRequests.filter(
    (review) => review.reviewUserId !== id,
  );
  if (uncheckUser) {
    addReviewer.value.updateUser(id);
  }
}

async function updateApprovalRequestStatus() {
  let approvalStatus = APPROVAL_STATUS.PENDING;
  if (hasPendingReview.value) {
    approvalStatus = APPROVAL_STATUS.APPROVED;
  }
  buttonClickedEvent(approveBtnText.value);
  await schedulerStore.updateApprovalRequest({
    postId: props.post?.id,
    platform: props.post?.platform,
    approvalRequestId: approvalRequestId.value,
    approvalStatus,
  });
}

onMounted(() => {
  schedulerStore.approvalRequests = props.post?.approvalRequests || [];
});

onUnmounted(() => {
  schedulerStore.approvalRequests = [];
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div class="flex flex-col p-2.5">
    <Button
      v-if="showApproveBtn && !disabled"
      class="m-2 h-8"
      large
      primary
      wide
      data-test="approve-undo-button"
      :loading="schedulerStore.savingApprovalRequestStatus"
      @click="updateApprovalRequestStatus"
      >{{ approveBtnText }}</Button
    >
    <Button
      v-if="showOverrideBtn && !disabled"
      class="m-2 h-8"
      large
      white
      wide
      data-test="lock-unlock-button"
      @click="updatePostApprovalPolicy"
    >
      <Icon :name="overrideIconName" class="mr-1" xsmall />
      {{ overrideBtnText }}
    </Button>
    <Banner v-if="showOverrideBtn && !disabled" class="override-banner m-2 bg-white">{{
      overrideBannerText
    }}</Banner>
    <div class="mx-3.5">
      <div class="mb-2 mt-6 flex">
        <p class="text-sm">Add Reviewer</p>
        <ApprovalStatusChip v-if="showChip" class="ml-2" :status="approvalChipText" />
      </div>
      <AddReviewer
        ref="addReviewer"
        :post="post"
        :platform="platform"
        :remove-reviewer="removeReviewer"
        :class="[{ disabled }]"
      />
      <a
        v-if="showOverrideBtn"
        class="text-sm text-[--action-500]"
        data-test="manage-approval-settings-button"
        @click="manageApprovalSettingsClicked"
        >Manage Approval Settings</a
      >
    </div>
  </div>
  <div
    class="text-primary approval-status-container flex items-center py-5 pl-6 pr-0 text-base leading-5"
  >
    <div v-if="schedulerStore.approvalRequests.length > 0" class="approval-status-header">
      Approval Status
    </div>
    <ApprovalStatus
      v-for="review in schedulerStore.approvalRequests"
      :key="review.id"
      :review="review"
      :remove-reviewer="removeReviewer"
    />
  </div>
</template>

<style lang="postcss" scoped>
.override-banner {
  width: unset !important;
}

.approval-status-container {
  flex-flow: column;
}

.approval-status-header {
  align-self: flex-start;
  font-size: var(--x14);
}

.disabled {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}
</style>
