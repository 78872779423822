import enumTypes, { MEDIA_CARD_SENTIMENT } from '@/app/library/constants';

export const MAX_WIDTH = 1080;
export const MAX_HEIGHT = 1080;

export function scaleImage(width, height) {
  if (width > height) {
    if (width > MAX_WIDTH) {
      return [MAX_WIDTH, (height * MAX_WIDTH) / width];
    }
  } else if (height > MAX_HEIGHT) {
    return [(width * MAX_HEIGHT) / height, MAX_HEIGHT];
  }
  return [width, height];
}

export function getCompetitorAndUGCHandles(mediaList) {
  return mediaList.reduce((prev, media) => {
    const handle =
      media?.creator?.handle ||
      media?.instagram?.instagramUser?.handle ||
      media?.instagram?.instagram_user?.handle;

    const sourceType = media?.sourceType || media?.source_type;
    if (!handle) {
      return prev;
    }
    if (sourceType === enumTypes.UGC) {
      if (prev?.ugc && Object.keys(prev.ugc).includes(handle)) {
        prev.ugc[handle] += 1;
      } else {
        prev.ugc = { ...prev.ugc, [handle]: 1 };
      }
    } else if (sourceType !== enumTypes.UGC && sourceType !== enumTypes.OWNED) {
      if (prev?.competitor && Object.keys(prev.competitor).includes(handle)) {
        prev.competitor[handle] += 1;
      } else {
        prev.competitor = { ...prev.competitor, [handle]: 1 };
      }
    }
    return prev;
  }, {});
}

/**
 * Formats the sentiment of an object based on predefined sentiment mappings.
 * @param {Object} sentimentObj - The object containing sentiment properties.
 */
export function formatSentiment(sentimentObj) {
  if (!sentimentObj) return '';

  const matchedSentiment = MEDIA_CARD_SENTIMENT.find((mapping) => sentimentObj[mapping.key]);

  return matchedSentiment ? matchedSentiment.value : '';
}
