import axios from 'axios';
import { createLazyInstance } from '@/utils/lazy';

/**
 * This function can be used to create an axios instance for use with an external API
 * @param options axios options
 * @returns axios instance
 */
export function createExternalAxiosInstance(options) {
  return createLazyInstance(axios.create, [options]);
}

export const externalAxios = createExternalAxiosInstance();
