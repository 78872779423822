import { ref, computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { env } from '@/env';
import { INTEGRATIONS, useIntegrationStore } from '@/stores/integration';
import { useAuthStore } from '@/stores/auth';
import { useCommunityStore } from '@/stores/community';
import { saveCommunityInteractionTicket, getCommentInteractionDetail } from '@/apis/community';
import { logger } from '@/utils/logger';
import { commentFormatter } from '@/app/community/utils/comments';
import { communityInteractionProps } from '@/app/community/utils/mixpanel-events';
import { getPlatformByType, getInteractionUrl } from '@/app/community/utils/community-interactions';
import { toUnicodeVariant } from '@/vendor/toUnicodeVariant';
import { normalizeMedia } from '@/utils/media';
import {
  COMMUNITY_INTERACTION_PLATFORM_BY_TYPE,
  COMMUNITY_INTERACTION_TYPES,
} from '@/app/community/constants';
import { useTrackingStore } from '@/stores/tracking';

const WORKFLOWS = {
  [INTEGRATIONS.BRAND.SALESFORCE.value]: {
    CREATE_TICKET: '775247f2-ad6e-4391-99a5-5f5b64063d5b',
    GET_TICKET: 'e9c946e8-47d8-43b9-825c-a4947069f8ba',
  },
  [INTEGRATIONS.BRAND.ZENDESK.value]: {
    CREATE_TICKET: '638c5dd1-9f62-498a-95d4-f8faa0974c36',
    GET_TICKET: '3ec49b11-4d9f-4daa-9ba3-f7b9fc3dc436',
  },
};

const DEV_WORKFLOWS = {
  [INTEGRATIONS.BRAND.SALESFORCE.value]: {
    CREATE_TICKET: '775247f2-ad6e-4391-99a5-5f5b64063d5b',
    GET_TICKET: 'e9c946e8-47d8-43b9-825c-a4947069f8ba',
  },
  [INTEGRATIONS.BRAND.ZENDESK.value]: {
    CREATE_TICKET: '638c5dd1-9f62-498a-95d4-f8faa0974c36',
    GET_TICKET: '3ec49b11-4d9f-4daa-9ba3-f7b9fc3dc436',
  },
};

const TICKET_TOOLTIP_PREFIX = {
  [INTEGRATIONS.BRAND.SALESFORCE.value]: 'Case Number',
  [INTEGRATIONS.BRAND.ZENDESK.value]: 'Ticket #',
};

function getWorkflows(integrationType) {
  return env.isProduction ? WORKFLOWS[integrationType] : DEV_WORKFLOWS[integrationType];
}

export const useTicketStore = defineStore('ticket', () => {
  const integrationStore = useIntegrationStore();
  const authStore = useAuthStore();
  const communityStore = useCommunityStore();
  const trackingStore = useTrackingStore();

  const createTicketPopup = ref({
    show: false,
    integration: null,
    communityInteraction: null,
    message: null,
    comment: null,
  });
  const tickets = reactive({
    [INTEGRATIONS.BRAND.SALESFORCE.value]: {},
    [INTEGRATIONS.BRAND.ZENDESK.value]: {},
  });
  const ready = computed(() => {
    return integrationStore.authenticatedAsBrand;
  });
  const isCreateTicketEnabled = computed(() => {
    return (integration) => {
      const integrationType = integrationStore.extractIntegrationType(integration);
      const workflowId = getWorkflows(integrationType)?.CREATE_TICKET;

      const integrationEnabled = integrationStore.isConnected(integrationType);
      const workflowEnabled = integrationStore.isWorkflowEnabled(integrationType, workflowId);

      return integrationEnabled && workflowEnabled;
    };
  });

  const isSalesforceCreateTicketEnabled = computed(() => {
    return isCreateTicketEnabled.value(INTEGRATIONS.BRAND.SALESFORCE);
  });
  const isZenDeskCreateTicketEnabled = computed(() => {
    return isCreateTicketEnabled.value(INTEGRATIONS.BRAND.ZENDESK);
  });

  function formatEscalatedDate(date) {
    if (!date) {
      return '';
    }
    const timeZoneName = authStore.identity?.time_zone_name;
    const escalatedDate = timeZoneName ? dayjs(date).tz(timeZoneName) : dayjs(date).local();
    return escalatedDate.format('LLL');
  }

  function stringifyComment({ comment, communityInteraction }) {
    let escalatedText = '';
    const { platform } = getPlatformByType(communityInteraction);
    escalatedText += `${platform} User Name: ${comment.username}\n`;
    if (comment?.userProfile) {
      escalatedText += `User Profile Link: ${comment.userProfile}`;
    }
    escalatedText += `Text: ${comment?.text ?? ''}\n`;
    if (comment?.attachments?.length > 0 || comment?.attachment) {
      (comment.attachments ?? [comment.attachment]).forEach((attachment) => {
        escalatedText += `Attachment: ${attachment.src}\n`;
      });
    }
    escalatedText += `Date: ${formatEscalatedDate(comment.timestamp)}\n`;
    return escalatedText;
  }

  function stringifyMessage({ message, communityInteraction }) {
    let escalatedText = '';
    const { platform } = getPlatformByType(communityInteraction);
    const username = communityInteraction?.followerParticipant?.username;
    escalatedText += `${platform} User Name: ${username}\n`;
    escalatedText += `Text: ${message?.text ?? ''}\n`;
    if (message?.attachments?.length > 0 || message?.attachment) {
      (message.attachments ?? [message.attachment]).forEach((attachment) => {
        escalatedText += `Attachment: ${attachment.src}\n`;
      });
    }
    escalatedText += `Date: ${formatEscalatedDate(message?.updatedAt)}\n`;
    return escalatedText;
  }

  async function getFormattedCommentDetail(communityInteraction) {
    const commentDetail = await getCommentInteractionDetail({
      platform: COMMUNITY_INTERACTION_PLATFORM_BY_TYPE[communityInteraction?.type].platform,
      communityInteractionId: communityInteraction?.id,
    });
    const formattedCommentDetail = commentFormatter(commentDetail?.data, authStore.currentBrand);
    return formattedCommentDetail;
  }

  async function getEscalatedText({ communityInteraction, comment, message, includeThread }) {
    let escalatedText = '';
    let escalatedThread = '';
    let commentList = null;
    let messageList = null;
    const brandId = authStore.currentBrand?.id;
    const platform = communityInteraction?.type;
    const limit = 9999;

    if (comment) {
      escalatedText = stringifyComment({ comment, communityInteraction });
    } else if (message) {
      escalatedText = stringifyMessage({ message, communityInteraction });
    }

    if (includeThread) {
      if (comment) {
        const mediaId = comment.mediaId;
        if (mediaId) {
          commentList = await communityStore.getCommentList({ brandId, platform, mediaId, limit });
          escalatedThread = (commentList ?? [])
            .map((c) => commentFormatter(c, authStore.currentBrand))
            .map((c) => stringifyComment({ comment: c, communityInteraction }))
            .join(`\n\n`);
        } else if (comment?.type === COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION) {
          const formattedCommentDetail = await getFormattedCommentDetail(communityInteraction);
          escalatedThread = (formattedCommentDetail?.replies ?? [])
            .map((r) => stringifyComment({ comment: r, communityInteraction }))
            .join('\n\n');
        }
      } else if (message) {
        const communityInteractionId = communityInteraction.id;
        messageList = await communityStore.getMessages({ platform, communityInteractionId, limit });
        escalatedThread = (messageList ?? [])
          .map((m) => stringifyMessage({ message: m, communityInteraction }))
          .join(`\n\n`);
      } else {
        // clicked from interaction overview of a tweet
        // topParentStatus is available when a selected interaction is reply to ugc mention
        const target = communityInteraction.topParentStatus ?? communityInteraction;
        const formattedCommentDetail = await getFormattedCommentDetail(target);
        escalatedText = stringifyComment({
          comment: formattedCommentDetail,
          communityInteraction: formattedCommentDetail,
        });
        escalatedThread = (formattedCommentDetail?.replies ?? [])
          .map((r) => stringifyComment({ comment: r, communityInteraction: target }))
          .join('\n\n');
      }
    } else if (!comment && !message && communityInteraction) {
      // clicked from interaction overview but didn't include thread
      const target = communityInteraction.topParentStatus ?? communityInteraction;
      const formattedTarget = commentFormatter(target, authStore.currentBrand);
      escalatedText = stringifyComment({
        comment: formattedTarget,
        communityInteraction: formattedTarget,
      });
    }
    return {
      escalatedText,
      escalatedThread,
    };
  }

  function openCreateTicketPopup(
    integration,
    { communityInteraction, communityInteractionMedia, message, comment } = {},
  ) {
    const integrationMixpanelProps = integrationStore.integrationMixpanelProps(integration);
    trackingStore.track('Send to Integration Selected', {
      ...integrationMixpanelProps,
      ...communityInteractionProps(communityInteraction),
    });
    createTicketPopup.value.integration = integration;
    createTicketPopup.value.communityInteraction = communityInteraction;
    createTicketPopup.value.communityInteractionMedia = communityInteractionMedia;
    createTicketPopup.value.message = message;
    createTicketPopup.value.comment = comment;
    createTicketPopup.value.show = true;
  }

  function closeCreateTicketPopup() {
    createTicketPopup.value.integration = null;
    createTicketPopup.value.communityInteraction = null;
    createTicketPopup.value.communityInteractionMedia = null;
    createTicketPopup.value.message = null;
    createTicketPopup.value.comment = null;
    createTicketPopup.value.show = false;
  }

  async function getTicketFromSource({ integration, sourceId }) {
    const integrationType = integrationStore.extractIntegrationType(integration);
    const workflowId = getWorkflows(integrationType)?.GET_TICKET;
    if (!workflowId) {
      logger.error(`No 'Get Ticket' workflow for ${integrationType}`);
      return null;
    }
    const response = await integrationStore.runWorkflow(workflowId, {
      body: {
        source_id: `${sourceId}`,
      },
    });

    if (response?.ticket && response?.source_id) {
      tickets[integrationType][`${response.source_id}`] = response.ticket;
    }

    return response;
  }

  async function updateTicket(communityInteraction, ticket) {
    if (!communityInteraction || !ticket) {
      return null;
    }

    const ticketFromSource = await getTicketFromSource({
      integration: ticket?.source,
      sourceId: `${ticket?.sourceId}`,
    });

    let updatedTicket = { ...ticket };

    const isNew = !!ticket?.isNew;
    const hasChanged =
      ticketFromSource?.status !== ticket?.status ||
      `${ticketFromSource?.label}` !== ticket?.label ||
      ticketFromSource?.source_updated_at !== ticket?.sourceUpdatedAt;
    if (isNew || hasChanged) {
      const response = await saveCommunityInteractionTicket({
        communityInteractionId: communityInteraction?.id,
        messageId: ticket?.messageId,
        source: ticket?.source,
        sourceId: `${ticket.sourceId}`,
        label: `${ticketFromSource?.label ?? ticket.label}`,
        sourceCreatedAt: ticketFromSource?.source_created_at ?? ticket?.sourceCreatedAt,
        sourceUpdatedAt: ticketFromSource?.source_updated_at ?? ticket?.sourceUpdatedAt,
        status: ticketFromSource?.status ?? ticket?.status,
      });
      updatedTicket = response?.data;
    }
    return updatedTicket;
  }

  function ticketUrl({ integration, id }) {
    const instanceUrl = integrationStore.instanceUrl(integration);

    const integrationType = integrationStore.extractIntegrationType(integration);
    if (integrationType === INTEGRATIONS.BRAND.SALESFORCE.value) {
      return `${instanceUrl}/lightning/r/Case/${id}/view`;
    }
    if (integrationType === INTEGRATIONS.BRAND.ZENDESK.value) {
      /*
          This is a temp solution until paragon puts some sort of instance/subdomain
          data in the integration.providerData json for zendesk.
        */
      const zendeskTicketUrl = tickets[integrationType]?.[id]?.url;
      if (zendeskTicketUrl) {
        const url = new URL(zendeskTicketUrl);
        return `${url.origin}/agent/tickets/${id}`;
      }
    }
    return '';
  }

  async function updateTickets({ communityInteraction }) {
    if (communityInteraction?.tickets?.length < 1) {
      return;
    }

    const savedTickets = await Promise.all(
      communityInteraction?.tickets?.map(async (ticket) =>
        updateTicket(communityInteraction, ticket),
      ) ?? [],
    );

    savedTickets.forEach((savedTicket) => {
      const index =
        communityInteraction?.tickets?.findIndex(
          (ticket) =>
            ticket?.source === savedTicket?.source &&
            `${ticket?.sourceId}` === `${savedTicket?.sourceId}`,
        ) ?? -1;
      if (index > -1) {
        communityInteraction.tickets.splice(index, 1, savedTicket);
        communityInteraction.tickets = [...communityInteraction.tickets];
      } else {
        communityInteraction.tickets = [...(communityInteraction.tickets ?? []), savedTicket];
      }
    });
  }

  async function createTicket(integration, { subject, description, includeThread }) {
    const integrationType = integrationStore.extractIntegrationType(integration);
    const requesterName = `${authStore.identity.first_name} ${authStore.identity.last_name}`;
    const requesterEmail = authStore.identity.email;
    const communityInteraction = createTicketPopup.value.communityInteraction;
    const communityInteractionNormalizedMedia = normalizeMedia(
      createTicketPopup.value.communityInteractionMedia,
    );
    const message = createTicketPopup.value.message;
    const comment = createTicketPopup.value.comment;
    const messageId = message?.id ?? comment?.id;
    const integrationMixpanelProps = integrationStore.integrationMixpanelProps(integration);
    const interactionUrl = getInteractionUrl(communityInteraction);
    const { escalatedText, escalatedThread } = await getEscalatedText({
      communityInteraction,
      message,
      comment,
      includeThread,
    });

    let content = '';
    content += `${toUnicodeVariant(`Requester`, 'b')}\n`;
    content += ` ${requesterName} (${requesterEmail})\n\n`;
    content += `${toUnicodeVariant(`Description`, 'b')}\n`;
    content += ` ${description}\n\n`;
    content += `${toUnicodeVariant(`Escalated Text`, 'b')}\n`;
    content += ` ${escalatedText}\n\n`;
    if (communityInteractionNormalizedMedia?.postUrl) {
      content += `${toUnicodeVariant(`Post Link`, 'b')}\n`;
      content += `${communityInteractionNormalizedMedia.postUrl}\n\n`;
    }
    content += `${toUnicodeVariant(`Dash Social Link`, 'b')}\n`;
    content += `${interactionUrl}\n\n`;
    if (escalatedThread) {
      content += `${toUnicodeVariant(`Escalated Thread History`, 'b')}\n`;
      content += ` ${escalatedThread}\n\n`;
    }

    let payload = null;
    try {
      const workflowId = getWorkflows(integrationType)?.CREATE_TICKET;
      payload = await integrationStore.runWorkflow(workflowId, {
        body: {
          subject,
          description: content,
          requesterName,
          requesterEmail,
        },
      });
    } catch (error) {
      trackingStore.track('Send to Integration Failure', {
        ...integrationMixpanelProps,
        ...communityInteractionProps(communityInteraction),
        messageId,
        messageDescriptionUsed: !!description,
        threadIncluded: includeThread,
        errorType: error?.message ?? error,
      });
      throw error;
    }

    if (payload) {
      trackingStore.track('Sent to Integration', {
        ...integrationMixpanelProps,
        ...communityInteractionProps(communityInteraction),
        messageId,
        messageDescriptionUsed: !!description,
        threadIncluded: includeThread,
      });

      const newTicket = {
        isNew: true,
        messageId,
        source: integrationType?.toUpperCase(),
        sourceId: `${payload.source_id}`,
        label: payload.label,
        sourceCreatedAt: payload.source_created_at,
        sourceUpdatedAt: payload.source_updated_at,
        status: payload.status,
      };
      communityInteraction.tickets = [...(communityInteraction.tickets ?? []), newTicket];
      await updateTickets({ communityInteraction });
    }
  }

  function getTicketDropdownOptions({
    communityInteraction,
    communityInteractionMedia,
    message,
    comment,
  } = {}) {
    const options = [];
    if (integrationStore.hasAccessToIntegrations && integrationStore.authenticatedAsBrand) {
      if (isSalesforceCreateTicketEnabled.value) {
        options.push({
          text: 'Send to Salesforce',
          action: () => {
            openCreateTicketPopup(INTEGRATIONS.BRAND.SALESFORCE, {
              communityInteraction,
              communityInteractionMedia,
              message,
              comment,
            });
          },
        });
      } else if (authStore.isCurrentBrandRoleAdmin) {
        options.push({
          text: 'Connect to Salesforce',
          optionImage: integrationStore.integrationIcon(INTEGRATIONS.BRAND.SALESFORCE),
          action: () => {
            integrationStore.connect(INTEGRATIONS.BRAND.SALESFORCE);
          },
        });
      }
      if (isZenDeskCreateTicketEnabled.value) {
        options.push({
          text: 'Send to Zendesk',
          action: () => {
            openCreateTicketPopup(INTEGRATIONS.BRAND.ZENDESK, {
              communityInteraction,
              communityInteractionMedia,
              message,
              comment,
            });
          },
        });
      } else if (authStore.isCurrentBrandRoleAdmin) {
        options.push({
          text: 'Connect to Zendesk',
          optionImage: integrationStore.integrationIcon(INTEGRATIONS.BRAND.ZENDESK),
          action: () => {
            integrationStore.connect(INTEGRATIONS.BRAND.ZENDESK);
          },
        });
      }
    }
    return options;
  }

  function getTicketChips(payload) {
    if (integrationStore.hasAccessToIntegrations) {
      const sortedTickets = (payload?.tickets ?? []).sort(
        (a, b) => new Date(a.sourceCreatedAt) - new Date(b.sourceCreatedAt),
      );
      return sortedTickets.map((ticket) => {
        const integrationType = integrationStore.extractIntegrationType(ticket.source);
        const ticketHref = ticketUrl({
          integration: ticket.source,
          id: `${ticket?.sourceId}`,
        });
        const sourceName = integrationStore.integrationName(ticket.source) ?? ticket.source;
        const sourceIcon = integrationStore.integrationIcon(ticket.source);
        const ticketStatus = capitalize(ticket.status);
        const ticketLabel = ticket.label;
        const tooltipPrefix = TICKET_TOOLTIP_PREFIX[integrationType] ?? '';
        const ticketTooltip = `${tooltipPrefix} ${ticketLabel} is ${ticketStatus}. Click the icon to be redirected to ${sourceName}.`;
        return {
          ...ticket,
          sourceName,
          sourceIcon,
          ticketHref,
          ticketTooltip,
          goToTicket: () => {
            if (ticketHref) {
              const integrationMixpanelProps =
                integrationStore.integrationMixpanelProps(integrationType);
              trackingStore.track('View Ticket Selected', {
                ...integrationMixpanelProps,
                ...communityInteractionProps(payload?.communityInteraction),
                messageId: ticket?.messageId,
                ticketStatus,
                ticketLabel,
                ticketHref,
              });
              window.open(ticketHref, '_blank');
            }
          },
        };
      });
    }
    return [];
  }

  return {
    ready,
    createTicketPopup,
    openCreateTicketPopup,
    closeCreateTicketPopup,
    isSalesforceCreateTicketEnabled,
    isZenDeskCreateTicketEnabled,
    isCreateTicketEnabled,
    getTicketDropdownOptions,
    getTicketChips,
    createTicket,
    ticketUrl,
    updateTickets,
  };
});
