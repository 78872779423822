import { LibraryAPI } from '@/apis';
import * as CreatorsAPI from '@/apis/creators';
import { useIdentityStore } from '@/stores/identity';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import { CanceledError, CancelToken } from 'axios';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { handleCancelError, refreshAbortController } from '@/apis/axios.utils';
import { getCountryCode } from '@/utils/countries';
import { creatorSource } from '@/app/creators/constants';
import { env } from '@/env';
import { sanitizeStoriesFromDMs } from '@/app/creators/utils';

const RECENT_LOCATIONS_KEY = 'creator-discovery-recent-locations';
export const MAX_RECENT_LOCATIONS = 3;

export const useCreatorsStore = defineStore('creators', () => {
  const accounts = ref([]);
  const creators = ref([]);
  const creatorsCount = ref();
  const account = ref();
  const tikTokAccount = ref();
  const instagramAccount = ref();
  const instagramAccountPosts = ref([]);
  const tikTokAccountPosts = ref([]);
  const tikTokRIQPosts = ref([]);
  const brandRelationshipTags = ref([]);
  const creatorInformationRequest = ref([]);
  const accountMetrics = ref();

  // Creator Profile Drawer Related State
  const creator = ref();
  const editCreator = ref(false);
  const onReloadCreatorProfileCallback = ref();
  const instagramInvitationEmailSent = ref(false);
  const creatorProfileDetailsFormValidation = ref(false);
  const unsavedCreatorData = ref();
  // Information Request Form state
  const informationRequestData = ref({});
  const pendingSubmitIRF = ref(false);

  const pendingCounts = ref({
    account: 0,
    accounts: 0,
    accountPosts: 0,
    tikTokAccountPosts: 0,
    accountMetrics: 0,
    creator: 0,
    creators: 0,
    creatorsCount: 0,
    creatorDetails: 0,
    deleteRelationship: 0,
    addBrandRelationshipTags: 0,
    deleteBrandRelationshipTags: 0,
    brandRelationshipTags: 0,
    relationships: 0,
    createInformationRequest: 0,
    removeRelationshipAccount: 0,
  });

  const creatorsCancelToken = ref(null);
  const accountsCancelToken = ref(null);
  const accountCancelToken = ref({
    instagram: null,
    tiktok: null,
  });

  const paging = ref({
    nextCursor: null,
    lastRequestBody: null,
  });

  const postsPaging = ref({
    accountPostsNextUrl: null,
    tikTokPostsNextCursor: null,
  });

  const abortControllers = {
    getAccountPosts: ref(null),
    fetchTikTokAccountPosts: ref(null),
  };

  const error = ref({
    accounts: null,
  });

  const recentLocations = ref(JSON.parse(browserStorageGetItem(RECENT_LOCATIONS_KEY) || '[]'));

  const hasAccountsNextPage = computed(
    () => !!paging.value.nextCursor && !!paging.value.lastRequestBody,
  );

  const pending = computed(() =>
    Object.fromEntries(Object.entries(pendingCounts.value).map(([k, v]) => [k, !!v])),
  );

  const accountsBySource = computed(() => groupBy(accounts.value, 'source'));

  const mappedAccounts = computed(() =>
    Object.fromEntries(
      Object.entries(accountsBySource.value).map(([source, sourceAccounts]) => [
        source,
        sourceAccounts.reduce(
          (mapById, accountValue) => ({
            ...mapById,
            [accountValue.sourceCreatorId]: accountValue,
          }),
          {},
        ),
      ]),
    ),
  );

  function addRecentLocations(newLocations) {
    recentLocations.value = [
      ...[...newLocations].reverse(),
      ...recentLocations.value.filter((location) => !newLocations.includes(location)),
    ].slice(0, MAX_RECENT_LOCATIONS);
    browserStorageSetItem(RECENT_LOCATIONS_KEY, JSON.stringify(recentLocations.value));
  }

  function getCreatorProfilePhoto() {
    if (creator?.value?.profilePhotoUrl) return creator.value.profilePhotoUrl;
    return null;
  }

  async function searchAccounts({
    source,
    followersGreaterThan,
    followersLessThan,
    bioIncludes,
    bioDoesNotInclude,
    captionsInclude,
    captionsDoNotInclude,
    bioOrCaptionsInclude,
    hasMentionedBrand,
    hasPostedBetween,
    interests,
    countries,
    visualFeatures,
    languages,
    minAvgViews,
    maxAvgViews,
    tiktok,
    sortBy,
    brandId,
    minEngagementRate,
    maxEngagementRate,
    mobileOS,
    audience,
    limit,
    afterCursor,
    hasPermission = true,
  }) {
    if (source !== 'INSTAGRAM' && !hasPermission) {
      throw new Error('Teaser results are only supported for Instagram!');
    }

    // Reset the error value before fetching.
    error.value.accounts = null;

    const requestBody = {
      brandId,
      sources: [source],
      followersGreaterThan,
      followersLessThan,
      bioIncludes,
      bioDoesNotInclude,
      captionsInclude,
      captionsDoNotInclude,
      bioOrCaptionsInclude,
      hasMentionedBrand,
      hasPostedBetween,
      interests,
      countryCodes: source === 'INSTAGRAM' ? countries?.map(getCountryCode) : countries,
      languages,
      minAvgViews,
      maxAvgViews,
      visualFeatures,
      audience,
      tiktok,
      sortBy,
      minEngagementRate,
      maxEngagementRate,
      mobileOS,
      limit,
      afterCursor,
      hasPermission,
    };

    // If we have already loaded accounts with the provided parameters, we
    // can skip making the request again.
    if (isEqual(requestBody, paging.value.lastRequestBody)) {
      return;
    }

    // Cancel pending request, if there is one
    accountsCancelToken.value?.cancel();

    const searchAccountsFunction = hasPermission
      ? CreatorsAPI.searchAccounts
      : CreatorsAPI.searchAccountsForTeaser;
    try {
      pendingCounts.value.accounts += 1;
      accounts.value = [];
      paging.value.lastRequestBody = null;
      accountsCancelToken.value = CancelToken.source();
      const response = await searchAccountsFunction(requestBody, {
        cancelToken: accountsCancelToken.value.token,
      });
      const payload = hasPermission ? response.data : response;
      accounts.value = payload?.data;
      paging.value.nextCursor = payload?.paging?.nextCursor;
      paging.value.lastRequestBody = requestBody;
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        error.value.accounts = e.response;
        throw e;
      }
    } finally {
      if (pendingCounts.value.accounts > 0) pendingCounts.value.accounts -= 1;
    }
  }

  async function fetchAccount({ source, sourceCreatorId, handle, brandId }, axiosConfig = {}) {
    let existingAccount;
    if (sourceCreatorId) {
      existingAccount = mappedAccounts.value[source]?.[sourceCreatorId];
    }
    if (existingAccount && paging.value.lastRequestBody?.brandId === brandId) {
      account.value = existingAccount;
      return existingAccount;
    }

    pendingCounts.value.account += 1;
    try {
      const response = await CreatorsAPI.fetchAccount(
        { source, sourceCreatorId, handle, brandId },
        axiosConfig,
      );
      account.value = response.data.data;
      return response.data.data;
    } catch (err) {
      if (err?.response?.status !== 404) throw err;
      return null;
    } finally {
      pendingCounts.value.account -= 1;
    }
  }

  async function fetchTikTokAccount({ brandId, handle }) {
    accountCancelToken.value.tiktok?.cancel();
    accountCancelToken.value.tiktok = CancelToken.source();
    try {
      tikTokAccount.value = await fetchAccount(
        { brandId, handle, source: creatorSource.TIKTOK },
        { cancelToken: accountCancelToken.value.tiktok.token },
      );
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    }
  }

  async function clearTikTokAccount() {
    accountCancelToken.value.tiktok?.cancel();
    tikTokAccount.value = null;
    account.value = null;
  }

  async function fetchInstagramAccount({ brandId, sourceCreatorId }) {
    accountCancelToken.value.instagram?.cancel();
    accountCancelToken.value.instagram = CancelToken.source();
    try {
      instagramAccount.value = await fetchAccount(
        {
          brandId,
          sourceCreatorId,
          source: creatorSource.INSTAGRAM,
        },
        { cancelToken: accountCancelToken.value.instagram.token },
      );
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    }
  }

  async function clearInstagramAccount() {
    accountCancelToken.value.instagram?.cancel();
    instagramAccount.value = null;
    account.value = null;
  }

  async function fetchAccountsNextPage() {
    if (!hasAccountsNextPage.value || pending.value.accounts) {
      return;
    }

    pendingCounts.value.accounts += 1;
    try {
      accountsCancelToken.value = CancelToken.source();
      const response = await CreatorsAPI.searchAccounts(
        {
          ...paging.value.lastRequestBody,
          afterCursor: paging.value.nextCursor,
        },
        { cancelToken: accountsCancelToken.value.token },
      );

      const payload = response.data;
      const data = payload.data;
      accounts.value = [...accounts.value, ...data];
      paging.value.nextCursor = payload.paging.nextCursor;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.accounts -= 1;
    }
  }

  function removeAccount({ source, sourceCreatorId, handle }) {
    accounts.value = accounts.value.filter(
      (accountValue) =>
        accountValue.source !== source ||
        (sourceCreatorId != null
          ? accountValue.sourceCreatorId !== sourceCreatorId
          : accountValue.handle !== handle),
    );
  }

  function clearAccounts() {
    accountsCancelToken.value?.cancel();
    accountsCancelToken.value = null;
    creatorsCancelToken.value?.cancel();
    creatorsCancelToken.value = null;
    accounts.value = [];
    creators.value = [];
    pendingCounts.value = {
      account: 0,
      accounts: 0,
      creators: 0,
      creator: 0,
      creatorDetails: 0,
      creatorsCount: 0,
      brandRelationshipTags: 0,
    };
    paging.value = {
      nextCursor: null,
      lastRequestBody: null,
    };
  }

  async function fetchAllBrandCreators() {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    try {
      pendingCounts.value.creators += 1;

      let nextCursor;
      const data = [];

      const fetchBrandCreatorsRecursive = async () => {
        const response = await CreatorsAPI.fetchBrandCreators({ afterCursor: nextCursor, brandId });
        data.push(...response.data.data);
        nextCursor = response.data.paging.nextCursor;

        if (nextCursor) {
          await fetchBrandCreatorsRecursive();
        }
      };

      await fetchBrandCreatorsRecursive();

      creators.value = data;
    } finally {
      pendingCounts.value.creators = false;
    }
  }

  async function fetchBrandCreators({ creatorIds }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.creators += 1;
    try {
      const response = await CreatorsAPI.fetchBrandCreators({
        brandId,
        creatorIds,
      });
      creators.value = response.data.data;
    } finally {
      pendingCounts.value.creators -= 1;
    }
    return creators.value;
  }

  async function removeRelationshipAccount({ creatorId, source }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.removeRelationshipAccount += 1;
    try {
      const response = await CreatorsAPI.removeRelationshipAccount({
        brandId,
        creatorId,
        source,
      });
      creator.value = response.data.data[0];
    } finally {
      pendingCounts.value.removeRelationshipAccount -= 1;
    }
  }

  async function getAccountPosts({ brandId, limit, offset, sortFields, filters, includeCreator }) {
    pendingCounts.value.accountPosts += 1;

    try {
      const signal = refreshAbortController(abortControllers.getAccountPosts);

      const response = await LibraryAPI.getMediaListV2(
        {
          brandId,
          limit,
          offset,
          sortFields,
          filters: { ...filters, includeDisconnectedStoryUgc: true },
          includeCreator,
          url: postsPaging?.value?.accountPostsNextUrl,
        },
        { signal },
      );
      const payload = response.status === 200 ? response.data : response;

      if (payload.data) {
        // we only set includeCreator if the source is INSTAGRAM, so will add it to instagramAccountPosts
        if (includeCreator) {
          instagramAccountPosts.value = sanitizeStoriesFromDMs([
            ...instagramAccountPosts.value,
            ...payload.data,
          ]);
        } else tikTokRIQPosts.value = [...tikTokRIQPosts.value, ...payload.data];

        postsPaging.value.accountPostsNextUrl = payload?.paging?.next;
      }

      return payload;
    } catch (e) {
      return handleCancelError(e);
    } finally {
      pendingCounts.value.accountPosts -= 1;
    }
  }

  function clearCreator() {
    pendingCounts.value.creator = 0;
    pendingCounts.value.creatorDetails = 0;
    editCreator.value = false;
    creator.value = undefined;
    instagramInvitationEmailSent.value = false;
  }

  async function fetchCreator({
    creatorId,
    postsPublishedStartDate,
    postsPublishedEndDate,
    instagramPostType,
    isFetchingMetrics,
  }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    // Since we're also using this wrapper for fetching RIQ posts metrics, we don't want creator profile to be skeleton loading
    if (!isFetchingMetrics) pendingCounts.value.creator += 1;
    try {
      const response = await CreatorsAPI.fetchBrandCreators({
        brandId,
        creatorIds: [creatorId],
        postsPublishedEndDate,
        postsPublishedStartDate,
        instagramPostType,
      });
      if (response.data.data.length > 0) creator.value = response.data.data[0];
    } finally {
      if (!isFetchingMetrics) pendingCounts.value.creator -= 1;
    }
  }

  async function fetchCreatorByHandle({ handle, channel, isFetchingMetrics, shouldFetchDetails }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    if (!isFetchingMetrics) pendingCounts.value.creator += 1;

    try {
      let response = await CreatorsAPI.searchCreators({
        brandId,
        limit: 1,
        lookupName: handle,
        restrictToPlatforms: [channel],
      });

      // can only get custom creator details (about section etc.) if you specifically search by ID
      if (shouldFetchDetails && response.data.data.length > 0) {
        const basicCreator = response.data.data[0];
        response = await CreatorsAPI.fetchBrandCreators({
          brandId,
          creatorIds: [basicCreator.creatorId],
        });
      }
      if (response.data.data.length > 0) creator.value = response.data.data[0];
    } finally {
      if (!isFetchingMetrics) pendingCounts.value.creator -= 1;
    }
  }

  async function updateCreatorDetails({ creatorId, creatorDetails }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.creatorDetails += 1;
    try {
      await CreatorsAPI.updateCreatorDetails({
        brandId,
        creatorId,
        creatorDetails,
      });
      const response = await CreatorsAPI.fetchBrandCreators({
        brandId,
        creatorIds: [creatorId],
      });
      creator.value = response.data.data[0];
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creatorDetails -= 1;
    }
  }

  function clearAccountsPosts() {
    instagramAccountPosts.value = [];
    tikTokRIQPosts.value = [];
    postsPaging.value.accountPostsNextUrl = null;
  }

  function clearBrandRelationshipTags() {
    brandRelationshipTags.value = [];
  }

  function clearAccount() {
    account.value = null;
  }

  async function fetchBrandRelationshipTags() {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    try {
      pendingCounts.value.brandRelationshipTags += 1;

      let nextUrl;
      const data = [];

      const fetchBrandRelationshipTagsRecursive = async () => {
        const response = await CreatorsAPI.fetchBrandRelationshipTags({ url: nextUrl, brandId });
        data.push(...response.data.data);
        nextUrl = response.data.paging.next;

        if (nextUrl) {
          await fetchBrandRelationshipTagsRecursive();
        }
      };

      await fetchBrandRelationshipTagsRecursive();

      brandRelationshipTags.value = data;
    } finally {
      pendingCounts.value.brandRelationshipTags -= 1;
    }
  }

  async function fetchInformationRequest(token) {
    try {
      creatorInformationRequest.value = await CreatorsAPI.fetchInformationRequest(token);
      return creatorInformationRequest.value;
    } catch (e) {
      return e.response;
    }
  }

  async function submitInformationRequest({ token, creatorData }) {
    try {
      pendingSubmitIRF.value = true;
      return await CreatorsAPI.submitInformationRequest({ token, creatorData });
    } finally {
      pendingSubmitIRF.value = false;
    }
  }

  async function deleteRelationship({ creatorId }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    const creatorIds = [creatorId];
    pendingCounts.value.deleteRelationship += 1;
    try {
      await CreatorsAPI.bulkDeleteRelationships({
        brandId,
        creatorIds,
      });

      creators.value = creators.value.filter((t) => t.creatorId !== parseInt(creatorId, 10));
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.deleteRelationship -= 1;
    }
  }

  async function bulkDeleteRelationships({ creatorIds }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    pendingCounts.value.deleteRelationship += 1;
    try {
      await CreatorsAPI.bulkDeleteRelationships({
        brandId,
        creatorIds,
      });

      creators.value = creators.value.filter((t) => !creatorIds.includes(t.creatorId));
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.deleteRelationship -= 1;
    }
  }

  async function addTagToRelationship({ creatorId, name, color }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.addBrandRelationshipTags += 1;
    try {
      const response = await CreatorsAPI.addRelationshipTag({
        brandId,
        creatorId,
        name,
        color,
      });
      const payload = response.data;
      creator.value.tags.push(payload.name);
      creators.value?.find((c) => c.creatorId === creatorId)?.tags?.push(payload.name);

      // Check if brandrelationship tag has the tag else update it
      const index = brandRelationshipTags.value.findIndex((tag) => tag.name === name);
      if (index === -1) {
        brandRelationshipTags.value = [payload, ...brandRelationshipTags.value];
      }
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.addBrandRelationshipTags -= 1;
    }
  }

  async function bulkAddTagsToRelationships({ creatorIds, tags }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.addBrandRelationshipTags += 1;
    try {
      const response = await CreatorsAPI.bulkAddRelationshipTags({
        brandId,
        creatorIds,
        tags,
      });
      const newTags = response.data.data.filter(
        (tag) => !brandRelationshipTags.value.find((t) => t.id === tag.id),
      );
      brandRelationshipTags.value = [...brandRelationshipTags.value, ...newTags];
      creatorIds.forEach((creatorId) => {
        const matchingCreatorFromList = creators.value.find((c) => c.creatorId === creatorId);
        if (matchingCreatorFromList) {
          matchingCreatorFromList.tags = response.data.data.map((tag) => tag.name);
        }
        if (creator.value?.creatorId === creatorId) {
          creator.value.tags = response.data.data.map((tag) => tag.name);
        }
      });
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.addBrandRelationshipTags -= 1;
    }
  }

  async function removeTagFromRelationship({ creatorId, tagId }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.deleteBrandRelationshipTags += 1;
    try {
      await CreatorsAPI.deleteRelationshipTag({
        brandId,
        creatorId,
        tagId,
      });
      const label = brandRelationshipTags.value.find((item) => item.id === tagId);
      creator.value.tags = creator.value.tags.filter((t) => t !== label.name);
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.deleteBrandRelationshipTags -= 1;
    }
  }

  async function createRelationships({ creatorAccounts }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    pendingCounts.value.relationships += 1;
    try {
      const creatorResponse = await CreatorsAPI.findOrCreateCreators({
        brandId,
        creators: creatorAccounts,
      });
      const creatorIds = creatorResponse.data.data.map(({ id }) => id);
      await CreatorsAPI.createRelationships({
        brandId,
        creatorIds,
      });

      return creatorIds;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
      return null;
    } finally {
      pendingCounts.value.relationships -= 1;
    }
  }

  // Your Creators Related State
  const creatorsPaging = ref({
    nextCursor: null,
    requestBody: null,
  });

  async function fetchYourCreators(
    {
      limit,
      sort,
      lookupName,
      restrictToPlatforms,
      postsPublishedStartDate,
      postsPublishedEndDate,
      tagFilterType,
      tags,
      hasMentionedBrand,
      instagramPostType,
      instagramConnectionStatus,
      instagramFollowersGreaterThan,
      instagramFollowersLessThan,
      instagramInterests,
      instagramRegions,
      instagramIsBusiness,
      instagramHandle,
      tiktokConnectionStatus,
      tiktokFollowersGreaterThan,
      tiktokFollowersLessThan,
      tiktokTTCMStatus,
      tiktokInterests,
      tiktokRegions,
      tiktokAudienceRegions,
      tiktokAudienceGender,
      tiktokAudienceAge,
      tiktokHandle,
    },
    refresh = true,
  ) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    if (refresh) creators.value = [];
    pendingCounts.value.creators += 1;
    creatorsPaging.value.requestBody = {
      limit,
      sort,
      lookupName,
      restrictToPlatforms,
      postsPublishedStartDate,
      postsPublishedEndDate,
      tagFilterType,
      tags,
      hasMentionedBrand,
      instagramPostType,
      instagramConnectionStatus,
      instagramFollowersGreaterThan,
      instagramFollowersLessThan,
      instagramInterests,
      instagramRegions,
      instagramIsBusiness,
      instagramHandle,
      tiktokConnectionStatus,
      tiktokFollowersGreaterThan,
      tiktokFollowersLessThan,
      tiktokTTCMStatus,
      tiktokInterests,
      tiktokRegions,
      tiktokAudienceRegions,
      tiktokAudienceGender,
      tiktokAudienceAge,
      tiktokHandle,
    };
    creatorsCancelToken.value?.cancel();
    creatorsCancelToken.value = CancelToken.source();
    try {
      const response = await CreatorsAPI.searchCreators(
        {
          brandId,
          ...creatorsPaging.value.requestBody,
        },
        {
          cancelToken: creatorsCancelToken.value.token,
        },
      );
      creators.value = response.data.data;
      creatorsPaging.value.nextCursor = response.data.paging.nextCursor;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creators -= 1;
    }
  }

  async function fetchYourCreatorsNextPage() {
    if (!creatorsPaging.value.nextCursor) return;
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    pendingCounts.value.creators += 1;
    creatorsCancelToken.value = CancelToken.source();
    try {
      const response = await CreatorsAPI.searchCreators(
        {
          brandId,
          ...creatorsPaging.value.requestBody,
          afterCursor: creatorsPaging.value.nextCursor,
        },
        { cancelToken: creatorsCancelToken.value.token },
      );
      creators.value = [...creators.value, ...response.data.data];
      creatorsPaging.value.nextCursor = response.data.paging.nextCursor;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creators -= 1;
    }
  }

  async function fetchCreatorsCount() {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    try {
      pendingCounts.value.creatorsCount += 1;
      const response = await CreatorsAPI.fetchCreatorsCount({ brandId });
      creatorsCount.value = response.data.paging.size;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.creatorsCount -= 1;
    }
  }

  async function fetchTikTokAccountPosts({
    brandId,
    source,
    handle,
    includeAuthorized,
    cache,
    limit,
  }) {
    pendingCounts.value.tikTokAccountPosts += 1;

    try {
      const signal = refreshAbortController(abortControllers.fetchTikTokAccountPosts);

      const response = await CreatorsAPI.fetchAccountPosts(
        {
          brandId,
          source,
          handle,
          includeAuthorized,
          cache,
          afterCursor: postsPaging.value.tikTokPostsNextCursor,
          limit,
        },
        { signal },
      );
      tikTokAccountPosts.value = [...tikTokAccountPosts.value, ...response.data.data];
      postsPaging.value.tikTokPostsNextCursor = response.data.paging.nextCursor;
    } catch (e) {
      handleCancelError(e);
    } finally {
      pendingCounts.value.tikTokAccountPosts -= 1;
    }
  }

  function clearTikTokAccountPosts() {
    postsPaging.value.tikTokPostsNextCursor = null;
    pendingCounts.value.tikTokAccountPosts = 0;
    tikTokAccountPosts.value = [];
  }

  async function fetchAccountMetrics({ source, handle, brandId }) {
    pendingCounts.value.accountMetrics += 1;
    try {
      const response = await CreatorsAPI.fetchAccountMetrics({ source, handle, brandId });
      accountMetrics.value = response.data.data;
    } catch (err) {
      if (err?.response?.status === 404) accountMetrics.value = null;
      else throw err;
    } finally {
      pendingCounts.value.accountMetrics -= 1;
    }
  }

  function clearAccountMetrics() {
    accountMetrics.value = null;
  }

  async function updateInvitationStatus({
    source,
    sourceCreatorId,
    invitedByBrandId,
    emailContent,
    invitationStatus = undefined,
  }) {
    try {
      const res = await CreatorsAPI.updateInvitationStatus({
        source,
        sourceCreatorId,
        invitedByBrandId,
        invitationStatus,
        emailContent,
      });
      return res;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
      return e.response;
    }
  }

  async function createInformationRequest() {
    pendingCounts.value.createInformationRequest += 1;

    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;
    try {
      const res = await CreatorsAPI.createInformationRequest({
        brandId,
        creatorId: creator.value.creatorId,
      });
      const token = res.data.token;

      const url = new URL(`${env.dashwebUrl}/information-request-form`);
      url.searchParams.set('t', token);

      informationRequestData.value.url = url.href;
      informationRequestData.value.token = token;
      informationRequestData.value.id = res.data.id;
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    } finally {
      pendingCounts.value.createInformationRequest -= 1;
    }
  }

  async function addRelationshipAccount({ creatorId, handle, source }) {
    const identityStore = useIdentityStore();
    const brandId = identityStore.currentBrand.id;

    try {
      const response = await CreatorsAPI.addRelationshipAccount({
        brandId,
        creatorId,
        handle,
        source,
      });
      creator.value = response.data.data[0];
    } catch (e) {
      if (!(e instanceof CanceledError)) throw e;
    }
  }

  function onReloadCreatorProfile(callback) {
    if (typeof callback === 'function') {
      onReloadCreatorProfileCallback.value = callback;
    }
  }

  function triggerCreatorProfileReload() {
    if (onReloadCreatorProfileCallback.value) {
      onReloadCreatorProfileCallback.value();
    }
  }

  function setInstagramInvitationEmailSent(value) {
    instagramInvitationEmailSent.value = value;
  }

  return {
    account,
    tikTokAccount,
    instagramAccount,
    accounts,
    accountsBySource,
    pending,
    hasAccountsNextPage,
    searchAccounts,
    clearAccount,
    fetchAccount,
    fetchTikTokAccount,
    fetchInstagramAccount,
    fetchAccountsNextPage,
    removeAccount,
    clearTikTokAccount,
    clearInstagramAccount,
    clearAccounts,
    recentLocations,
    addRecentLocations,
    paging,
    creators,
    fetchAllBrandCreators,
    fetchBrandCreators,
    getAccountPosts,
    instagramAccountPosts,
    brandRelationshipTags,
    clearBrandRelationshipTags,
    fetchBrandRelationshipTags,
    fetchInformationRequest,
    submitInformationRequest,
    pendingSubmitIRF,
    creatorInformationRequest,
    informationRequestData,
    updateCreatorDetails,
    creator,
    editCreator,
    fetchCreator,
    fetchCreatorByHandle,
    clearCreator,
    clearAccountsPosts,
    deleteRelationship,
    bulkDeleteRelationships,
    addTagToRelationship,
    bulkAddTagsToRelationships,
    removeTagFromRelationship,
    creatorsPaging,
    fetchYourCreators,
    fetchYourCreatorsNextPage,
    createRelationships,
    removeRelationshipAccount,
    creatorsCount,
    fetchCreatorsCount,
    tikTokAccountPosts,
    tikTokRIQPosts,
    fetchTikTokAccountPosts,
    clearTikTokAccountPosts,
    accountMetrics,
    fetchAccountMetrics,
    clearAccountMetrics,
    updateInvitationStatus,
    postsPaging,
    error,
    getCreatorProfilePhoto,
    createInformationRequest,
    onReloadCreatorProfile,
    triggerCreatorProfileReload,
    instagramInvitationEmailSent,
    setInstagramInvitationEmailSent,
    addRelationshipAccount,
    creatorProfileDetailsFormValidation,
    unsavedCreatorData,
  };
});
