import { defineAsyncComponent } from 'vue';
import LDRouteGuard from '@/components/core/LDRouteGuard';
import { constants } from '@/config';

const App = () => import('@/app/tiktok/App.vue');
const TikTokGalleries = () => import('@/app/tiktok/pages/Galleries.vue');
const Gallery = () => import('@/components/Galleries/Gallery.vue');
const TikTokGalleryEdit = () => import('@/app/tiktok/pages/TikTokGalleryEdit.vue');
const Overview = () => import('@/app/tiktok/pages/Overview.vue');
const YourPosts = () => import('@/app/tiktok/pages/YourPosts.vue');
const Ugc = defineAsyncComponent(() => import('@/app/tiktok/pages/Ugc.vue'));
const TrendingSounds = defineAsyncComponent(() => import('@/app/tiktok/pages/TrendingSounds.vue'));
const TrendingHashtags = defineAsyncComponent(
  () => import('@/app/tiktok/pages/TrendingHashtags.vue'),
);
const TikTokLikeShop = () => import('@/app/ecommerce/pages/LikeShopWrapper.vue');

const PAGE_LEVEL_TOP = `TikTok`;

export default {
  path: 'tiktok',
  component: App,
  redirect: { name: 'tiktok.overview' },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      name: 'tiktok',
      path: '',
      redirect: { name: 'tiktok.overview' },
    },
    {
      name: 'tiktok.overview',
      path: 'overview',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: 'tiktok.yourPosts',
      path: 'your-posts',
      component: YourPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Your Posts`,
      },
    },
    {
      name: 'tiktok.ugc',
      path: 'ugc',
      component: LDRouteGuard,
      props: {
        component: Ugc,
        requiredFeatureFlag: 'tiktokUgc',
        to: { name: 'tiktok.overview' },
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - UGC`,
      },
    },
    {
      path: 'boards',
      children: [
        {
          name: 'tiktok.galleries',
          path: '',
          component: TikTokGalleries,
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Boards`,
          },
          children: [
            {
              name: 'tiktok.galleries.new',
              path: 'new',
              component: TikTokGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - New`,
              },
            },
          ],
        },
        {
          name: 'tiktok.gallery.detail',
          path: ':id',
          component: Gallery,
          props: {
            galleryType: constants.TIKTOK,
          },
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - Board Detail`,
          },
          children: [
            {
              name: 'tiktok.galleries.edit',
              path: 'edit',
              component: TikTokGalleryEdit,
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - Boards - Edit`,
              },
            },
          ],
        },
      ],
    },
    {
      name: 'tiktok.trendingSounds',
      path: 'trending-sounds',
      component: LDRouteGuard,
      props: {
        component: TrendingSounds,
        requiredFeatureFlag: 'tiktokTrendingSounds',
        to: { name: 'tiktok.overview' },
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Trending Sounds`,
      },
    },
    {
      name: 'tiktok.trendingHashtags',
      path: 'trending-hashtags',
      component: LDRouteGuard,
      props: {
        component: TrendingHashtags,
        requiredFeatureFlag: 'tikTokTrendingHashtags',
        to: { name: 'tiktok.overview' },
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Trending Hashtags`,
      },
    },
    {
      name: 'tiktok.likeshop',
      path: 'likeshop',
      component: TikTokLikeShop,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - LikeShop`,
      },
    },
  ],
};
