<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { Avatar, Button } from '@dashhudson/dashing-ui';
import PrimaryNavigationMenu from '@/components/layout/navigation/PrimaryNavigationMenu.vue';
import { branding } from '@/config';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import SecondaryNavigationPanel from '@/components/layout/navigation/SecondaryNavigationPanel.vue';
import NotificationsPanel from '@/components/layout/navigation/NotificationsPanel.vue';
import UtilityPanel from '@/components/layout/navigation/UtilityPanel.vue';
import { trackNavigation, trackSecondaryNavigationInteractions } from '@/utils/analytics/tracking';

const props = defineProps({
  hideToggleButton: { type: Boolean, default: false },
});

const {
  toggleNavigationPanel,
  expanded: isExpanded,
  hidden: secondaryNavHidden,
} = useSecondaryNavigationPanel();

const route = useRoute();
const hideSecondaryNavigation = computed(
  () => route?.meta?.hideSecondaryNav || secondaryNavHidden.value,
);

function handleSecondaryNavigationToggleClick() {
  toggleNavigationPanel();
  trackSecondaryNavigationInteractions(!isExpanded.value ? 'closed' : 'opened');
}
</script>

<template>
  <header
    class="fixed bottom-0 left-0 top-0 z-[var(--z-index-nav)] grid h-full w-[var(--primary-navigation-width)] items-start bg-white"
    data-primary-nav
  >
    <nav class="grid h-full w-full bg-white py-6">
      <div class="z-10 flex h-full flex-col items-center justify-between">
        <div>
          <router-link
            to="/"
            class="dash-rounded-sm mx-1 mb-9 block h-8 w-8 flex-col items-center focus:outline-[color:--action-500]"
            @click="trackNavigation('Landing Page')"
          >
            <span class="visually-hidden"> Go to the Dash Social home page </span>
            <Avatar :image="branding.iconPrimary" size="md" class="bg-white" />
          </router-link>
          <PrimaryNavigationMenu @active-item-click="handleSecondaryNavigationToggleClick" />
        </div>
        <div class="grid place-items-center gap-3">
          <NotificationsPanel />
          <UtilityPanel />
        </div>
      </div>
      <Button
        v-show="!hideSecondaryNavigation && !props.hideToggleButton"
        icon="layout-left"
        size="xs"
        aria-label="toggle"
        class="dash-transition-md absolute z-20 translate-y-1"
        :class="[isExpanded ? 'right-[-15.5rem]' : 'right-0 translate-x-2/4']"
        severity="tertiary"
        @click="handleSecondaryNavigationToggleClick"
      />
      <SecondaryNavigationPanel
        v-show="!hideSecondaryNavigation"
        class="-z-10"
        :width="isExpanded ? 276 : 0"
      />
    </nav>
  </header>
</template>

<style lang="postcss">
.hs-web-interactives-top-banner-open {
  [data-primary-nav] {
    height: calc(100% - var(--hubspot-banner-height)) !important;
    top: var(--hubspot-banner-height) !important;
  }
}
</style>
