<script setup>
import { Button, useFilterSidePanel } from '@dashhudson/dashing-ui';
import { useHubspotStore } from '@/stores/hubspot';

const hubspotStore = useHubspotStore();

const { visible: filterVisible } = useFilterSidePanel();

function onToggleChat() {
  hubspotStore.toggle();
}
</script>

<template>
  <div
    :class="[
      'dash-transition-md fixed bottom-4 flex flex-col gap-3 print:hidden',
      {
        'right-4': !filterVisible,
        'right-[23rem]': filterVisible,
      },
    ]"
    :style="{ zIndex: '9999' }"
  >
    <Transition name="expand">
      <div
        v-show="hubspotStore.showConversation"
        id="dh-hubspot-chat"
        class="dash-elevation-5xl dash-rounded-lg origin-bottom-right overflow-hidden"
        :style="{ width: '376px', height: '530px' }"
      />
    </Transition>
    <div class="flex justify-end">
      <Button
        v-if="hubspotStore.enabled"
        size="lg"
        :icon="{ name: 'hubspot-chat', size: 22 }"
        severity="footer"
        aria-label="Support Chat"
        :disabled="!hubspotStore.ready"
        @click="onToggleChat"
      />
    </div>
  </div>
</template>
