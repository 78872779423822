<script setup>
/**
 * Video preview component that shows a list of thumbnails sampled from the video.
 *
 * This approach injects n different video elements and seeks them to the desired thumbnail
 * timestamp. Out of all the client-side video thumbnail approaches I've tried, this is the
 * fastest, but it's slow & unreliable in Firefox and Safari. Since the large majority of our
 * users are using Chrome, we decided it was worth it to serve the fast version to them.
 */
import { computed } from 'vue';
import { mediaUrl } from '@/app/scheduler/utils';

const props = defineProps({
  media: { type: Object, required: true },
  duration: { type: Number, default: null },
  height: { type: Number, default: 56 },
  width: { type: Number, default: 356 },
});
const videoSrc = computed(() => mediaUrl([props.media]));

const timestamps = computed(() => {
  const ts = [];
  const thumbInterval = props.duration / (props.width / props.height);
  for (let i = 0; i < props.duration; i += thumbInterval) {
    ts.push(i);
  }
  return ts;
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div id="thumbnails-container">
    <video
      v-for="timestamp in timestamps"
      :key="timestamp"
      :src="videoSrc"
      :style="{ width: `${props.height}px` }"
      @loadeddata="(e) => (e.target.currentTime = timestamp)"
    />
  </div>
</template>

<style lang="postcss" scoped>
#thumbnails-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: var(--round-corner-small);

  video {
    height: 100%;
    object-fit: cover;
  }
}
</style>
