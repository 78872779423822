import SchedulePostPopups from '@/app/scheduler/components/SchedulePostPopups.vue';
import { USER } from '@/models/auth/permissions.enum';

const App = () => import('@/app/scheduler/App.vue');
const FacebookReport = () => import('@/app/scheduler/pages/reports/FacebookReport.vue');
const FeedPreviewReport = () => import('@/app/scheduler/pages/reports/FeedPreviewReport.vue');
const InstagramReport = () => import('@/app/scheduler/pages/reports/InstagramReport.vue');
const PinterestReport = () => import('@/app/scheduler/pages/reports/PinterestReport.vue');
const TwitterReport = () => import('@/app/scheduler/pages/reports/TwitterReport.vue');
const GDSPageWrapper = () => import('@/app/scheduler/pages/GDSPageWrapper.vue');

const PAGE_LEVEL_TOP = `Scheduler`;
const PAGE_LEVEL_INSTAGRAM = `Instagram`;
const PAGE_LEVEL_LINKEDIN = 'LinkedIn';
const PAGE_LEVEL_PINTEREST = `Pinterest`;
const PAGE_LEVEL_TWITTER = `Twitter`;
const PAGE_LEVEL_FACEBOOK = `Facebook`;
const PAGE_LEVEL_TIKTOK = `TikTok`;

export default {
  path: 'scheduler',
  name: 'scheduler',
  redirect: { name: 'scheduler.index' },
  component: App,
  meta: {
    requiresAuth: true,
    permissions: [USER.SCHEDULER.CAN_ACCESS_SCHEDULER],
  },
  children: [
    {
      name: 'scheduler.index',
      path: 'overview',
      component: GDSPageWrapper,
      props: {
        target: 'overview',
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: 'scheduler.instagram',
      path: 'instagram',
      component: GDSPageWrapper,
      props: {
        target: 'instagram',
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM}`,
        schedulerRoute: true,
        permissions: [USER.SCHEDULER.CAN_ACCESS_SCHEDULER_IG],
      },
      redirect: { name: 'scheduler.instagram.timeline' },
      children: [
        {
          name: 'scheduler.instagram.timeline',
          path: 'timeline',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Timeline`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.instagram.feedPreview',
          path: 'feed',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Feed Preview`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.instagram.unscheduled',
          path: 'unscheduled',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Unscheduled`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.instagram.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Post`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'instagram',
                showPostEditorSidePanel: false,
                postType: 'FEED',
              },
            },
          },
          children: [
            {
              name: 'scheduler.instagram.posts.comments',
              path: 'comments',
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Post - Comments`,
                modal: {
                  component: SchedulePostPopups,
                  props: {
                    type: 'instagram',
                    showPostEditorSidePanel: true,
                  },
                },
                rootPath: '/:brandLabel/scheduler/instagram',
              },
            },
          ],
        },
        {
          name: 'scheduler.instagram.story.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Story`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'instagram',
                showPostEditorSidePanel: false,
                postType: 'STORY',
              },
            },
          },
        },
        {
          name: 'scheduler.instagram.reel.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Reel`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'instagram',
                showPostEditorSidePanel: false,
                postType: 'REEL',
              },
            },
          },
        },
      ],
    },
    {
      name: 'scheduler.instagram.report',
      path: 'instagram/report',
      component: InstagramReport,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      name: 'scheduler.instagram.feed.report',
      path: 'instagram/feed/report',
      component: FeedPreviewReport,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_INSTAGRAM} - Feed Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      name: 'scheduler.pinterest',
      path: 'pinterest',
      component: GDSPageWrapper,
      props: {
        target: 'pinterest',
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_PINTEREST}`,
        schedulerRoute: true,
        permissions: [USER.SCHEDULER.CAN_ACCESS_SCHEDULER_PINT],
      },
      redirect: { name: 'scheduler.pinterest.timeline' },
      children: [
        {
          name: 'scheduler.pinterest.timeline',
          path: 'timeline',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_PINTEREST} - Timeline`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.pinterest.unscheduled',
          path: 'unscheduled',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_PINTEREST} - Unscheduled`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.pinterest.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_PINTEREST} - Post`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'pinterest',
                showPostEditorSidePanel: false,
              },
            },
          },
          children: [
            {
              name: 'scheduler.pinterest.posts.comments',
              path: 'comments',
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_PINTEREST} - Post - Comments`,
                modal: {
                  component: SchedulePostPopups,
                  props: {
                    type: 'pinterest',
                    showPostEditorSidePanel: true,
                  },
                },
                rootPath: '/:brandLabel/scheduler/pinterest',
              },
            },
          ],
        },
        {
          name: 'scheduler.pinterest.posts.new.bulk',
          path: 'posts/new/bulk',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_PINTEREST} - Post - New Bulk`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'pinterestBulk',
              },
            },
          },
        },
      ],
    },
    {
      name: 'scheduler.pinterest.report',
      path: 'pinterest/report',
      component: PinterestReport,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_PINTEREST} - Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      path: 'twitter:pathMatch(.*)*',
      redirect: (to) => ({ ...to, path: to.path.replace('/twitter', '/x') }),
    },
    {
      name: 'scheduler.twitter',
      path: 'x',
      component: GDSPageWrapper,
      props: {
        target: 'twitter',
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TWITTER}`,
        schedulerRoute: true,
        permissions: [USER.SCHEDULER.CAN_ACCESS_SCHEDULER_TWIT],
      },
      redirect: { name: 'scheduler.twitter.timeline' },
      children: [
        {
          name: 'scheduler.twitter.timeline',
          path: 'timeline',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TWITTER} - Timeline`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.twitter.unscheduled',
          path: 'unscheduled',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TWITTER} - Unscheduled`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.twitter.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TWITTER} - Post`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'twitter',
                showPostEditorSidePanel: false,
              },
            },
          },
          children: [
            {
              name: 'scheduler.twitter.posts.comments',
              path: 'comments',
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TWITTER} - Post - Comments`,
                modal: {
                  component: SchedulePostPopups,
                  props: {
                    type: 'twitter',
                    showPostEditorSidePanel: true,
                  },
                },
                rootPath: '/:brandLabel/scheduler/twitter',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'scheduler.twitter.report',
      path: 'twitter/report',
      component: TwitterReport,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TWITTER} - Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      name: 'scheduler.facebook',
      path: 'facebook',
      component: GDSPageWrapper,
      props: {
        target: 'facebook',
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_FACEBOOK}`,
        schedulerRoute: true,
        permissions: [USER.SCHEDULER.CAN_ACCESS_SCHEDULER_FB],
      },
      redirect: { name: 'scheduler.facebook.timeline' },
      children: [
        {
          name: 'scheduler.facebook.timeline',
          path: 'timeline',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_FACEBOOK} - Timeline`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.facebook.unscheduled',
          path: 'unscheduled',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_FACEBOOK} - Unscheduled`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.facebook.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_FACEBOOK} - Post`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'facebook',
                showPostEditorSidePanel: false,
              },
            },
          },
          children: [
            {
              name: 'scheduler.facebook.posts.comments',
              path: 'comments',
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_FACEBOOK} - Post - Comments`,
                modal: {
                  component: SchedulePostPopups,
                  props: {
                    type: 'facebook',
                    showPostEditorSidePanel: true,
                  },
                },
                rootPath: '/:brandLabel/scheduler/facebook',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'scheduler.facebook.report',
      path: 'facebook/report',
      component: FacebookReport,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_FACEBOOK} - Report`,
        report: true,
        hideNav: true,
        hideVisionAi: true,
      },
    },
    {
      name: 'scheduler.tiktok',
      path: 'tiktok',
      component: GDSPageWrapper,
      props: {
        target: 'tiktok',
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TIKTOK}`,
        schedulerRoute: true,
      },
      redirect: { name: 'scheduler.tiktok.timeline' },
      children: [
        {
          name: 'scheduler.tiktok.timeline',
          path: 'timeline',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TIKTOK} - Timeline`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.tiktok.unscheduled',
          path: 'unscheduled',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TIKTOK} - Unscheduled`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.tiktok.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TIKTOK} - Post`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'tiktok',
                showPostEditorSidePanel: false,
              },
            },
          },
          children: [
            {
              name: 'scheduler.tiktok.posts.comments',
              path: 'comments',
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_TIKTOK} - Post - Comments`,
                modal: {
                  component: SchedulePostPopups,
                  props: {
                    type: 'tiktok',
                    showPostEditorSidePanel: true,
                  },
                },
                rootPath: '/:brandLabel/scheduler/tiktok',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'scheduler.linkedin',
      path: 'linkedin',
      component: GDSPageWrapper,
      props: {
        target: 'linkedin',
      },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_LINKEDIN}`,
        schedulerRoute: true,
      },
      redirect: { name: 'scheduler.linkedin.timeline' },
      children: [
        {
          name: 'scheduler.linkedin.timeline',
          path: 'timeline',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_LINKEDIN} - Timeline`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.linkedin.unscheduled',
          path: 'unscheduled',
          component: {}, // Change the path, but remain on the same page
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_LINKEDIN} - Unscheduled`,
            schedulerRoute: true,
          },
        },
        {
          name: 'scheduler.linkedin.posts',
          path: 'posts/:id',
          meta: {
            analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_LINKEDIN} - Post`,
            modal: {
              component: SchedulePostPopups,
              props: {
                type: 'linkedin',
                showPostEditorSidePanel: false,
              },
            },
          },
          children: [
            {
              name: 'scheduler.linkedin.posts.comments',
              path: 'comments',
              meta: {
                analytics: `${PAGE_LEVEL_TOP} - ${PAGE_LEVEL_LINKEDIN} - Post - Comments`,
                modal: {
                  component: SchedulePostPopups,
                  props: {
                    type: 'linkedin',
                    showPostEditorSidePanel: true,
                  },
                },
                rootPath: '/:brandLabel/scheduler/linkedin',
              },
            },
          ],
        },
      ],
    },
  ],
};
