import { defineStore } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { useAuthStore } from '@/stores/auth';
import { useFullstoryStore } from '@/stores/fullstory';
import { logger } from '@/utils/logger';
import { env } from '@/env';
import { useTrackingStore } from '@/stores/tracking';

function registerTrackingSessionId() {
  const ddRumSessionId =
    datadogLogs?.getInternalContext()?.session_id ??
    datadogRum?.getInternalContext()?.session_id ??
    null;
  if (!ddRumSessionId) {
    logger.warn(`Datadog Session ID Missing`);
  }
  useTrackingStore().setSessionId(ddRumSessionId);
  return ddRumSessionId;
}

export const useDatadogStore = defineStore('datadog', () => {
  const authStore = useAuthStore();
  const fullstoryStore = useFullstoryStore();

  const enabled = env.datadogEnabled;
  const clientToken = env.datadogClientToken;
  const applicationId = env.datadogApplicationId;
  const mode = env.mode;
  const version = env.revision;

  function beforeSend(event) {
    registerTrackingSessionId();
    if (event.type === 'error' && fullstoryStore.isFullStoryEnabled) {
      event.context = {
        ...event.context,
        fullStory: {
          fullStoryUrl: fullstoryStore.getFullStoryUrl(),
        },
      };

      try {
        fullstoryStore.sendFullstoryEvent('RUM Error', { ...event });
      } catch (e) {
        logger.debug('Unable to report RUM error details to FullStory');
      }
    }
  }

  function init() {
    if (enabled) {
      const commonConfig = {
        clientToken,
        service: 'dash-web',
        env: mode,
        version,
      };
      // running vue 3 migration with datadog logs causes issues
      if (env.isProduction) {
        datadogLogs.init({
          ...commonConfig,
          sessionSampleRate: 25,
          forwardErrorsToLogs: false,
          forwardConsoleLogs: ['info', 'warn', 'error'],
        });
      }
      datadogRum.init({
        ...commonConfig,
        applicationId,
        allowedTracingUrls: [
          (url) => {
            // ChiliPiper calls include our full domain in the URL, causing x-datadog-* headers
            // being added to requests to ChiliPiper, who disallow those headers
            if (url.includes('chilipiper')) {
              return false;
            }
            return [
              /https:\/\/(?!cdn|images|mp).*\.dhdev\.co/,
              /https:\/\/(?!cdn|images|mp).*\.dashhudson\.com/,
              /https:\/\/(?!cdn|images|mp).*\.dashsocial\.com/,
            ].some((expr) => expr.test(url));
          },
        ],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        traceSampleRate: 100,
        beforeSend,
        enableExperimentalFeatures: ['feature_flags'],
      });
      registerTrackingSessionId();
    }
  }

  function setupUser() {
    if (enabled) {
      const identity = authStore.identity;
      const currentBrand = authStore.currentBrand;

      if (identity) {
        const user = {
          id: identity.id,
          name: `${identity.first_name} ${identity.last_name}`,
          email: identity.email,
        };
        datadogLogs.setUser(user);
        datadogRum.setUser(user);
      } else {
        datadogLogs.clearUser();
        datadogRum.clearUser();
      }

      if (currentBrand) {
        const brand = {
          id: currentBrand.id,
          name: currentBrand.name,
          label: currentBrand.label,
        };
        datadogLogs.setGlobalContextProperty('brand', brand);
        datadogRum.setGlobalContextProperty('brand', brand);
      } else {
        datadogLogs.removeGlobalContextProperty('brand');
        datadogRum.removeGlobalContextProperty('brand');
      }
    }
  }

  return {
    init,
    setupUser,
    beforeSend,
  };
});
