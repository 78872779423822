/**
 * Decode things like &amp; and &nbsp; found in Gmail snippets
 * @param {string} input A string containing HTML entities
 * @returns {string} A decoded plain text string
 */
export function decodeHtmlEntities(input) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

/**
 * Flatten the nested payload of a Gmail message into a list of parts
 * @param {object} payload The payload of a Gmail message
 * @returns {array} A flat list of message parts
 */
export function extractGmailMessageParts(payload) {
  return payload.parts ? payload.parts.flatMap(extractGmailMessageParts) : [payload];
}

/**
 * Turn headers list from a Gmail message into a map with lowercase keys
 * @param {array} headers The payload of a Gmail message
 * @returns {object} A map of headers with lowercase keys
 */
export function parseGmailHeaders(headers) {
  return Object.fromEntries(headers.map(({ name, value }) => [name.toLowerCase(), value]));
}

/**
 * Convert a Base64-encoded string to a Uint8Array representing binary data.
 * @param {string} base64 A Base64-encoded string representing binary data.
 * @returns {Uint8Array} A Uint8Array containing the decoded binary data.
 */
export function base64ToBinaryArray(base64) {
  const binaryString = atob(base64.replace(/-/g, '+').replace(/_/g, '/'));
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
}

/**
 * Convert a Base64-encoded string into a UTF-8 decoded string.
 * @param {string} base64 A Base64-encoded string representing UTF-8 text.
 * @returns {string} A UTF-8 decoded string.
 */
export function decodeBase64ToUTF8(base64) {
  const binaryArray = base64ToBinaryArray(base64);
  const decoder = new TextDecoder('utf-8');

  return decoder.decode(binaryArray);
}

/**
 * Extracts Google Drive links and their corresponding filenames from the raw HTML content of a message.
 *
 * @param {string} content The raw HTML content from the Gmail API message.
 * @returns {Array} An array of objects, each containing the link and filename.
 */
export function extractGoogleDriveLinks(content) {
  if (!content) return [];

  const linkAndNameRegex =
    /<a href="(https:\/\/drive\.google\.com\/open\?id=[^"]+)"[^>]*aria-label="([^"]+)"/g;

  const links = [];
  let match = linkAndNameRegex.exec(content);

  while (match !== null) {
    links.push({
      link: match[1],
      filename: match[2],
    });
    match = linkAndNameRegex.exec(content);
  }

  return links;
}
