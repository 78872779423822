import { getEnumText, getEnumValue } from '@/models/enum.utils';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';

export const CHANNELS = Object.freeze({
  TIKTOK: {
    value: 'TIKTOK',
    text: 'TikTok',
    channelIcon: 'tiktok',
    tooltip: 'Tik Tok',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_TIKTOK,
    typeOverride: {
      CONTENT_OWNED_STORIES: 'CONTENT_OWNED',
    },
  },
  INSTAGRAM: {
    value: 'INSTAGRAM',
    text: 'Instagram',
    ugcChannel: 'INSTAGRAM_UGC',
    channelIcon: 'instagram',
    channelMonoIcon: 'instagram-mono',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_INSTAGRAM,
    typeOverride: {
      CONTENT_OWNED_STORIES: 'CONTENT_OWNED',
    },
  },
  INSTAGRAM_STORIES: {
    value: 'INSTAGRAM_STORIES',
    text: 'Instagram Stories',
    channelIcon: 'instagramStories',
    tooltip: 'Instagram Story',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_IG_STORIES,
    typeOverride: {
      CONTENT_OWNED: 'CONTENT_OWNED_STORIES',
    },
  },
  INSTAGRAM_UGC: {
    value: 'INSTAGRAM_UGC',
    text: 'Instagram UGC',
    channelIcon: 'instagram',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_IG_UGC,
    hideFromChannelList: true,
  },
  INSTAGRAM_COMPETITIVE: {
    value: 'INSTAGRAM_COMPETITIVE',
    competitiveChannelType: 'INSTAGRAM',
    text: 'Instagram',
    channelIcon: 'instagram',
    chartColor: colours.CHART.WILDCARD,
    hideFromChannelList: true,
  },
  INSTAGRAM_BENCHMARKS: {
    value: 'INSTAGRAM_BENCHMARKS',
    text: 'Instagram',
    channelIcon: 'instagram',
    chartColor: '',
    hideFromChannelList: true,
  },
  FACEBOOK: {
    value: 'FACEBOOK',
    text: 'Facebook',
    channelIcon: 'facebook',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_FACEBOOK,
    typeOverride: {
      CONTENT_OWNED_STORIES: 'CONTENT_OWNED',
    },
  },
  FACEBOOK_COMPETITIVE: {
    value: 'FACEBOOK_COMPETITIVE',
    competitiveChannelType: 'FACEBOOK',
    text: 'Facebook',
    channelIcon: 'facebook',
    chartColor: colours.CHART.WILDCARD,
    hideFromChannelList: true,
  },
  PINTEREST: {
    value: 'PINTEREST',
    text: 'Pinterest',
    channelIcon: 'pinterest',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_PINTEREST,
    typeOverride: {
      CONTENT_OWNED_STORIES: 'CONTENT_OWNED',
    },
  },
  TWITTER: {
    value: 'TWITTER',
    text: 'X',
    channelIcon: 'twitter',
    channelMonoIcon: 'x-mono',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_TWITTER,
    typeOverride: {
      CONTENT_OWNED_STORIES: 'CONTENT_OWNED',
    },
  },
  TWITTER_COMPETITIVE: {
    value: 'TWITTER_COMPETITIVE',
    text: 'X',
    competitiveChannelType: 'TWITTER',
    channelIcon: 'twitter',
    chartColor: colours.CHART.WILDCARD,
    hideFromChannelList: true,
    hideFromCompetitiveChannelList: true,
  },
  YOUTUBE: {
    value: 'YOUTUBE',
    text: 'YouTube',
    channelIcon: 'youtube',
    channelMonoIcon: 'youtube-mono',
    tooltip: 'YouTube',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_YOUTUBE,
  },
  YOUTUBE_COMPETITIVE: {
    value: 'YOUTUBE_COMPETITIVE',
    competitiveChannelType: 'YOUTUBE',
    text: 'YouTube',
    channelIcon: 'youtube',
    chartColor: colours.CHART.WILDCARD,
    hideFromChannelList: true,
    hideFromCompetitiveChannelList: true,
  },
  LINKEDIN: {
    value: 'LINKEDIN',
    text: 'LinkedIn',
    channelIcon: 'linkedin',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_LINKEDIN,
  },
  THREADS: {
    value: 'THREADS',
    text: 'Threads',
    channelIcon: 'threads',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_THREADS,
  },
  META_ADS: {
    value: 'META_ADS',
    text: 'Meta Ads',
    channelIcon: 'meta',
    chartColor: colours.CHART_SOCIAL_CHANNEL.CHART_SC_META_ADS,
    hideFromChannelList: true,
  },
  TIKTOK_ADS: {
    value: 'TIKTOK_ADS',
    text: 'TikTok Ads',
    channelIcon: 'tiktok',
    hideFromChannelList: true,
  },
  MESSENGER: {
    value: 'MESSENGER',
    text: 'Messenger',
    channelIcon: 'messenger',
    hideFromChannelList: true,
  },
  AUDIENCE_NETWORK: {
    value: 'AUDIENCE_NETWORK',
    text: 'Meta Audience Network',
    channelIcon: 'audience_network',
    hideFromChannelList: true,
  },
  UNKNOWN: {
    value: 'UNKNOWN',
    text: 'Unknown',
    channelIcon: 'meta',
    hideFromChannelList: true,
  },
  INSTAGRAM_CREATORS: {
    value: 'INSTAGRAM_CREATORS',
    text: 'Instagram Creators',
    channelIcon: 'instagram',
    chartColor: colours.CHART.SET.CHART_SET_23,
    hideFromChannelList: true,
    hideFromCompetitiveChannelList: true,
  },
  TIKTOK_CREATORS: {
    value: 'TIKTOK_CREATORS',
    text: 'TikTok Creators',
    channelIcon: 'tiktok',
    chartColor: colours.CHART.SET.CHART_SET_24,
    hideFromChannelList: true,
    hideFromCompetitiveChannelList: true,
  },
});

export const ALL_CHANNELS_KEY = 'ALL_CHANNELS';

export const COMPETITIVE_CHANNELS = [
  CHANNELS.INSTAGRAM_COMPETITIVE.value,
  CHANNELS.TWITTER_COMPETITIVE.value,
  CHANNELS.YOUTUBE_COMPETITIVE.value,
  CHANNELS.FACEBOOK_COMPETITIVE.value,
];
export const MEDIA_TYPE_CHANNELS = [
  CHANNELS.INSTAGRAM.value,
  CHANNELS.INSTAGRAM_BENCHMARKS.value,
  CHANNELS.INSTAGRAM_COMPETITIVE.value,
  CHANNELS.FACEBOOK.value,
  CHANNELS.FACEBOOK_COMPETITIVE.value,
];
export const ENTERTAINMENT_SCORE_CHANNELS = [CHANNELS.INSTAGRAM.value, CHANNELS.TIKTOK.value];
export const META_ADS_CHANNELS = [CHANNELS.INSTAGRAM.value, CHANNELS.FACEBOOK.value];
export const ADS_CHANNELS = [CHANNELS.TIKTOK_ADS.value, CHANNELS.META_ADS.value];

export const CHANNEL_FLAGS = {
  // This enum will make an entire channel unavailable based on a feature flag
  // [Channel]:[featureFlagName],
  [CHANNELS.LINKEDIN.value]: ['linkedinDashboards'],
};

export const getChannelConfigProp = (channel, property) => {
  const channelKey = getEnumValue(channel);
  const channelConfig = CHANNELS[channelKey];
  return channelConfig?.[property];
};

export const getChannelTypeOverride = (channel, type) => {
  return CHANNELS?.[channel]?.typeOverride?.[type] || type;
};

export function getChannelText(channel) {
  const channelText = getChannelConfigProp(channel, 'text');
  return channelText || getEnumText(channel);
}

export function compareChannels(left, right) {
  const channels = Object.keys(CHANNELS);
  return channels.indexOf(left) - channels.indexOf(right);
}

export function filterChannelByFlags(flags) {
  return (channel) => {
    const filterOutChannelBasedOnFlag = channel in CHANNEL_FLAGS;
    if (filterOutChannelBasedOnFlag) {
      const flagsToCheck = CHANNEL_FLAGS[channel];
      return flagsToCheck.some((flag) => flags?.[flag]);
    }
    return true;
  };
}

export function filterNonCompetitiveChannels() {
  return (channel) => getChannelConfigProp(channel, 'competitiveChannelType');
}

export function filterChannelIfUnknown() {
  return (channel) => {
    return !!CHANNELS?.[channel];
  };
}

export function filterOutNonSelectableChannels() {
  return (channel) => {
    return !getChannelConfigProp(channel, 'hideFromChannelList');
  };
}

export function filterOutNonSelectableCompetitiveChannels() {
  return (channel) => {
    return !getChannelConfigProp(channel, 'hideFromCompetitiveChannelList');
  };
}

export function getAccountDisconnectionMessage(channel) {
  return toolTips.accountDisconnection?.[channel?.toLowerCase()] || '';
}
