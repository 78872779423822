<template>
  <Popup :close="closePopup" type="small" show-when-drawer-open>
    <h4 data-cy="AddProductPopupTitle">{{ operationLabel }} Product</h4>

    <div v-if="isNewProduct" class="product-url-container">
      <span class="product-url-title">Product URL</span>
      <input
        v-model="productData.url"
        type="text"
        data-cy="ProductUrlInput"
        placeholder="Enter your product URL"
        :class="{ warning: v$.productData.url.$error }"
        @input="onInput"
      />
    </div>

    <p v-if="v$.productData.url.$error" data-cy="InvalidUrlMessage" class="alert-error">
      {{ invalidUrlMessage }}
    </p>

    <div v-if="!isNewProduct" class="info-alert">
      <TextAlert
        icon-name="info"
        alert-message="Editing the product name or image will update the information in your Dash Social Product Catalog and will appear anywhere the product is tagged."
      />
    </div>

    <CircularLoader v-if="productStore.pending.urlMetadata" />
    <div v-else class="product-data-container">
      <div :class="{ loading: uploadingImage }" class="product-image-container">
        <Dropzone
          ref="dropzone"
          :on-accept="uploadStarted"
          :add-to-library="false"
          :on-success="uploadSuccess"
          :brand-ids="[currentBrand.id]"
        />
        <img v-if="productData.imageUrl" data-cy="ProductImage" :src="productData.imageUrl" />
        <div class="product-media-actions">
          <Button class="small circle-white icon-button upload" @click="uploadProductImage">
            <Icon :color="iconColor" :hover-color="iconHoverColor" name="cloudUpload" small />
          </Button>
          <Button class="small circle-white icon-button delete" @click="removeProductImage">
            <Icon :color="iconColor" :hover-color="iconHoverColor" name="bin" small />
          </Button>
        </div>
      </div>

      <div class="product-info-container">
        <div class="product-name">
          <label>
            <span>Product Name</span>
          </label>
          <Textarea
            v-model="productData.title"
            class="product-name-text"
            data-cy="ProductTitle"
            placeholder="Enter product name"
          />
        </div>
        <div v-if="existingProduct" class="additional-product-info-container">
          <div
            v-if="existingProduct.sourceId"
            class="product-info"
            data-cy="ExistingProductSourceId"
          >
            {{ existingProduct.sourceId }}
          </div>
          <div v-if="existingProduct.price" class="product-info" data-cy="ExistingProductPrice">
            {{ existingProduct.price }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isNewProduct" class="product-url-container">
      <span class="product-url-title">Product URL</span>
      <span class="readonly-text-box" data-cy="ProductUrlLabel">{{ productData.url }}</span>
    </div>

    <div class="buttons-section">
      <Button data-cy="ReloadMetaDataButton" class="action-button" @click="refreshProduct">
        <Icon class="button-icon" :color="iconColor" name="sync" small />
        <span>Reload Meta Data</span>
      </Button>
      <Button
        data-cy="SaveProductButton"
        :disabled="disableSaveButton"
        :loading="showLoadingButton"
        primary
        wide
        class="save action-button"
        @click="handleSaveClicked"
      >
        Save
      </Button>
    </div>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import debounce from 'lodash/debounce';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { useProductStore } from '@/stores/product';
import { debounceInputDelay, productUrlValidationMessages } from '@/config';
import { env } from '@/env';
import { colours } from '@/ux/colours';
import { stringifyQuery } from '@/utils/query';
import { productFeedType } from '@/utils/productFeed';
import Popup from '@/components/Popup.vue';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import Textarea from '@/components/Textarea.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import Dropzone from '@/app/scheduler/components/EditPost/MediaViewer/Dropzone.vue';
import TextAlert from '@/app/library/components/TextAlert.vue';
import { mapVuelidateErrorMessage } from '@/utils/vuelidate';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AddProduct',
  components: {
    TextAlert,
    Popup,
    Button,
    Icon,
    Textarea,
    CircularLoader,
    Dropzone,
  },
  props: {
    close: { type: Function, default: null },
    existingProduct: { type: Object, default: null },
    customSave: { type: Function, default: null },
    // the context in which the AddProductPopup is opened (ProductPopup, Products Page, etc.)
    context: { type: String, default: null },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      iconColor: colours.ICON.ICON_SECONDARY,
      iconHoverColor: colours.ACTION.ACTION_500,
      didNotFetchUrlMetadata: false,
      editing: false,
      showLoadingButton: false,
      uploadingImage: false,
      productData: {
        title: '',
        url: '',
        imageUrl: null,
      },
    };
  },
  validations() {
    return {
      productData: {
        url: {
          required,
          isValid: () => !this.didNotFetchUrlMetadata,
        },
      },
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useProductStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    operationLabel() {
      return this.isNewProduct ? 'Add' : 'Edit';
    },
    isNewProduct() {
      return this.existingProduct === null;
    },
    productPageLink() {
      const queryString = stringifyQuery({
        source: 'All Links',
      });
      return `${env.dashwebUrl}/library/products?${queryString}`;
    },
    disableSaveButton() {
      return (
        this.editing || this.v$.productData.url.$invalid || this.productStore.pending.urlMetadata
      );
    },
    invalidUrlMessage() {
      return mapVuelidateErrorMessage(
        this.v$.productData.url,
        {
          required: productUrlValidationMessages.empty,
          isValid: productUrlValidationMessages.invalidUrl,
        },
        { force: true },
      );
    },
    hasInvalidUrl() {
      return this.invalidUrlMessage !== null;
    },
  },
  mounted() {
    if (this.existingProduct) {
      this.productData = {
        title: this.existingProduct.title || '',
        url: this.existingProduct.url || '',
        imageUrl: this.existingProduct.imageUrl,
      };
    }
  },
  methods: {
    showAddProductNotification() {
      const htmlMessage = `Your product has been added. <a href='${this.productPageLink}'>See All Products</a>.`;
      this.notificationStore.setToast({ htmlMessage });
    },
    closePopup(closeSetting = {}) {
      this.productStore.clearUrlMetadata();
      if (this.close) {
        this.close(closeSetting);
      } else {
        this.$router.back();
      }
    },
    handleSaveClicked() {
      if (this.customSave) {
        this.customSave(this.productData);
      } else {
        this.saveProduct();
      }
    },
    async saveProduct() {
      const brandId = this.currentBrand.id;
      this.showLoadingButton = true;

      try {
        if (this.isNewProduct) {
          await this.productStore.addProduct({ brandId, context: this.context }, this.productData);
          this.showAddProductNotification();
        } else {
          await this.productStore.updateProduct({
            brandId,
            productId: this.existingProduct.id,
            data: this.productData,
            feedType: productFeedType(this.existingProduct),
            productCatalogName: this.existingProduct?.productFeed?.productCatalog?.name ?? null,
            context: this.context,
          });
        }
      } catch (error) {
        this.notificationStore.setToast({
          message: 'Something went wrong, please try again.',
          type: 'error',
        });
        return;
      } finally {
        this.showLoadingButton = false;
      }

      this.closePopup({ hasProductChanges: true });
    },
    onInput() {
      this.editing = true;
      this.debounceInput();
    },
    debounceInput: debounce(function debouncedRefreshProduct() {
      this.refreshProduct();
    }, debounceInputDelay),
    async refreshProduct() {
      if (!this.productData.url) {
        return;
      }
      const hasHeader =
        this.productData.url.indexOf('http://') > -1 ||
        this.productData.url.indexOf('https://') > -1;
      if (!hasHeader) {
        this.productData.url = `https://${this.productData.url}`;
      }
      let res;
      try {
        res = await this.productStore.getUrlMetadata(
          { url: this.productData.url },
          { camelizeKeys: true },
        );
        this.didNotFetchUrlMetadata = false;
      } catch {
        this.didNotFetchUrlMetadata = true;
        return;
      } finally {
        this.editing = false;
        this.v$.productData.url.$touch();
      }
      this.productData = {
        ...this.productData,
        ...res?.data,
      };
    },
    removeProductImage() {
      this.productData.imageUrl = null;
    },
    uploadProductImage() {
      this.$refs.dropzone.openFileDialog();
    },
    uploadStarted() {
      this.uploadingImage = true;
    },
    uploadSuccess(file) {
      this.uploadingImage = false;
      this.productData.imageUrl = file.uploadURL.split('?')[0];
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
input {
  width: 100%;
}

input.warning {
  border-color: var(--error-500);
}

label.span {
  font-size: var(--x14);
}

label textarea {
  height: var(--space-40);
}

.body {
  h4 {
    text-transform: capitalize;
    text-align: center;
    padding: 0.75rem 0 2rem;
  }
}

:deep(.dh-text-area > textarea) {
  height: 4.874rem;
}

.product-data-container {
  display: flex;
  width: 100%;
  margin: var(--space-24) 0;
  background-color: var(--background-300);
  padding: var(--space-30);
  border-radius: var(--round-corner-small);

  .product-image-container {
    min-width: 10rem;
    min-height: 10rem;
    width: 10rem;
    height: 10rem;
    background: var(--background-500);
    margin-right: var(--space-16);
    position: relative;
    border-radius: var(--round-corner-small);
    display: flex;

    img {
      width: 100%;
      border-radius: var(--round-corner-small);
      display: block;
      object-fit: cover;
    }

    .product-media-actions {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      padding-bottom: 0.6rem;

      .icon-button {
        width: var(--space-32);
        height: var(--space-32);
        padding: 0;
        margin: 0 var(--space-8);
      }

      .small.circle-white.icon-button.upload {
        margin-left: 0.25rem;
      }

      .small.circle-white.icon-button.delete {
        margin-right: 0.25rem;
      }
    }
  }

  .product-info-container {
    width: 100%;

    label {
      margin: 0;
    }
  }
}

.loading {
  color: transparent !important;
  position: relative;
}

.loading::after {
  content: '';
  display: block;
  border: 3px solid var(--white);
  border-left-color: rgb(255 255 255 / 20%);
  border-right-color: rgb(255 255 255 / 20%);
  border-bottom-color: rgb(255 255 255 / 20%);
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  margin-left: -0.75em;
  margin-top: -0.75em;
  animation: rotate 1s linear infinite;
}

.buttons-section {
  display: flex;
  justify-content: center;
  margin-top: var(--space-40);

  .action-button {
    width: 12.75rem;
  }

  .action-button:first-child {
    margin-right: var(--space-24);
  }
}

.product-url-container {
  .product-url-title {
    display: block;
    font-size: var(--x14);
    margin-bottom: var(--space-6);
  }
}

.alert-error {
  margin-top: var(--space-8);
  font-size: var(--x14);
}

.button-icon {
  margin-right: var(--space-6);
}

.readonly-text-box {
  width: 100%;
  position: relative;
  background-color: var(--background-0);
  color: var(--text-secondary);
  border: 1px solid var(--border);
  border-radius: var(--space-4);
  align-items: center;
  margin-left: auto;
  display: flex;
  font-size: var(--x14);
  padding: var(--space-12) 0 var(--space-12) var(--space-16);
  line-height: 1;
  white-space: nowrap;
  overflow-x: hidden;
}

.additional-product-info-container {
  /* stylelint-disable */
  &:has(.product-info) {
    margin-top: var(--space-12);
  }
  /* stylelint-enable */

  .product-info {
    font-size: var(--x12);
    color: var(--text-secondary);
    margin-bottom: var(--space-8);
    line-height: 1;
  }
}

@media (max-width: 50rem) {
  .left-container {
    margin-bottom: 1rem;
  }
}
</style>
