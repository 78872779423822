<template>
  <div>
    <template v-if="platformStore.connectionPopup.show">
      <template v-if="showAdsAccountsPopup">
        <AdsAccountsPopupV2
          v-if="showV2AdsAccountPopup"
          v-model="adsAccountsPopup"
          :fb-ads-accounts="facebookStore.fbAdsAccounts"
          :brand-id="brand.id"
          :loading="facebookStore.pending.fbAdsAccounts"
          :show-when-drawer-open="showWhenDrawerOpen"
          @input="onSelectAdsAccounts"
        />
        <AdsAccountsPopup
          v-else
          v-model="adsAccountsPopup"
          :fb-ads-accounts="facebookStore.fbAdsAccounts"
          :show-when-drawer-open="showWhenDrawerOpen"
          @input="onSelectAdsAccounts"
        />
      </template>
      <template v-else-if="isConnected && isTikTokAdsPlatform">
        <TikTokAdsAccountsPopup :brand="brand" />
      </template>
      <template v-else-if="isConnected && isTikTokCreatorMarketplacePlatform">
        <TikTokCreatorMarketplaceAccountsPopup :brand="brand" />
      </template>
      <template v-else-if="isConnected && isGoogleAnalyticsPlatform">
        <SelectGoogleAnalyticsProfilePopup
          :brand-id="brand.id"
          @close="onConnectionPopupCancel"
          @success="onGoogleAnalyticsAccountSuccess"
        />
      </template>
      <template v-else>
        <EducationalConnectionPopup
          :platform="platformValue"
          :show-terms="platformStore.connectionPopup.showTerms"
          :on-cancel="onConnectionPopupCancel"
          :brand="brand"
          :bypass-connection-check="platformStore.connectionPopup.bypassConnectionCheck"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import isFunction from 'lodash/isFunction';
import { useAuthStore } from '@/stores/auth';
import { usePlatformStore } from '@/stores/platform';
import EducationalConnectionPopup from '@/components/common/EducationalConnectionPopup.vue';
import {
  PLATFORM_CONNECTION,
  determinePlatformConnectionType,
  isSamePlatformConnection,
} from '@/models/platform/platform-connection.enum';
import { useFacebookStore } from '@/stores/facebook';
import { useFlagStore } from '@/stores/flag';
import TikTokCreatorMarketplaceAccountsPopup from '@/app/settings/components/TikTokCreatorMarketplaceAccountsPopup.vue';

const CONNECTION_POPUP = {
  [PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value]: {
    title: 'Connect a Facebook Account',
    message: 'To connect an account you must have Admin access to your Facebook business page.',
  },
  [PLATFORM_CONNECTION.FACEBOOK.value]: {
    title: 'Connect a Facebook Account',
    message: 'To connect an account you must have Admin access to your Facebook business page.',
  },
  [PLATFORM_CONNECTION.FACEBOOK_ADS.value]: {
    title: 'Connect a Facebook Ad Account',
    message: 'To connect an account you must have Admin access to your Facebook business page.',
  },
  [PLATFORM_CONNECTION.FACEBOOK_PAGE.value]: {
    title: 'Connect your Facebook Page',
    message: 'To connect an account you must have Admin access to your Facebook business page.',
  },
  [PLATFORM_CONNECTION.PINTEREST.value]: {
    title: `Connect your Pinterest Account`,
    message: `To see account insights you are required to connect your Pinterest account.`,
  },
  [PLATFORM_CONNECTION.TIKTOK.value]: {
    title: 'Connect your TikTok Account',
    message: 'You must have a Business TikTok account to access your insights in Dash Social.',
  },
  [PLATFORM_CONNECTION.YOUTUBE.value]: {
    title: 'Connect your YouTube Channel',
    message: 'To see account insights you are required to connect your YouTube Channel.',
  },
  [PLATFORM_CONNECTION.GOOGLE_ANALYTICS.value]: {
    title: 'Connect a Google Analytics Account',
    message:
      'To connect, you will need access to the Google Analytics Account you would like to link with Dash Social.',
  },
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    TikTokCreatorMarketplaceAccountsPopup,
    EducationalConnectionPopup,
    SelectGoogleAnalyticsProfilePopup: defineAsyncComponent(
      () => import('@/app/settings/components/SelectGoogleAnalyticsProfilePopup.vue'),
    ),
    AdsAccountsPopup: defineAsyncComponent(
      () => import('@/app/settings/components/AdsAccountsPopup.vue'),
    ),
    AdsAccountsPopupV2: defineAsyncComponent(
      () => import('@/app/settings/components/AdsAccountsPopupV2.vue'),
    ),
    TikTokAdsAccountsPopup: defineAsyncComponent(
      () => import('@/app/settings/components/TikTokAdsAccountsPopup.vue'),
    ),
  },
  data() {
    return {
      adsAccountsPopup: false,
    };
  },
  computed: {
    ...mapStores(usePlatformStore, useAuthStore, useFacebookStore, useFlagStore),
    platform() {
      return this.platformStore.connectionPopup?.platform;
    },
    brand() {
      return this.platformStore.connectionPopup?.brand ?? this.authStore?.currentBrand;
    },
    platformValue() {
      return determinePlatformConnectionType(this.platform);
    },
    title() {
      return (
        this.platformStore.connectionPopup.title ?? CONNECTION_POPUP[this.platformValue]?.title
      );
    },
    message() {
      return (
        this.platformStore.connectionPopup.message ?? CONNECTION_POPUP[this.platformValue]?.message
      );
    },
    isConnected() {
      return this.platformStore.isPlatformConnected(this.platformValue, {
        brandId: this.brand.id,
      });
    },
    isTwitterPlatform() {
      return isSamePlatformConnection(PLATFORM_CONNECTION.TWITTER, this.platformValue);
    },
    isGoogleAnalyticsPlatform() {
      return isSamePlatformConnection(PLATFORM_CONNECTION.GOOGLE_ANALYTICS, this.platformValue);
    },
    isFacebookAdsPlatform() {
      return isSamePlatformConnection(PLATFORM_CONNECTION.FACEBOOK_ADS, this.platformValue);
    },
    isTikTokAdsPlatform() {
      return isSamePlatformConnection(PLATFORM_CONNECTION.TIKTOK_ADS, this.platformValue);
    },
    isTikTokCreatorMarketplacePlatform() {
      return isSamePlatformConnection(
        PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE,
        this.platformValue,
      );
    },
    showAdsAccountsPopup() {
      return (
        this.platformStore.connectionPopup.show && this.isConnected && this.isFacebookAdsPlatform
      );
    },
    showV2AdsAccountPopup() {
      return this.flagStore.ready && this.flagStore.flags.adAccountConnection;
    },
    showWhenDrawerOpen() {
      return this.platformStore.connectionPopup.showWhenDrawerOpen;
    },
  },
  watch: {
    showAdsAccountsPopup(value) {
      this.launchAdsAccountPage(value);
    },
    adsAccountsPopup(value) {
      if (!value) {
        this.onConnectionPopupCancel();
      }
    },
  },
  methods: {
    async onConnectionPopupCancel() {
      if (isFunction(this.platformStore.connectionPopup.onCancel)) {
        await this.platformStore.connectionPopup.onCancel();
      }
      this.platformStore.closeConnectionPopup();
    },
    onGoogleAnalyticsAccountSuccess() {
      const brandId = this.brand.id;
      this.platformStore.getGoogleAnalyticsAccount(brandId);
      this.platformStore.closeConnectionPopup();
    },
    onSelectAdsAccounts() {
      const brandId = this.brand.id;
      this.platformStore.getFacebookAdsAccount(brandId);
      this.platformStore.closeConnectionPopup();
    },
    async launchAdsAccountPage(value) {
      if (this.showV2AdsAccountPopup) {
        this.adsAccountsPopup = value;
      }
      if (value) {
        const brandId = this.brand.id;
        await this.facebookStore.fetchFbAccountsFromApi({ brandId, fetchApi: true });
      }
      this.adsAccountsPopup = value;
    },
  },
});
export default comp;
</script>
