import { computed } from 'vue';
import { useIdentityStore } from '@/stores/identity';
import { useFlagStore } from '@/stores/flag';
import { BRAND, USER } from '@/models/auth/permissions.enum';

export function useCommunityPermissions() {
  // stores
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  // computed
  const userCanAccessCommunity = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_COMMUNITY);
  });

  const brandCanAccessCommunity = computed(() => {
    return identityStore.guard(BRAND.COMMUNITY.CAN_ACCESS_COMMUNITY);
  });

  const canAccessCommunity = computed(() => {
    return (
      flagStore.ready &&
      !flagStore.flags.disableCommunity &&
      brandCanAccessCommunity.value &&
      userCanAccessCommunity.value
    );
  });

  const canAccessSentimentAnalysis = computed(() => {
    return (
      identityStore.guard(USER.COMMUNITY.CAN_ACCESS_TEXT_ANALYSIS) &&
      identityStore.guard(BRAND.COMMUNITY.CAN_ACCESS_TEXT_ANALYSIS)
    );
  });

  const brandCanAccessInboxAutomation = computed(() => {
    return identityStore.guard(BRAND.COMMUNITY.CAN_ACCESS_INBOX_AUTOMATION);
  });

  const brandCanAccessInternalNotes = computed(() => {
    return identityStore.guard(BRAND.COMMUNITY.CAN_ACCESS_COMMUNITY_COLLABORATION);
  });

  const userCanAccessCommunityOverview = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_COMMUNITY_OVERVIEW);
  });

  const brandCanAccessCommunityOverview = computed(() => {
    return identityStore.guard(BRAND.COMMUNITY.CAN_ACCESS_COMMUNITY_OVERVIEW);
  });

  const canAccessCommunityOverview = computed(() => {
    return userCanAccessCommunityOverview.value && brandCanAccessCommunityOverview.value;
  });

  const canAccessInstagramDirect = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_INSTAGRAM_DIRECT);
  });

  const canAccessInstagramComments = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_INSTAGRAM_COMMENTS);
  });

  const canAccessTikTokComments = computed(() => {
    return (
      identityStore.guard(BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS) &&
      identityStore.guard(USER.COMMUNITY.CAN_ACCESS_TIKTOK_COMMENTS)
    );
  });

  const canAccessFacebookMessenger = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_FACEBOOK_MESSENGER);
  });

  const canAccessFacebookComments = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_FACEBOOK_COMMENTS);
  });

  const canAccessTwitterMentions = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_TWITTER_MENTIONS);
  });

  const canAccessTwitterDirect = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_TWITTER_DIRECT);
  });

  const canAccessYouTubeComments = computed(() => {
    return identityStore.guard(USER.COMMUNITY.CAN_ACCESS_YOUTUBE_COMMENTS);
  });
  const canAccessTeamPerformanceMetrics = computed(() => {
    return flagStore.ready && flagStore.flags.communityTeamPerformanceMetrics;
  });

  const canAccessCommunityTranslation = computed(() => {
    if (!flagStore.ready) return undefined;
    return flagStore.ready && flagStore?.flags?.communityTranslation;
  });

  const canAccessYoutubeCompliance = computed(() => {
    return flagStore.ready && flagStore.flags.youtubeCompliance;
  });

  const hasAccessToIntegrations = computed(() => {
    return identityStore.guard(BRAND.SETTINGS.CAN_ACCESS_INTEGRATIONS);
  });

  const hasGDIHeadersAndFilterFlag = computed(() => {
    return flagStore.ready && flagStore.flags.gdiHeadersAndFilters;
  });

  const canAccessEngageableMessages = computed(() => {
    return (
      flagStore.ready &&
      flagStore.flags.engageableMessages &&
      identityStore.guard(USER.COMMUNITY.CAN_ACCESS_TEXT_ANALYSIS) &&
      identityStore.guard(BRAND.COMMUNITY.CAN_ACCESS_TEXT_ANALYSIS)
    );
  });

  return {
    userCanAccessCommunity,
    brandCanAccessCommunity,
    canAccessCommunity,
    canAccessSentimentAnalysis,
    brandCanAccessInternalNotes,
    brandCanAccessCommunityOverview,
    brandCanAccessInboxAutomation,
    canAccessCommunityOverview,
    userCanAccessCommunityOverview,
    canAccessInstagramDirect,
    canAccessInstagramComments,
    canAccessTikTokComments,
    canAccessFacebookMessenger,
    canAccessFacebookComments,
    canAccessTwitterMentions,
    canAccessTwitterDirect,
    canAccessYouTubeComments,
    canAccessTeamPerformanceMetrics,
    canAccessCommunityTranslation,
    canAccessYoutubeCompliance,
    hasAccessToIntegrations,
    hasGDIHeadersAndFilterFlag,
    canAccessEngageableMessages,
  };
}
