import merge from 'lodash/merge';
import uniqueId from 'lodash/uniqueId';

export const parseToQuery = (groups) => {
  let query = '';
  const space = ' ';
  let parenthesisOpen = 0;
  groups.forEach((group, idx) => {
    const { operators, level, keywords } = group;
    if (!idx) {
      // do some special things for the first group
      if (operators && operators.includes('not')) {
        // if first group starts of with 'AND NOT' (excludes)
        query += `NOT${space}`;
      }
      // open a parentheses before any other operators if it should be nested
      // this is the ONLY time the parenthesis needs to be added for a nested
      // group BEFORE an operator immediately follows
      if (level) {
        query += '(';
        parenthesisOpen += 1;
      }
    }

    if (operators && idx) {
      // before operators are appended
      if (parenthesisOpen && (operators.includes('or') || operators.includes('not'))) {
        // if parentheses are open and the current group is an (or/and not) close the group
        query = query.trimEnd();
        query += `)${space}`;
        parenthesisOpen -= 1;
      }
      // append operator(s) to group
      query += operators.join(' ').toUpperCase().trim() + space;
    }

    // after operators are appended
    if (level && !parenthesisOpen) {
      // check if we need to open a new parenthesis
      query += '(';
      parenthesisOpen += 1;
    } else if (!level && parenthesisOpen && operators.includes('or')) {
      // close the previous group if the current group is preceded by an or
      // not nested and there is a parenthesis open
      query += `)${space}`;
      parenthesisOpen -= 1;
    }

    // build group of keywords
    query += `(${keywords.join(' OR ')})${space}`;

    if (level && parenthesisOpen && idx === groups.length - 1) {
      // if last group is level 1 close parentheses
      query = query.trimEnd();
      query += ')';
      parenthesisOpen -= 1;
    }
  });
  query = query.trim();
  return query;
};

const organizeGroups = (groups, depth) => {
  if (!depth) {
    return groups;
  }

  // place not groups at the end to match the UI
  groups.sort((a, b) => {
    if (a.operators.includes('not') && !b.operators.includes('not')) {
      return 1;
    }
    if (b.operators.includes('not') && !a.operators.includes('not')) {
      return -1;
    }
    return 0;
  });

  groups.forEach((group, index, arr) => {
    if (index === 0) {
      // make sure the first group, if not an excludes group, is the head and "or"
      if (!group.operators.includes('not')) {
        group.operators = ['or'];
        group.head = true;
      }
    }
    if (index === arr.length - 1 && arr.find((item) => item.operators.includes('not'))) {
      // mark any excludes statements a level 0
      if (group.operators.includes('not')) {
        arr[index].level = 0;
      }
      // mark any or groups before the exclude group as level 0
      if (index > 0 && arr[index - 1].operators.includes('or')) {
        arr[index - 1].level = 0;
      }
      return;
    }
    const previousGroup = index > 0 ? arr[index - 1] : undefined;
    // if current group operator is and then check if previous group is or
    // change the previous group's level to 1
    if (
      previousGroup &&
      previousGroup.operators.includes('or') &&
      group.operators.includes('and') &&
      group.operators.length === 1
    ) {
      groups[index - 1].level = 1;
    }

    // if the previous group is or and level 1 and the current one is and
    if (
      previousGroup &&
      previousGroup.operators.includes('or') &&
      previousGroup.level &&
      !previousGroup?.head &&
      group.operators.includes('and') &&
      group.operators.length === 1 &&
      index - 1 > 0
    ) {
      // mark the previous group as an and
      arr[index - 1].operators = ['and'];
    } else if (
      previousGroup &&
      previousGroup.operators.includes('or') &&
      !previousGroup.level &&
      group.operators.includes('and') &&
      group.operators.length === 1
    ) {
      arr[index].operators = ['or'];
    }
  });

  if (depth > 0) {
    organizeGroups(groups, depth - 1);
  }
  return groups;
};

const processGroups = (groups) => {
  const points = [];
  const unmergedGroups = [];
  const mergedResults = [];
  // find all indices that have keywords
  groups.forEach((group, idx) => {
    if (group?.keywords?.length) {
      points.push(idx);
    }
  });
  // use points to group related objects into list
  points.forEach((p, idx, arr) => {
    const endpoint = idx === arr.length - 1 ? undefined : arr[idx + 1];
    const slice = groups.slice(p, endpoint);
    unmergedGroups.push(slice);
  });
  // merge related objects into one
  unmergedGroups.forEach((group) => {
    mergedResults.push(merge(...group.filter(({ operators, level }) => operators.length || level)));
  });
  mergedResults.forEach((group, idx, arr) => {
    if (!idx) {
      // first group always head
      arr[idx].head = true;
      // if the first group is not related to others it is also a tail
      if (group.operators.includes('or') && !group.level) {
        arr[idx].tail = true;
      }
    } else if (arr[idx - 1]?.tail) {
      // if the previous group was a tail then the next must be a head
      arr[idx].head = true;
    }
  });
  // do some final processing to logically organize groups
  return organizeGroups(mergedResults, mergedResults.length - 1);
};

export const parseTreeToGroups = (tree, prevRule = '', groups = [], idx = null) => {
  const rule = tree.rule;
  const children = tree.children;
  let group = {
    keywords: [],
    operators: [],
    level: 0,
  };
  let keywords = [];

  children.forEach((child, index) => {
    if (child?.keyword) {
      // reached a leaf
      keywords.push(child.keyword);
    } else {
      // recursively parse until reaching a leaf
      group = parseTreeToGroups(child, rule, groups, index);
    }
  });

  group.operators = [rule].filter((op) => op !== 'atom');

  if (keywords.length > 0) {
    group.keywords = keywords;
  }
  // handle group return values (popping of the stack)
  // use logic to determine if we are ready to push a group to `groups`
  let push = false;

  if (rule === 'single_keyword' || rule === 'multiword_keyword') {
    group.level = 0;
    group.head = true;
    group.tail = true;
    group.operators = ['or'];
    push = true;
  }

  if (rule === 'and') {
    group.level = 1;
    push = true;
    if (keywords?.length > 1) {
      // split "AND" keywords without atom rule i.e foo AND bar AND vaz
      // into separate groups
      keywords.forEach((keyword, index) => {
        const groupData = {
          keywords: [keyword],
          level: 1,
          operators: ['and'],
        };
        if (index === 0) {
          groupData.head = true;
          groupData.operators = ['or'];
        }
        if (index === keywords.length - 1) {
          groupData.tail = true;
        }
        groups.push(groupData);
      });
      push = false;
      keywords = [];
    }
  }

  if (rule === 'or') {
    group.level = 0;
  }

  if (rule === 'atom') {
    push = true;
    if (prevRule === 'and') {
      group.level = 1;
    }

    if (prevRule === 'or') {
      group.tail = true;
    }
    // if a single keyword is wrapped in parentheses
    if (keywords?.length === 1) {
      group.operators = idx > 0 ? [prevRule] : ['or'];
    }
  }

  const pushed = {
    operators: group.operators,
    level: group.level,
  };

  if (group?.tail) {
    pushed.tail = group.tail;
  }

  if (keywords.length > 0) {
    pushed.keywords = keywords;
    push = true;
  }

  if (rule === 'not') {
    pushed.operators = [prevRule, rule].filter((r) => r && r !== 'or');
    push = true;
  }

  if (push) {
    groups.push(pushed);
  }
  return idx === null ? processGroups(groups).map((g) => ({ ...g, id: uniqueId() })) : group;
};

export const stripIdFieldFromGroups = (groups) => {
  if (!groups?.length) {
    return undefined;
  }
  return groups.map((group) => {
    const { id, ...data } = group;
    return data;
  });
};
