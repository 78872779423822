<template>
  <div id="thumbnails-container" ref="container" :class="{ spaced: lastFrameLoaded }">
    <img
      v-for="(thumbnail, index) in thumbnails"
      :key="index"
      :src="thumbnail"
      :style="{ width: `${height}px` }"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mediaUrl } from '@/app/scheduler/utils';
import { env } from '@/env';

/**
 * Video preview component that shows a list of thumbnails sampled from the video.
 *
 * This approach creates a video element, seeks it to a timestamp, draws it onto a canvas, and
 * renders that canvas to a jpeg blob URL that is then displayed with a regular img tag. This
 * is reliable and works in all browsers, but because it doesn't work in parallel, it's a little
 * slow. If you can figure out how to make this faster, go for it :)
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  props: {
    media: { type: Object, default: null },
    duration: { type: Number, default: null },
    height: { type: Number, default: 56 },
    width: { type: Number, default: 356 },
  },
  data() {
    return {
      thumbnails: [],
      thumbInterval: null,
      lastFrameLoaded: false,
      destroyed: false,
      videoSrc: mediaUrl([this.media]),
    };
  },
  watch: {
    videoSrc() {
      this.generateThumbs();
    },
  },
  mounted() {
    this.generateThumbs();
  },
  beforeUnmount() {
    this.destroyed = true;
    // Fixes memory leak that occurs in Safari. Visit link for more details:
    // https://stackoverflow.com/questions/5170398/ios-safari-memory-leak-when-loading-unloading-html5-video
    this.video.src = '';
    this.video.load();
  },
  methods: {
    async generateThumbs() {
      let duration = this.media.duration || null;
      this.thumbnails = [];

      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      this.video = video;

      video.addEventListener('loadeddata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        video.currentTime = 0;
        this.thumbInterval = video.duration / (this.width / this.height);
        if (!duration) {
          duration = video.duration;
        }
      });

      video.addEventListener('seeked', () => {
        // Capture the thumbnail after the video's finished seeking
        ctx.drawImage(video, 0, 0);
        canvas.toBlob((blob) => {
          // Prevent thumbnail generation process from running in background if component is
          // destroyed
          if (this.destroyed) return;

          // Tell Vue to render the captured thumbnail
          this.thumbnails.push(URL.createObjectURL(blob));
          const nextTime = video.currentTime + this.thumbInterval;
          if (nextTime < video.duration) {
            video.currentTime = nextTime;
          } else {
            this.lastFrameLoaded = true;
          }
        }, 'image/jpeg');
      });

      video.preload = 'auto';
      video.crossOrigin = 'anonymous';

      // Prevent Chrome from trying to use cached responses that don't have the appropriate CORS
      // headers if the object is not in s3 or cloudfront cache. See https://serverfault.com/a/856948 for more detail.
      video.src =
        this.videoSrc.search(env.uploadedMediaStorageUrl) === -1 &&
        this.videoSrc.search(
          /^https:\/\/dashhudson-(static|dev)\.s3(-accelerate)?\.amazonaws\.com\/media\//,
        ) === -1
          ? this.videoSrc
          : `${this.videoSrc}?x-bust-cache=true`;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
#thumbnails-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: var(--round-corner-small);

  img {
    height: 100%;
    object-fit: cover;
  }
}

/*
 Applied only after the last frame is loaded so that frames don't shift positions to try and
 center themselves as they populate
*/
.spaced {
  justify-content: space-between;
}
</style>
