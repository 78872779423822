import { productAvailabilityLabels, productAvailabilityTypes } from '@/app/library/constants';

export const LIKESHOP_ROUTE = Object.freeze({
  INSTAGRAM: 'instagram.likeshop',
  TIKTOK: 'tiktok.likeshop',
});

export const LIKESHOP_GALLERY_ID = Object.freeze({
  INSTAGRAM: 'likeshop',
  TIKTOK: 'tiktok_likeshop',
});

export const LIKESHOP_PAGE_TITLE = Object.freeze({
  INSTAGRAM: 'Instagram LikeShop',
  TIKTOK: 'TikTok LikeShop',
});

export const LIKESHOP_DRAWERS = Object.freeze({
  SETTINGS: 'likeShopSettings',
});

export const LIKESHOP_MEDIA_TYPES = Object.freeze({
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
});

export const ECOMMERCE_DRAWERS = Object.freeze({
  MEDIA_LINKS: 'mediaLinks',
  PRODUCT: 'product',
  FEED_REORDER: 'feedReorder',
  UTM_SETTINGS: 'utmSettings',
});

export const LIKESHOP_COPY_TOOLTIP = Object.freeze({
  INSTAGRAM:
    'This is your custom LikeShop website address. Add it to your Instagram bio to make it visible to your followers.',
  TIKTOK:
    'This is your custom LikeShop website address. Add it to your TikTok bio to make it visible to your followers.',
});

export const LIKESHOP_COPIED_TOAST_MESSAGE = Object.freeze({
  INSTAGRAM: 'Add this link to your Instagram bio to share it with your followers!',
  TIKTOK: 'Add this link to your TikTok bio to share it with your followers!',
});

export const LIKESHOP_FIRST_TIME_BANNER = Object.freeze({
  TITLE: 'Set Up Your Link in Bio',
  IG_MESSAGE:
    'Convert your Instagram followers into shoppers with LikeShop. Get started by adding product links to at least your last 12 posts.',
  TIKTOK_MESSAGE:
    'Convert your TikTok followers into shoppers with LikeShop. Get started by adding product links to at least your last 12 posts.',
});

export const LIKESHOP_ALL_MEDIA_SORT_OPTIONS = Object.freeze([
  {
    value: 'POSTED',
    label: 'Recently Published',
  },
  {
    value: 'CLICKS',
    label: 'Clicks',
  },
]);

export const LIKESHOP_MEDIA_TYPE_OPTIONS = Object.freeze([
  {
    value: 'ALL_MEDIA',
    label: 'All Media',
  },
  {
    value: 'MEDIA_WITH_LINKS',
    label: 'Media with Links',
  },
]);

export const likeshopAllMediaDefaultOptions = Object.freeze({
  sort: {
    sortOrder: 'DESC',
    selected: {
      label: 'Recently Published',
      value: 'POSTED',
    },
  },
  dateRangeFilter: {
    preset: 'ALL_TIME',
    presetObject: { value: 'ALL_TIME', label: 'All Time' },
  },
  mediaType: 'ALL_MEDIA',
});

export const integrations = Object.freeze({
  introCopy:
    'Turn followers into newsletter subscribers or email leads. Download submissions as a CSV or send them directly to your Mailchimp or Klaviyo lists.',
  mailchimpHelpLink:
    'https://help.dashsocial.com/hc/en-us/articles/14530160226189-Why-Connect-Your-Mailchimp-Account-with-Dash-Hudson-LikeShop',
  klaviyoHelpLink:
    'https://help.dashsocial.com/hc/en-us/articles/14528999094925-Why-Connect-Your-Klaviyo-Account-with-Dash-Hudson-LikeShop',
});

export const PRIVACY_POLICY_LINK_TOOLTIP =
  'Link provided to your audience outlining your privacy policy. Required as per the Interactive Features section of our Terms of Service and applicable legislation.';

export const productDrawerSearchPlaceholder = 'Search by product name or ID';

export const PRODUCT_SORT_OPTIONS = [
  { label: 'Recently Added', value: 'DATE' },
  { label: 'Total Clicks', value: 'CLICKS' },
];

export const productDefaultOptions = Object.freeze({
  sort: {
    sortOrder: 'DESC',
    selected: { label: 'Recently Added', value: 'DATE' },
  },
});

export const LIKESHOP_SCOPED_FILTERS = Object.freeze({
  PRODUCTS: {
    scope: 'likeshopProducts',
    options: {
      sort: PRODUCT_SORT_OPTIONS,
      import_source: [
        {
          formattedLabel: 'Facebook Product Catalog',
          label: 'Facebook Product Catalog',
          value: 'facebook-product-catalog',
        },
        {
          formattedLabel: 'Single Product Link',
          label: 'Single Product Link',
          value: 'single-product-link',
        },
        {
          formattedLabel: 'Other Product Feeds',
          label: 'Other Product Feeds',
          value: 'other-product-feed',
        },
      ],
      availability: [
        {
          label: productAvailabilityLabels.IN_STOCK,
          formattedLabel: productAvailabilityLabels.IN_STOCK,
          value: productAvailabilityTypes.IN_STOCK,
        },
        {
          label: productAvailabilityLabels.OUT_OF_STOCK,
          formattedLabel: productAvailabilityLabels.OUT_OF_STOCK,
          value: productAvailabilityTypes.OUT_OF_STOCK,
        },
        {
          label: productAvailabilityLabels.PREORDER,
          formattedLabel: productAvailabilityLabels.PREORDER,
          value: productAvailabilityTypes.PREORDER,
        },
        {
          label: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
          formattedLabel: productAvailabilityLabels.AVAILABLE_FOR_ORDER,
          value: productAvailabilityTypes.AVAILABLE_FOR_ORDER,
        },
      ],
    },
    default: {
      sort: {
        sortOrder: 'DESC',
        selected: PRODUCT_SORT_OPTIONS[0],
      },
      import_source: [],
      availability: [],
      product_catalog_ids: [],
    },
  },
});
