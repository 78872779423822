<template>
  <nav class="media-action-buttons" data-cy="media-action-buttons">
    <a v-if="showAddToGalleryControl" v-tooltip="addToButtonTooltip">
      <button
        ref="addToButton"
        :class="{ disabled: addDropdownDisabled || onDifferentBrand }"
        :disabled="addDropdownDisabled || onDifferentBrand"
        class="add-to-button add-to-dropdown-button"
        data-cy="media-action-button-add-to"
        @click="openAddDropdown"
      >
        <Icon large name="folderAdd" />
        Add To
      </button>

      <AddToDropdown
        ref="addToDropdown"
        :selected-media="mediaItem"
        class="add-to-dropdown"
        :parent-name="$options.name"
        @add-from-popup="emitChangeSaved"
      />
    </a>

    <a v-if="showScheduleButton" v-tooltip="scheduleButtonTooltip">
      <button
        ref="scheduleButton"
        :class="[
          'schedule',
          { disabled: disableGeneralSchedulePermission || onDifferentBrand || isDMedStory },
        ]"
        :disabled="disableGeneralSchedulePermission || onDifferentBrand || isDMedStory"
        data-cy="media-action-button-schedule"
        @click.prevent="openScheduleOptions"
      >
        <Icon large name="calendar" />
        Schedule
      </button>
      <transition name="slide">
        <ul
          v-if="showScheduleOptions"
          v-on-click-outside="closeScheduleOptions"
          class="add-post-dropdown"
          data-cy="media-action-button-add-post-dropdown"
        >
          <li
            v-for="(platform, index) in addPostPlatforms"
            :key="index"
            v-tooltip="platform.tooltip ? platform.tooltip : null"
            :class="{ disabled: platform.disabled }"
            @click="platform.disabled ? null : scheduleOptionClicked(platform)"
          >
            <a>
              <div class="left">
                <Icon :name="platform.name" small />
                {{ platform.linkText }}
              </div>
              <Icon v-if="platform.subList" name="caret" xxsmall />
            </a>
          </li>
        </ul>
      </transition>
      <transition name="slide">
        <AddToUnscheduled
          v-if="showAddToUnscheduled"
          v-on-click-outside="closeAddToUnscheduled"
          :selected-media="mediaItem"
          @close="closeAddToUnscheduled"
          @back="openScheduleOptions"
        />
      </transition>
    </a>

    <a v-if="showLinksButton" v-tooltip="linksButtonTooltip">
      <button
        ref="linksButton"
        :class="['links', { disabled: linksDisabled }]"
        :disabled="linksDisabled"
        data-cy="media-action-button-links"
        @click="linksButtonClicked(mediaDetailStore.mediaDetail)"
      >
        <span v-if="linkAmount" class="link-number-badge">{{ linkAmount }}</span>
        <Icon large name="link" />
        Links
      </button>
    </a>

    <MoreDropdown @dropdown-clicked="emitButtonClicked" @change-saved="emitChangeSaved" />
  </nav>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useAuthStore } from '@/stores/auth';
import AddToDropdown from '@/components/AddToDropdown.vue';
import AddToUnscheduled from '@/components/AddToUnscheduled.vue';
import Icon from '@/components/foundation/Icon.vue';
import MoreDropdown from '@/app/library/components/MediaPopup/MoreDropdown.vue';
import enumTypes, { mediaTypes } from '@/app/library/constants';
import { toolTips } from '@/config';
import { usePlatformStore } from '@/stores/platform';
import { useMediaStore } from '@/stores/media';
import { useMediaLinksStore } from '@/stores/media-links';
import { useMediaDetailStore } from '@/stores/media-detail';
import { useNotificationStore } from '@/stores/notification';
import { useTrackingStore } from '@/stores/tracking';
import { SchedulerUserEventTracker } from '@/app/scheduler/mixpanel';
import { useFlagStore } from '@/stores/flag';
import { mixpanelActions } from '@/app/scheduler/constants';
import dayjs from 'dayjs';
import { getCurrentDate } from '@/utils';

const schedulerDisallowedPostTypes = [enumTypes.TIKTOK, enumTypes.YOUTUBE];

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaActionButtons',
  components: {
    AddToDropdown,
    AddToUnscheduled,
    Icon,
    MoreDropdown,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    mediaItem: { type: Object, required: true },
    showAddToGalleryControl: { type: Boolean, default: true },
  },
  emits: ['pauseVideo', 'buttonClicked'],
  data() {
    return {
      enumTypes,
      showScheduleOptions: false,
      showAddToUnscheduled: false,
      selectedPlatform: null,
      isMounted: false,
    };
  },
  computed: {
    ...mapStores(
      usePlatformStore,
      useMediaLinksStore,
      useMediaStore,
      useMediaDetailStore,
      useNotificationStore,
      useTrackingStore,
      useFlagStore,
    ),
    ...mapPiniaState(useAuthStore, [
      'currentBrand',
      'identity',
      'canAccessSchedulerPlatform',
      'brandCanAccessScheduler',
    ]),
    addPostPlatforms() {
      const platforms = [
        {
          name: 'tiktok',
          linkText: 'tikTok post',
          disabled:
            this.mediaItem.mediaType === mediaTypes.IMAGE ||
            !this.canAccessSchedulerPlatform('tiktok'),
          tooltip: this.tikTokDisabledTooltip,
        },
        {
          name: 'instagram',
          linkText: 'instagram post',
          disabled: !this.canAccessSchedulerPlatform('instagram'),
          tooltip:
            !this.canAccessSchedulerPlatform('instagram') &&
            this.platformSchedulerPermissionDeniedTooltip('instagram'),
        },
        {
          name: 'facebook',
          linkText: 'facebook post',
          disabled: !this.canAccessSchedulerPlatform('facebook'),
          tooltip:
            !this.canAccessSchedulerPlatform('facebook') &&
            this.platformSchedulerPermissionDeniedTooltip('facebook'),
        },
        {
          name: 'pinterest',
          linkText: 'pinterest pin',
          disabled: !this.canAccessSchedulerPlatform('pinterest'),
          tooltip: this.pinterestDisabledTooltip,
        },
        {
          name: 'twitter',
          linkText: 'x post',
          disabled: !this.canAccessSchedulerPlatform('twitter'),
          tooltip:
            !this.canAccessSchedulerPlatform('twitter') &&
            this.platformSchedulerPermissionDeniedTooltip('twitter'),
        },
        {
          name: 'linkedin',
          linkText: 'linkedIn post',
          disabled:
            this.mediaItem.mediaType === mediaTypes.VIDEO ||
            !this.canAccessSchedulerPlatform('linkedin'),
          tooltip: this.linkedInDisabledTooltip,
        },
        {
          name: 'addCalendar',
          linkText: 'unscheduled',
          subList: true,
        },
      ];
      return platforms;
    },
    addToButtonTooltip() {
      if (
        this.isDMedStory &&
        dayjs(this.mediaItem.datePosted).diff(getCurrentDate(), 'hour') >= -24
      ) {
        return toolTips.cannotAddDMedStories;
      }
      if (this.addDropdownDisabled) {
        return toolTips.onlyAddPostsWithMediatoGallery;
      }
      if (this.onDifferentBrand) {
        return toolTips.onDifferentBrand;
      }
      return null;
    },
    scheduleButtonTooltip() {
      if (this.disableGeneralSchedulePermission) {
        return toolTips.noSchedulePermission;
      }
      if (this.onDifferentBrand) {
        return toolTips.onDifferentBrand;
      }
      return null;
    },
    linksButtonTooltip() {
      if (this.onDifferentBrand) {
        return toolTips.onDifferentBrand;
      }
      return null;
    },
    disableGeneralSchedulePermission() {
      return !this.userPermissionForScheduler.can_access_scheduler;
    },
    pinterestDisabledTooltip() {
      if (!this.canAccessSchedulerPlatform('pinterest')) {
        return this.platformSchedulerPermissionDeniedTooltip('pinterest');
      }
      return '';
    },
    tikTokDisabledTooltip() {
      if (!this.brandCanAccessScheduler('tiktok')) {
        return this.platformSchedulerPermissionDeniedTooltip('tiktok');
      }
      if (!this.canAccessSchedulerPlatform('tiktok')) {
        return this.platformSchedulerUserPermissionDeniedTooltip('tiktok');
      }
      if (this.mediaItem.mediaType === mediaTypes.IMAGE) {
        return toolTips.tikTokDisabledTooltip;
      }
      return '';
    },
    linkedInDisabledTooltip() {
      if (!this.brandCanAccessScheduler('linkedin')) {
        return this.platformSchedulerPermissionDeniedTooltip('linkedin');
      }
      if (!this.canAccessSchedulerPlatform('linkedin')) {
        return this.platformSchedulerUserPermissionDeniedTooltip('linkedin');
      }
      if (this.mediaItem.mediaType === mediaTypes.VIDEO) {
        return toolTips.linkedInDisabledTooltip;
      }
      return '';
    },
    linkAmount() {
      if (this.mediaLinksStore.mediaLinks) {
        return this.mediaLinksStore.mediaLinks.length;
      }
      return 0;
    },
    userPermissionForScheduler() {
      // this returns the user permission for the current brand in scheduler
      return this.identity.permissions.scheduler[this.currentBrand.label];
    },
    isDMedStory() {
      return (
        this.mediaItem.sourceType === enumTypes.INSTAGRAM_STORY_UGC && this.mediaItem.importedViaDm
      );
    },
    addDropdownDisabled() {
      if (!this.isMounted) {
        return false;
      }
      return this.$refs.addToDropdown.allDisabled || this.isDMedStory;
    },
    linksDisabled() {
      const mediaSource = this.mediaItem.sourceType;
      return (
        enumTypes.NO_MEDIA_SOURCE_LIST.includes(mediaSource) ||
        this.onDifferentBrand ||
        this.isDMedStory
      );
    },
    onDifferentBrand() {
      return this.currentBrand.id !== this.mediaDetailStore.mediaDetail.brandId;
    },
    brandLabel() {
      const owningBrandId = this.mediaDetailStore.mediaDetail.brandId;
      const brandInfo = Object.values(this.identity.brands).filter(
        (brand) => owningBrandId === brand.id,
      )?.[0];
      return brandInfo?.label;
    },
    showScheduleButton() {
      return !schedulerDisallowedPostTypes.includes(this.mediaItem.postType);
    },
    showLinksButton() {
      const disallowedPlatforms = [enumTypes.YOUTUBE, enumTypes.LINKEDIN];
      return !disallowedPlatforms.includes(this.mediaItem.postType);
    },
    trackSchedulerMixpanel() {
      return new SchedulerUserEventTracker();
    },
  },
  mounted() {
    this.isMounted = true;
    this.mediaLinksStore.listMediaLinks({
      brandId: this.currentBrand.id,
      mediaId: this.mediaDetailStore.mediaDetail.id,
    });
  },
  methods: {
    async confirmCloseAndOpenScheduler(brandLabel) {
      const mediaSource = this.mediaItem.sourceType;
      let mediaCaption =
        this.mediaItem.postCaption ||
        this.mediaItem.message ||
        this.mediaItem.tweetStatus ||
        this.mediaItem.caption;
      if (
        this.selectedPlatform.name === 'instagram' &&
        (mediaSource === enumTypes.INSTAGRAM_OWNED ||
          mediaSource === enumTypes.INSTAGRAM_UGC ||
          mediaSource === enumTypes.INSTAGRAM_OTHER)
      ) {
        mediaCaption = `${mediaCaption} | @${this.mediaItem.userName}`;
      }

      const hasMedia = !enumTypes.NO_MEDIA_SOURCE_LIST.includes(mediaSource) && !!this.mediaItem;
      const mediaIds = hasMedia ? [this.mediaItem.id] : null;
      this.$router.push({
        name: `scheduler.${this.selectedPlatform.name}.posts`,
        params: { id: 'new', brandLabel },
        query: {
          mediaIds,
          mediaCaption,
        },
      });
    },
    emitButtonClicked(buttonName) {
      this.$emit('buttonClicked', 'Top Section', `Click ${buttonName}`, {
        buttonLabel: buttonName,
      });
    },
    emitChangeSaved(buttonName) {
      this.$emit('buttonClicked', 'Top Section', `Save ${buttonName}`, {
        buttonLabel: buttonName,
      });
    },
    openAddDropdown() {
      this.$refs.addToDropdown.openAddToDropdown();
      this.emitButtonClicked('Add To');
    },
    openScheduleOptions() {
      this.showScheduleOptions = true;
      this.showAddToUnscheduled = false;
      this.emitButtonClicked('Schedule');
    },
    closeScheduleOptions(e) {
      if (e && e.target !== this.$refs.scheduleButton) {
        this.showScheduleOptions = false;
      }
    },
    openAddToUnscheduled() {
      this.showAddToUnscheduled = true;
      this.showScheduleOptions = false;
    },
    closeAddToUnscheduled() {
      this.showAddToUnscheduled = false;
    },
    platformSchedulerPermissionDeniedTooltip(platform) {
      if (platform === 'tiktok') {
        return toolTips.featureNotIncludedInPlan;
      }
      return `You don't have the proper permissions to use scheduler for ${platform
        .charAt(0)
        .toUpperCase()}${platform.slice(1)}.`;
    },
    platformSchedulerUserPermissionDeniedTooltip(platform) {
      if (platform === 'tiktok') {
        return toolTips.tikTokNoUserPermission;
      }
      return '';
    },
    scheduleOptionClicked(platform) {
      if (this.mediaItem.media_type === 'VIDEO' && platform.linkText !== 'unscheduled') {
        this.$emit('pauseVideo');
      }
      if (platform.linkText === 'unscheduled') {
        this.openAddToUnscheduled();
        return;
      }
      this.selectedPlatform = platform;
      let section = this.$route.name.split('.')[0];
      section = section.charAt(0).toUpperCase() + section.slice(1);
      this.trackSchedulerMixpanel.schedulerCreatePost(section, platform.name, null, 'MediaPopup');
      if (this.mediaItem.is_video) {
        const title = 'Pin will schedule as a photo';
        const message =
          'This pin will be scheduled as a photo as the video file is not available via Pinterest.';
        this.notificationStore.confirm(title, message, {
          confirmAlias: 'Continue',
          confirmType: 'primary',
          uncancellable: true,
          onConfirm: () => {
            this.confirmCloseAndOpenScheduler(this.brandLabel);
          },
        });
      } else {
        this.confirmCloseAndOpenScheduler(this.brandLabel);
      }
    },
    linksButtonClicked(mediaDetail) {
      this.mediaLinksStore.openLinkPopup(mediaDetail);
      this.emitButtonClicked('Links');
      const updateLinkData = {
        editFrom: 'Media Popup',
        firstLink: this.mediaLinksStore.mediaLinks.length === 0,
      };
      this.trackingStore.track(mixpanelActions.LINK_UPDATE_CLICKED, updateLinkData);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-action-buttons {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto var(--space-16);
  height: 5rem;
  width: 100%;
  border: 1px solid var(--border);
  background: var(--background-300);
  border-radius: var(--round-corner-small);

  > a {
    flex: 1 1 0;
    border-right: 1px solid var(--border);

    &:last-of-type {
      border: none;
    }

    > button {
      position: relative;
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: var(--x14);
      color: var(--text-primary);
      font-weight: var(--font-medium);
      cursor: pointer;

      .svg-icon {
        margin-bottom: var(--space-8);
        pointer-events: none;
      }
    }
  }

  .link-number-badge {
    position: absolute;
    top: var(--space-4);
    right: var(--space-4);
    color: white;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: var(--action-500);
    font-size: var(--x12);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-post-dropdown {
    position: absolute;
    margin: 0 auto;
    top: 5.5rem;
    background: var(--background-0);
    width: max-content;
    border-radius: var(--round-corner-small);
    box-shadow: var(--shadow-4);
    z-index: 1;
    padding: var(--space-8) 0;

    li {
      display: flex;
      align-items: center;
      height: 2.5rem;
      padding: 0 var(--space-16);

      a {
        width: 100%;
        min-width: 9rem;
        font-size: var(--x14);
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          svg {
            margin: 0 var(--space-8) 0 0;
          }
        }

        &:hover {
          color: var(--action-500);
        }
      }
    }
  }
}

.add-to-dropdown {
  position: absolute;
  top: 5.5rem;
}
</style>
