import { inAppTrials } from '@/assets';
import { extractEnumValue } from '@/models/enum.utils';

export const PRODUCT_MAP = Object.freeze({
  GROW: 'GROW',
  ENGAGE: 'ENGAGE',
  ADVANCE: 'ADVANCE',
  ENTERPRISE: 'ENTERPRISE',
  PREMIUM_ANALYTICS: 'PREMIUM_ANALYTICS',
  ADVANCE_PREMIUM: 'ADVANCE_PREMIUM',
  SOCIAL_LISTENING: 'SOCIAL_LISTENING',
  CREATORS: 'CREATORS',
});

export const BRAND_PRODUCT_LABEL_MAP = Object.freeze({
  GROW: 'grow',
  CREATOR_MANAGEMENT: 'creator_management',
  ENGAGE: 'engage',
  ADVANCE: 'advance',
  ENTERPRISE: 'enterprise',
  PREMIUM_ANALYTICS: 'premium_analytics',
  CREATOR_STARTER: 'creator_starter',
  CREATOR_PRO: 'creator_pro',
  CREATOR_PLUS: 'creator_plus',
  CREATOR_CUSTOM: 'creator_custom',
});

export const CUSTOMER_PLAN = Object.freeze({
  GROW: {
    name: 'Grow',
    value: 'grow',
  },
  ENGAGE: {
    name: 'Engage',
    value: 'engage',
  },
  ADVANCE: {
    name: 'Advance',
    value: 'advance',
  },
  ADVANCE_SOCIAL_LISTENING: {
    name: 'Advance Social Listening',
    value: 'advance_social_listening',
  },
  ADVANCE_PREMIUM: {
    name: 'Advance Premium',
    value: 'advance_premium',
  },
  ADVANCE_PREMIUM_AND_SOCIAL_LISTENING: {
    name: 'Advance Premium and Social Listening',
    value: 'advance_premium_and_social_listening',
  },
  ENTERPRISE: {
    name: 'Enterprise',
    value: 'enterprise',
  },
  ENTERPRISE_PREMIUM: {
    name: 'Enterprise Premium',
    value: 'enterprise_premium',
  },
  ENTERPRISE_SOCIAL_LISTENING: {
    name: 'Enterprise Social Listening',
    value: 'enterprise_social_listening',
  },
  ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING: {
    name: 'Enterprise Premium and Social Listening',
    value: 'enterprise_premium_and_social_listening',
  },
  // Legacy plans
  AGENCY: {
    name: 'Agency',
    value: 'agency',
  },
  EMERGING: {
    name: 'Emerging',
    value: 'emerging',
  },
  HIGH_GROWTH: {
    name: 'High Growth',
    value: 'high_growth',
  },
  STRATEGIC: {
    name: 'Strategic',
    value: 'strategic',
  },
  FREE: {
    name: 'Free',
    value: 'free',
  },
  BASIC: {
    name: 'Basic',
    value: 'basic',
  },
  TEST: {
    name: 'Test',
    value: 'test',
  },
});

export const FEATURE_MAP = Object.freeze({
  ANALYTICS: { routeName: 'analytics', requiredProduct: PRODUCT_MAP.GROW },
  CAMPAIGNS: { routeName: 'campaigns', requiredProduct: PRODUCT_MAP.PREMIUM_ANALYTICS },
  COMMUNITY: { routeName: 'community', requiredProduct: PRODUCT_MAP.ENGAGE },
  COMPETITORS: { routeName: 'competitors', requiredProduct: PRODUCT_MAP.PREMIUM_ANALYTICS },
  DASHBOARDS: { routeName: 'dashboards', requiredProduct: PRODUCT_MAP.ADVANCE },
  LIBRARY: { routeName: 'library', requiredProduct: PRODUCT_MAP.ENGAGE },
  LIKESHOP: { routeName: 'likeshop', requiredProduct: PRODUCT_MAP.GROW },
  RELATIONSHIPS: { routeName: 'relationships', requiredProduct: PRODUCT_MAP.PREMIUM_ANALYTICS },
  SCHEDULER: { routeName: 'scheduler', requiredProduct: PRODUCT_MAP.GROW },
  SOCIAL_LISTENING: { routeName: 'listening', requiredProduct: PRODUCT_MAP.SOCIAL_LISTENING },
  CREATORS: { routeName: 'creators', requiredProduct: PRODUCT_MAP.CREATORS },
});

export const FEATURE_UPGRADE_PLACEHOLDER_CTA_TEXT_MAP = Object.freeze({
  dashboards: 'Try Dashboards',
  community: 'Try Community',
  library: 'Try Library',
  listening: 'Try Social Listening',
  competitors: 'Try Competitive',
  campaigns: 'Try Campaigns',
  creators: 'Try Creators',
});

export const START_TRIAL = Object.freeze({
  [PRODUCT_MAP.ENGAGE]: {
    product_name: 'Engage',
    product_label: 'engage',
    product_type: 'Social Plan',
    title: 'Start a 14-Day Trial of Engage',
    description_items: [
      'Never miss a moment to connect with your community on social',
      'Find out the best times to post on social based on when your audience is active',
      'Store all of your owned and earned content in our Library',
    ],
    imageSrc: inAppTrials.engage[0],
    imageSrcSet: inAppTrials.engage,
    trackingKey: 'plan',
  },

  [PRODUCT_MAP.ADVANCE]: {
    product_name: 'Advance',
    product_label: 'advance',
    product_type: 'Social Plan',
    title: 'Start a 14-Day Trial of Advance',
    description_items: [
      'Streamline reporting across your channels in Dashboards',
      'Make better content decisions using Vision AI',
      "Get the inside scoop of what's happening within your industry in Competitors",
    ],
    imageSrc: inAppTrials.advance[0],
    imageSrcSet: inAppTrials.advance,
    trackingKey: 'plan',
  },

  [PRODUCT_MAP.PREMIUM_ANALYTICS]: {
    product_name: 'Premium Analytics',
    product_label: 'premium_analytics',
    product_type: 'Add-On',
    title: 'Start a 14-Day Trial of Premium Analytics',
    description_items: [
      'Measure your initiatives across social channels in Campaigns',
      'Ask Vision AI to analyze your owned, competitor and UGC data for reports in seconds',
    ],
    imageSrc: inAppTrials.premium[0],
    imageSrcSet: inAppTrials.premium,
    trackingKey: 'addOn',
  },

  [PRODUCT_MAP.ADVANCE_PREMIUM]: {
    product_name: 'Advance + Premium Analytics',
    product_label: 'advance_premium',
    product_type: 'Add-On',
    title: 'Start a 14-Day Free Trial of Advance with the Premium Analytics Add-On',
    description_items: [
      'Streamline reporting across your channels in Dashboards',
      'Make better content decisions using Vision AI',
      "Get the inside scoop of what's happening within your industry in Competitors",
      'Measure your initiatives across social channels in Campaigns',
      'Ask Vision AI to analyze your owned, competitor and UGC data for reports in seconds',
    ],
    imageSrc: inAppTrials.advance[0],
    imageSrcSet: inAppTrials.advance,
    trackingKey: 'plan',
  },

  [PRODUCT_MAP.SOCIAL_LISTENING]: {
    product_name: 'Social Listening',
    product_label: 'social_listening_tier_trial',
    product_type: 'Add-On',
    title: 'Start a 14-Day Trial of Social Listening',
    description_items: [
      'Keep a pulse on the conversations around your brand, products, competitors or campaigns',
      'Instantly see what’s trending across Instagram, X and YouTube',
      'View sentiment and brand health reports alongside your most important metrics in Dashboards',
    ],
    imageSrc: inAppTrials.socialListening[0],
    imageSrcSet: inAppTrials.socialListening,
    trackingKey: 'addOn',
  },
  [PRODUCT_MAP.CREATORS]: {
    chilipiper: {
      router: 'creators-demo',
      form: 'creators_demo',
    },
    product_name: 'Creator Management Plan',
    product_label: 'social_listening_tier_trial',
    product_type: 'Creator Management Plan',
    title: 'Start a 14-day Trial of Creator Management',
    description_items: [
      'Find creators who resonate with your brand audience',
      'Outreach and track campaign deliverables-including Stories',
      'Measure creator performance and ROI',
      "Capture all social efforts with Dash Social's social Management platform",
    ],
    imageSrc: inAppTrials.creators[0],
    imageSrcSet: inAppTrials.creators,
    trackingKey: 'addOn',
  },
});

export const MARKETING_QUOTES = Object.freeze({
  [PRODUCT_MAP.ENGAGE]: {
    quote:
      ' “We can now closely examine our comment sections and tagged posts to truly understand if our content resonates and discover opportunities for meaningful connections with our community.” ',
    name: 'Cheyenne Adler',
    title: 'Senior Social Strategist at Coterie',
    image: 'coterieLogo',
  },

  [PRODUCT_MAP.ADVANCE]: {
    quote:
      ' “We’ve reduced our reporting time from a week to a single day, thanks to Dash Social.” ',
    name: 'Rémi Ipekci',
    title: 'Global Head of Digital Marketing & Social Media at Stella McCartney',
    image: 'stellMcCartneyLogo',
  },

  [PRODUCT_MAP.PREMIUM_ANALYTICS]: {
    quote:
      ' “Our leadership team loves the competitive analysis we provide! \n' +
      'We can benchmark ourselves against like-minded brands and the industry at large — ' +
      'something that was previously difficult to do.” ',
    name: 'Paige Tapp',
    title: 'Social Media Manager at BÉIS',
    image: 'beisLogo',
  },

  [PRODUCT_MAP.ADVANCE_PREMIUM]: {
    quote:
      ' “Our leadership team loves the competitive analysis we provide! \n' +
      'We can benchmark ourselves against like-minded brands and the industry at large — ' +
      'something that was previously difficult to do.” ',
    name: 'Paige Tapp',
    title: 'Social Media Manager at BÉIS',
    image: 'beisLogo',
  },

  [PRODUCT_MAP.SOCIAL_LISTENING]: {
    quote:
      ' “Visualizing the social listening data is really helpful for our content team because they’re very creative and go into it with a lot of inspiration!” ',
    name: 'Caleb Simpson',
    title: 'Assistant Manager, Social & Community at Summer Fridays',
    image: 'summerFridaysLogo',
  },
  [PRODUCT_MAP.CREATORS]: {
    quote:
      ' “Visualizing the social listening data is really helpful for our content team because they’re very creative and go into it with a lot of inspiration!” ',
    name: 'Caleb Simpson',
    title: 'Assistant Manager, Social & Community at Summer Fridays',
    image: 'summerFridaysLogo',
  },
});

export function isSameCustomerPlan(left, right) {
  const leftType = extractEnumValue(left);
  const rightType = extractEnumValue(right);

  // make sure we have a valid plan type
  if (!leftType || !rightType) {
    return false;
  }

  return leftType === rightType;
}

export function isProPlan(type) {
  return [BRAND_PRODUCT_LABEL_MAP.CREATOR_PRO].includes(extractEnumValue(type));
}

export function isStarterPlan(type) {
  return [BRAND_PRODUCT_LABEL_MAP.CREATOR_STARTER].includes(extractEnumValue(type));
}

export function isPlusPlan(type) {
  return [BRAND_PRODUCT_LABEL_MAP.CREATOR_PLUS].includes(extractEnumValue(type));
}

export function isCustomPlan(type) {
  return [BRAND_PRODUCT_LABEL_MAP.CREATOR_CUSTOM].includes(extractEnumValue(type));
}

export function isAdvancePlan(type) {
  return [
    CUSTOMER_PLAN.ADVANCE.value,
    CUSTOMER_PLAN.ADVANCE_PREMIUM.value,
    CUSTOMER_PLAN.ADVANCE_SOCIAL_LISTENING.value,
    CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING.value,
  ].includes(extractEnumValue(type));
}

export function isEnterprisePlan(type) {
  return [
    CUSTOMER_PLAN.ENTERPRISE.value,
    CUSTOMER_PLAN.ENTERPRISE_PREMIUM.value,
    CUSTOMER_PLAN.ENTERPRISE_SOCIAL_LISTENING.value,
    CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING.value,
  ].includes(extractEnumValue(type));
}

export function isPremiumPlan(type) {
  return [
    CUSTOMER_PLAN.ADVANCE_PREMIUM.value,
    CUSTOMER_PLAN.ENTERPRISE_PREMIUM.value,
    CUSTOMER_PLAN.ADVANCE_PREMIUM_AND_SOCIAL_LISTENING.value,
    CUSTOMER_PLAN.ENTERPRISE_PREMIUM_AND_SOCIAL_LISTENING.value,
  ].includes(extractEnumValue(type));
}

export function isCustomerPlanLegacy(type) {
  return [
    CUSTOMER_PLAN.AGENCY.value,
    CUSTOMER_PLAN.EMERGING.value,
    CUSTOMER_PLAN.HIGH_GROWTH.value,
    CUSTOMER_PLAN.STRATEGIC.value,
    CUSTOMER_PLAN.FREE.value,
    CUSTOMER_PLAN.BASIC.value,
    CUSTOMER_PLAN.TEST.value,
  ].includes(extractEnumValue(type));
}

export function removeAddOnFromPlan(type) {
  return extractEnumValue(type).split('_')[0];
}

export function getRequiredProductForFeature(feature) {
  const mappedFeature = Object.values(FEATURE_MAP).find((item) => item.routeName === feature);
  return mappedFeature.requiredProduct;
}
